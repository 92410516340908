import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getQueryParam } from 'common/routing/routing.utils'
import { FormField } from 'common/utils/form/form.components'
import { useXtForm } from 'common/hooks/form/form'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { loginRoutePath } from '../../auth.constants'
import * as styles from '../../auth.module.scss'
import { AuthPageContainer } from '../../auth-page-container'
import { INewPasswordForm, NewPasswordFormField } from './new-password.types'
import { newPasswordValidation } from './new-password.validation'
import { useAuthModule } from '../../auth-module-hook'
import { useCoreModule } from '../../../core/core-module-hook'

export const NewPassword: FC = () => {
  const { AuthService } = useAuthModule()
  const { ToastService, ErrorHandler } = useCoreModule()

  const history = useHistory()
  const [isLoading, setLoading] = useState<boolean>(false)

  const token: string | null = getQueryParam('token')

  const openLoginScreen: VoidFunction = () => history.push(loginRoutePath)

  const init: () => Promise<void> = async () => {
    const isValid = !token ? false : await AuthService.isValidPasswordResetToken(token)

    if (!isValid) {
      openLoginScreen()
      ToastService.showError('Invalid password reset link.')
    }
  }

  useEffect(() => void init(), [token])

  const { control, handleSubmit, setValue, trigger, getValues } = useXtForm<INewPasswordForm>({
    defaultValues: { [NewPasswordFormField.Password]: '', [NewPasswordFormField.VerifyPassword]: '' },
    mode: 'onChange',
    validationSchema: newPasswordValidation,
  })

  const onSubmit: (data: INewPasswordForm) => Promise<void> = async ({ password }) => {
    if (!token) {
      return
    }
    try {
      setLoading(true)
      await AuthService.changePassword(password, token)
      ToastService.showSuccess(`The password has been reset.`)
      setLoading(false)
      openLoginScreen()
    } catch (e) {
      setLoading(false)
      ErrorHandler.handleError(e)
    }
  }

  const onChangePassword = useCallback(
    (pass) => {
      setValue(NewPasswordFormField.Password, pass, { shouldValidate: true, shouldDirty: true })
      const verifyPassword = getValues(NewPasswordFormField.VerifyPassword)
      if (verifyPassword) {
        void trigger(NewPasswordFormField.VerifyPassword)
      }
    },
    [getValues, setValue, trigger]
  )

  const onChangeVerifyPassword = useCallback(
    (pass) => {
      setValue(NewPasswordFormField.VerifyPassword, pass, { shouldValidate: true, shouldDirty: true })
      const password = getValues(NewPasswordFormField.Password)
      if (!password) {
        void trigger(NewPasswordFormField.Password)
      }
    },
    [getValues, setValue, trigger]
  )

  return (
    <AuthPageContainer>
      <form className={styles.authResetPassword} onSubmit={handleSubmit(onSubmit)}>
        <FormField
          name={NewPasswordFormField.Password}
          control={control}
          onChange={onChangePassword}
          label="New Password"
          type="password"
          disabled={isLoading}
          className={styles.authFields}
        />
        <FormField
          name={NewPasswordFormField.VerifyPassword}
          control={control}
          onChange={onChangeVerifyPassword}
          label="Verify Password"
          type="password"
          disabled={isLoading}
          className={styles.authFields}
        />
        <div className={styles.authScreenButtons}>
          <XtButton labelClass={styles.authScreenButtonsLabel} label="Cancel" onClick={openLoginScreen} disabled={isLoading} />
          <XtButton labelClass={styles.authScreenButtonsLabel} label="Change Password" type="submit" loading={isLoading} />
        </div>
      </form>
    </AuthPageContainer>
  )
}
