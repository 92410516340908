// extracted by mini-css-extract-plugin
export var root = "prospects-list_root__jXvjO";
export var xtContent = "prospects-list_xt-content__rbdgu";
export var xtContentWithRemarks = "prospects-list_xt-content-with-remarks__zYwaL";
export var muiFormHelperTextRoot = "prospects-list_MuiFormHelperText-root__cxoP5";
export var xtListPageContainer = "prospects-list_xt-list-page-container__k7PWB";
export var listContent = "prospects-list_listContent__PMEnO";
export var muiInputBaseRoot = "prospects-list_MuiInputBase-root__ibI-N";
export var muiTextFieldRoot = "prospects-list_MuiTextField-root__5TfWU";
export var muiTablePaginationRoot = "prospects-list_MuiTablePagination-root__GdJKU";
export var muiTablePaginationSelectRoot = "prospects-list_MuiTablePagination-selectRoot__kvEPA";
export var muiTablePaginationSelect = "prospects-list_MuiTablePagination-select__xm5qy";
export var xtPageTitle = "prospects-list_xt-page-title__mkat-";
export var xtSectionBorder = "prospects-list_xt-section-border__ZzdKo";
export var xtPageHeader = "prospects-list_xt-page-header__HwzA3";
export var xtDialogScrollable = "prospects-list_xt-dialog-scrollable__-mlUB";
export var reactToastNotificationsContainer = "prospects-list_react-toast-notifications__container__MeYUO";
export var xtScrollbarStyle = "prospects-list_xt-scrollbar-style__gT5+I";
export var xtModalDetailsContent = "prospects-list_xt-modal-details-content__TA4FO";
export var xtDialogDetailsContent = "prospects-list_xt-dialog-details-content__n3tJ1";
export var xtLazyModuleLoaderTabContainer = "prospects-list_xt-lazy-module-loader-tab-container__F9lVM";
export var xtTextTruncated = "prospects-list_xt-text-truncated__srhIj";
export var xtSummaryContent = "prospects-list_xt-summary-content__PoV7m";
export var xtSummaryLabel = "prospects-list_xt-summary-label__D742s";
export var visibleHidden = "prospects-list_visible-hidden__VRYcm";
export var headerContent = "prospects-list_headerContent__i-Dlj";
export var listControls = "prospects-list_listControls__-A77e";
export var filterButton = "prospects-list_filterButton__S9qKC";
export var prospectsListCheckbox = "prospects-list_prospectsListCheckbox__A3x-D";
export var filtersContainer = "prospects-list_filtersContainer__s5XVQ";