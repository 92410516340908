import { FilterType, IFilter } from 'components/pagefilter/filter/filter.types'
import { IDocumentsUtilsService } from 'documents/documents-utils.service'
import { getAutocompleteInputLabelAsId, renderColumnOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { OpportunitiesFilter } from '../services/opportunities-services.types'

export function resolveFilters({
  loadProjectOptions,
  loadOpportunityTypes,
  loadDocumentOppSourceOptions,
  loadOpportunityStages,
  loadAccountOptions,
  loadUserOptions,
}: IDocumentsUtilsService): IFilter[] {
  return [
    {
      type: FilterType.Autocomplete,
      label: 'User',
      fieldName: OpportunitiesFilter.User,
      autocompleteProps: {
        loadOptions: loadUserOptions,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: 'Assigned To',
      fieldName: OpportunitiesFilter.AssignedTo,
      autocompleteProps: {
        loadOptions: loadUserOptions,
      },
    },

    {
      type: FilterType.Date,
      label: 'Target Date on or After',
      fieldName: OpportunitiesFilter.TargetDateOnOrAfter,
    },
    {
      type: FilterType.Date,
      label: 'Target Date on or Before',
      fieldName: OpportunitiesFilter.TargetDateOnOrBefore,
    },
    {
      type: FilterType.Autocomplete,
      label: 'Project',
      fieldName: OpportunitiesFilter.Project,
      autocompleteProps: {
        loadOptions: loadProjectOptions,
        renderOption: renderColumnOption,
        getInputLabel: getAutocompleteInputLabelAsId,
      },
    },
    {
      type: FilterType.Text,
      label: 'Name Pattern',
      fieldName: OpportunitiesFilter.NamePattern,
    },
    {
      type: FilterType.Autocomplete,
      label: 'Account',
      fieldName: OpportunitiesFilter.Account,
      autocompleteProps: {
        loadOptions: loadAccountOptions,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: 'Type',
      fieldName: OpportunitiesFilter.Type,
      autocompleteProps: {
        loadOptions: loadOpportunityTypes,
      },
    },
    {
      type: FilterType.Text,
      label: 'Type Pattern',
      fieldName: OpportunitiesFilter.TypePattern,
    },
    {
      type: FilterType.Autocomplete,
      label: 'Source',
      fieldName: OpportunitiesFilter.Source,
      autocompleteProps: {
        loadOptions: loadDocumentOppSourceOptions,
      },
    },
    {
      type: FilterType.Text,
      label: 'Source Pattern',
      fieldName: OpportunitiesFilter.SourcePattern,
    },
    {
      type: FilterType.Autocomplete,
      label: 'Stage',
      fieldName: OpportunitiesFilter.Stage,
      autocompleteProps: {
        loadOptions: loadOpportunityStages,
      },
    },
    {
      type: FilterType.Text,
      label: 'Stage Pattern',
      fieldName: OpportunitiesFilter.StagePattern,
    },
    {
      type: FilterType.Date,
      label: 'Create Date on or After',
      fieldName: OpportunitiesFilter.CreateDateOnOrAfter,
    },
    {
      type: FilterType.Date,
      label: 'Create Date on or Before',
      fieldName: OpportunitiesFilter.CreateDateOnOrBefore,
    },
  ]
}
