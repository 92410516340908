// extracted by mini-css-extract-plugin
export var root = "xt-transfer-list_root__5jGEi";
export var xtContent = "xt-transfer-list_xt-content__QT9-+";
export var xtContentWithRemarks = "xt-transfer-list_xt-content-with-remarks__-gro1";
export var muiFormHelperTextRoot = "xt-transfer-list_MuiFormHelperText-root__gC7P-";
export var xtListPageContainer = "xt-transfer-list_xt-list-page-container__q-SOT";
export var muiInputBaseRoot = "xt-transfer-list_MuiInputBase-root__gORGd";
export var muiTextFieldRoot = "xt-transfer-list_MuiTextField-root__ZBQ2k";
export var muiTablePaginationRoot = "xt-transfer-list_MuiTablePagination-root__1bMsU";
export var muiTablePaginationSelectRoot = "xt-transfer-list_MuiTablePagination-selectRoot__rJMc7";
export var muiTablePaginationSelect = "xt-transfer-list_MuiTablePagination-select__OOOpB";
export var xtPageTitle = "xt-transfer-list_xt-page-title__jCKi4";
export var xtSectionBorder = "xt-transfer-list_xt-section-border__ScooC";
export var xtPageHeader = "xt-transfer-list_xt-page-header__87n-h";
export var xtDialogScrollable = "xt-transfer-list_xt-dialog-scrollable__7yB4b";
export var reactToastNotificationsContainer = "xt-transfer-list_react-toast-notifications__container__wcxVi";
export var xtScrollbarStyle = "xt-transfer-list_xt-scrollbar-style__OGVDt";
export var xtModalDetailsContent = "xt-transfer-list_xt-modal-details-content__kFT03";
export var list = "xt-transfer-list_list__4xPt+";
export var xtDialogDetailsContent = "xt-transfer-list_xt-dialog-details-content__BgtXc";
export var xtLazyModuleLoaderTabContainer = "xt-transfer-list_xt-lazy-module-loader-tab-container__kwR-x";
export var xtTextTruncated = "xt-transfer-list_xt-text-truncated__9HwEv";
export var xtSummaryContent = "xt-transfer-list_xt-summary-content__K3qfp";
export var xtSummaryLabel = "xt-transfer-list_xt-summary-label__ZoDXg";
export var visibleHidden = "xt-transfer-list_visible-hidden__BZnMX";
export var xtTransferListContainer = "xt-transfer-list_xtTransferListContainer__GSXhW";
export var listContainer = "xt-transfer-list_listContainer__cOM88";
export var transferListLabel = "xt-transfer-list_transferListLabel__fryvS";
export var controlButtonsContainer = "xt-transfer-list_controlButtonsContainer__y1XcY";
export var controlButton = "xt-transfer-list_controlButton__UXiB3";
export var listItemContainer = "xt-transfer-list_listItemContainer__cn4sU";
export var listItemLabel = "xt-transfer-list_listItemLabel__5Cev8";
export var addIcon = "xt-transfer-list_addIcon__DvA9D";
export var removeIcon = "xt-transfer-list_removeIcon__tV4rj";
export var error = "xt-transfer-list_error__CNBOM";
export var button = "xt-transfer-list_button__PBo7P";
export var buttonAdd = "xt-transfer-list_buttonAdd__MLux-";
export var buttonRemove = "xt-transfer-list_buttonRemove__V4eos";
export var addLabel = "xt-transfer-list_addLabel__D8Wul";
export var removeLabel = "xt-transfer-list_removeLabel__qO9wy";
export var errorMessage = "xt-transfer-list_errorMessage__lyAcz";