import { Overwrite } from 'utility-types'
import { IAppliedCharacteristic, IAppliedCharacteristicNew } from 'characteristics/characteristics.types'
import { ISalesPriceDetails, ISalesSharedOrderItem, ISalesSharedType } from './sales-shared.types'

export interface IQuote extends ISalesSharedType {
  quote_number: string
  quote_date: string | null
  commission?: number
  project_number?: string
  expire_date: string | null
  status: string
  order_items: IQuoteOrderItem[]
  quote_characteristics: IAppliedCharacteristic[] | null
}

export type QuoteLightweight = Omit<IQuote, 'quote_characteristics' | 'order_items'>

export type QuotesResponseType<ShowDetails> = ShowDetails extends true ? IQuote : QuoteLightweight

export interface IQuoteOrderItem extends ISalesSharedOrderItem {}

export type QuoteOrderItem = Omit<IQuoteOrderItem, 'order_number'>

// TODO support currency_symbol
export type QuoteCreateInput = Overwrite<
  Omit<
    IQuote,
    | 'order_number'
    | 'misc_charge_tax_type'
    | 'terms_description'
    | 'lastupdated'
    | 'site'
    | 'created'
    | 'scheduled_date'
    | 'currency_symbol'
    | 'external_number'
    | 'order_total'
    | 'sales_total'
    | 'status'
  >,
  {
    order_items: IQuoteLineItemCreatePayload[]
    quote_characteristics: IAppliedCharacteristicNew[]
    sales_rep: string | null
    sale_type: string | null
    terms: string | null
  }
>

export type QuoteUpdateInput = Overwrite<
  IQuote,
  {
    quote_characteristics: IAppliedCharacteristicNew[]
    order_items: IQuoteLineItemUpdatePayload[]
    sales_rep: string | null
    sale_type: string | null
    terms: string | null
  }
>

export interface IQuoteOrderItemsFilters {
  showCancelledLines: boolean
}

export interface IQuoteLineItemCreatePayload extends Partial<ISalesPriceDetails> {
  price_uom: string
  customer_pn: string | null
  item_number: string
  qty_ordered: number | null
  order_number?: string
  net_unit_price: number
  scheduled_date: string | null
  sold_from_site: string
  discount_percent?: string
  promise_date: string | null
}

export interface ICopyToCustomerData {
  quote_number: string
  customer_number: string
  quote_date: string | null
}

export interface IQuoteLineItemUpdatePayload extends IQuoteLineItemCreatePayload {
  line_number: string
}

export interface IQuoteCreateResponse {
  quote_number: string
}

export enum QuoteFilterLabel {
  Site = 'Site',
  ShowExpired = 'Show Expired',
  ShowConverted = 'converted',
  ExcludeProspects = 'Exclude Prospects',
  Customer = 'Customer',
  CustomerType = 'Customer Type',
  CustomerPattern = 'Customer Type Pattern',
  PONumber = 'P/O #',
  SalesRep = 'Sales Rep.',
  StartDate = 'Start Date',
  EndDate = 'End Date',
  CustomerNumber = 'customerNumber',
  Opportunity = 'opportunity_number',
}

export enum QuoteFilterFieldName {
  Site = 'site',
  ShowExpired = 'expired',
  ShowConverted = 'converted',
  ExcludeProspects = 'excludeProspects',
  Customer = 'customer',
  CustomerType = 'customer_type',
  CustomerPattern = 'customer_type_pattern',
  PONumber = 'customer_po',
  SalesRep = 'sales_rep',
  StartDate = 'startDate',
  EndDate = 'endDate',
  CustomerNumber = 'customerNumber',
  Opportunity = 'opportunity_number',
}

export interface IQuoteFilters {
  [QuoteFilterFieldName.Site]?: string
  [QuoteFilterFieldName.ShowExpired]?: boolean
  [QuoteFilterFieldName.ExcludeProspects]?: boolean
  [QuoteFilterFieldName.Customer]?: string
  [QuoteFilterFieldName.CustomerType]?: string
  [QuoteFilterFieldName.CustomerPattern]?: string
  [QuoteFilterFieldName.PONumber]?: string
  [QuoteFilterFieldName.SalesRep]?: string
  [QuoteFilterFieldName.StartDate]?: string
  [QuoteFilterFieldName.EndDate]?: string
  [QuoteFilterFieldName.CustomerNumber]?: string
  [QuoteFilterFieldName.ShowConverted]?: boolean
  [QuoteFilterFieldName.Opportunity]?: string
  unassignedToOpportunity?: boolean
}

export enum QuotesAction {
  Delete = 'Delete',
  Edit = 'Edit',
  View = 'View',
  Copy = 'Copy ',
  CopyTo = 'Copy to Customer/Prospect',
  PrintForm = 'Print Quote Form',
  Convert = 'Convert',
}
