import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { switchMap } from 'rxjs/operators'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMediaQuery } from '@material-ui/core'
import { LoadingSpinner } from 'components/loading-spinner'
import { xsMq } from 'common/constants'
import { cls } from 'common/utils/utils'

import { DocnumberSetting, DocumentType } from 'documents/documents.types'
import { convertToNewComments } from 'comments/comments.utils'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { convertMode } from 'common/utils/mode.utils'
import { useCommentsModule } from 'comments/comments-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCharacteristicsModule } from 'characteristics/characteristics-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { convertFormDataToTaskPayload } from './prospect-details.utils'
import { IProspectUpdatePayload } from '../prospects.types'
import { ProspectDetailsTabs } from './prospect-details-tabs/prospect-details-tabs'
import * as styles from './prospect-details.module.scss'
import { ProspectDetailsForm } from './prospect-details-form/prospect-details-form'
import { ProspectFormField, IProspectDetailsState, IProspectForm, IProspectDetails } from './prospect-details.types'
import { defineFormState } from '../prospects.utils'
import { prospectFormSchema } from './prospect-details-form/prospect-details-form.validation'
import { useProspectsModule } from '../prospects-module-hook'

const defaultCRMProspectState = { loading: false, prospect: null, isAdditionallyDirty: false, characteristics: [] }

export const ProspectDetails: FC<IProspectDetails> = ({
  mode,
  onCancel,
  onSubmit,
  refreshDataObservable,
  prospectNumber,
  prospectName,
}) => {
  const { useCharacteristics } = useCharacteristicsModule()
  const { useDocuments, DocumentsUtilsService } = useDocumentsModule()
  const { useRemarks } = useCommentsModule()
  const { ProspectsService } = useProspectsModule()
  const { ErrorHandler, ToastService } = useCoreModule()

  const isMobile = useMediaQuery(xsMq)

  const { isViewMode, isEditMode, isNewMode } = convertMode(mode)
  const [isReadOnlyNumber, setIsReadOnlyNumber] = useState<boolean>(false)
  const [{ prospect, isAdditionallyDirty, loading }, setState] = useState<IProspectDetailsState>(defaultCRMProspectState)
  const characteristicsState = useCharacteristics()
  const remarks = useRemarks(DocumentType.Prospect)
  const documents = useDocuments(DocumentType.Prospect, prospect?.number)
  const formMethods = useForm({
    defaultValues: defineFormState(null, prospectName),
    resolver: yupResolver(prospectFormSchema),
    mode: 'all',
  })

  const {
    formState: { isDirty: isFormDirty, isSubmitting, isValid },
    reset,
    setValue,
    handleSubmit,
  } = formMethods

  const disabled = isViewMode || isSubmitting
  const editTitle = `${prospect?.number}${prospectName ? `: ${prospectName}` : ''}`
  const isDirty = isFormDirty || isAdditionallyDirty || remarks.isDirty || characteristicsState.isDirty || documents.isDirty
  // we need additional check in New mode as the form may be initially valid (name and number are provided)
  const submitDisabled = disabled || (!isDirty && !isValid && isNewMode) || (isEditMode && !isDirty)
  const disabledProspectNumber = disabled || isReadOnlyNumber || isEditMode || Boolean(prospectNumber)

  const onClose = (): void => {
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave the page? Updates will not be applied.')) {
      return
    }
    onCancel()
  }

  async function init(): Promise<void> {
    if (isNewMode) {
      setState(defaultCRMProspectState)
      const { isReadOnly, number } = await DocumentsUtilsService.fetchGenerationSettingsData(
        DocumentType.Account,
        [DocnumberSetting.Override, DocnumberSetting.Manual],
        [DocnumberSetting.Automatic]
      )
      setValue(ProspectFormField.Number, prospectNumber ?? number ?? '', { shouldValidate: true, shouldDirty: false })
      setIsReadOnlyNumber(isReadOnly)
      return
    }
    if (!prospectNumber) {
      setState(defaultCRMProspectState)
      return
    }
    try {
      setState((prev) => ({ ...prev, loading: true, task: null }))
      const prospectData = await ProspectsService.get(prospectNumber)
      reset(defineFormState(prospectData, prospectName))
      setState((prev) => ({
        ...prev,
        loading: false,
        prospect: prospectData,
      }))
      characteristicsState.reset(prospectData.prospect_characteristics || [])
      remarks.reset(prospectNumber, prospectData.notes ?? '')
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }

  const onSubmitForm: (data: IProspectForm) => Promise<void> = async (formValues) => {
    try {
      const payload: IProspectUpdatePayload = {
        ...convertFormDataToTaskPayload(formValues, characteristicsState.characteristics, remarks.notes),
      }
      if (!prospect || !isEditMode) {
        // TODO: check remarks comments (request data)
        await ProspectsService.create({ ...payload, comments: convertToNewComments(remarks.comments) }, documents.getUnsavedDocuments())
        ToastService.showSuccess(`Prospect ${formValues.name} has been created.`)
      } else {
        await ProspectsService.update({ ...payload })
        ToastService.showSuccess(`Prospect ${formValues.name} has been updated.`)
      }
      onCancel()
      onSubmit?.call(this)
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }

  const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(onSubmitForm)
    void eventHandler(e)
  }

  useEffect(() => void init(), [prospectNumber])

  useEffect(() => {
    const refreshData = refreshDataObservable?.pipe(switchMap(() => init())).subscribe()
    return refreshData?.unsubscribe()
  }, [refreshDataObservable])

  useEffect(() => {
    if (isNewMode) {
      setValue(ProspectFormField.Name, prospectName ?? '', { shouldValidate: true, shouldDirty: false })
    }
  }, [prospectName, setValue])

  return (
    <div className={cls('xt-content', ' xt-content-with-remarks')}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...formMethods}>
        {loading && <LoadingSpinner />}
        <form hidden={loading} onSubmit={submitForm}>
          <div className={cls('xt-page-header', styles.prospectDetailsHeader)}>
            <h3 className="xt-page-title" title={editTitle}>
              {!isNewMode ? editTitle : 'New Prospect'}
            </h3>
            <div className={cls(styles.prospectDetailsHeaderButtons, isViewMode && styles.buttonsIsViewMode)}>
              <XtButton label="Cancel" onClick={onClose} />
              <XtButton hidden={isViewMode} disabled={submitDisabled} loading={isSubmitting} label="Save" type="submit" />
            </div>
          </div>
          <div>
            <ProspectDetailsForm
              disabledProspectNumber={disabledProspectNumber}
              disabledProspectName={disabled || !!prospectName}
              disabled={disabled}
            />
          </div>
          <ProspectDetailsTabs
            isMobile={isMobile}
            prospectNumber={prospect?.number}
            characteristics={characteristicsState.characteristics}
            onDeleteCharacteristic={characteristicsState.deleteCharacteristic}
            onUpdateCharacteristic={characteristicsState.updateCharacteristic}
            onCreateCharacteristic={characteristicsState.createCharacteristic}
            disabled={disabled}
            prospect={prospect}
            mode={mode}
            remarks={remarks}
            documents={documents}
          />
        </form>
      </FormProvider>
    </div>
  )
}
