import type { IContactTableItem } from 'contacts/contact-list/contact-list.types'
import type { IContactService } from 'contacts/contacts.service'
import type { IPaginationData, IPaginationParams } from 'common/common.types'
import type { ISortOption } from 'components/table/table-head/table-head.types'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { convertFiltersPanelState } from 'common/utils/filter.utils'
import { ContactFilterPanel } from './contacts.types'

export interface ICompaniesContactsUtilsService {
  deleteContact(contactNumber: string): Promise<void>
  fetchContacts(
    filters: ContactFilterPanel,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IContactTableItem>>
}

export class ContactsUtilsService implements ICompaniesContactsUtilsService {
  constructor(private contactsService: IContactService) {
    bindAllInstanceMethods(this)
  }

  public deleteContact(contactNumber: string): Promise<void> {
    return this.contactsService.delete(contactNumber)
  }

  public async fetchContacts(
    filters: ContactFilterPanel,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IContactTableItem>> {
    const { total, data } = await this.contactsService.getAll(paginationParams, convertFiltersPanelState(filters), sortOptions)

    return {
      data: data.map((item) => ({ ...item, id: item.contact_number })),
      total,
    }
  }
}
