import {
  AccountingPeriodOption,
  ContractOption,
  CRMAccountDocumentOption,
  DocnumberSetting,
  DocumentLocationFilters,
  DocumentLocationOption,
  DocumentOption,
  DocumentOrderOption,
  DocumentType,
  IDocument,
  IDocumentFilters,
  IDocumentListFilters,
  IDocumentsService,
  IGenerationSettingsData,
  ICRMAccountDocumentFilters,
  ISitesDocumentFilters,
} from 'documents/documents.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { checkPaginationParams } from 'common/utils/utils'
import { IPaginationData } from 'common/common.types'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { ISortOption } from '../components/table/table-head/table-head.types'

type DocumentMapper = (doc: IDocument) => IXtAutocompleteOption

function defaultMapper({ number }: IDocument): IXtAutocompleteOption {
  return { id: number, label: number }
}

function numberAndNameMapper({ number, name }: IDocument): IXtAutocompleteOption {
  return { id: number, label: name }
}

function numberAndDescriptionMapper({ number, description }: IDocument): IXtAutocompleteOption {
  return { id: number, label: description }
}

function nameAndDescriptionInlineMapper({ name, description }: IDocument): IXtAutocompleteOption {
  return { id: name, label: description ? `${name}-${description}` : name }
}

function numberAndDescriptionInlineMapper({ number, description }: IDocument): IXtAutocompleteOption {
  return { id: number, label: description ? `${number}-${description}` : number }
}

function numberAndNameInlineMapper({ name, number }: IDocument): IXtAutocompleteOption {
  return { id: number, label: name ? `${number}-${name}` : number }
}

function nameMapper({ name }: IDocument): IXtAutocompleteOption {
  return { id: name, label: name }
}

function documentWithDataMapper(document: IDocument, mapper: DocumentMapper): DocumentOption {
  return { ...document, ...mapper(document) }
}

export interface IDocumentsUtilsService {
  loadUserOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadCustomers(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadProspectOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadShippingZones(page?: number, limit?: number, filter?: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadTaxCodes(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadTaxZones(page?: number, limit?: number, filter?: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadLocales(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadSalesReps(page?: number, limit?: number, filter?: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadShippingForm(page?: number, limit?: number, filter?: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadShippingCharges(page?: number, limit?: number, filter?: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadSiteOptions(filters?: ISitesDocumentFilters): Promise<IXtAutocompleteOption[]>
  loadSites(page?: number, limit?: number, filter?: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadSaleTypes(page?: number, limit?: number, filter?: string | null): Promise<IPaginationData<DocumentOption>>
  loadItemTypes(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadClassCodes(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadClassCodesNumberAndDescription(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadProductCategories(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadPOTypes(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadPOTypesNumberAndDescription(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadPlannerCodes(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadCostCategories(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadFreightClasses(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadItemGroups(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadTaxTypes(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadAccountOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadAccountTypeOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadOpportunityTypes(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadProjectOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadCharacteristicGroupOptions(): Promise<IXtAutocompleteOption[]>
  loadEmployeeTypeOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadPrivilegeModulesOptions(): Promise<IXtAutocompleteOption[]>
  loadDocumentListOptions(
    page: number,
    limit: number,
    filter: string | null,
    filters?: DocumentLocationFilters
  ): Promise<IPaginationData<DocumentLocationOption>>
  loadDocumentDefaultListOption(): Promise<DocumentLocationOption | null>
  loadCustomerTypeOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadDocumentPriorityOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadDocumentOppSourceOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadDocumentTaxZoneOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadHonorificOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadOpportunityTypeOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadOpportunityStages(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadTerms(
    page?: number,
    limit?: number,
    searchFilter?: string | null,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>>
  loadUomOptions(page?: number, limit?: number, filter?: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadContractOptions(
    page: number,
    limit: number,
    filter: string | null,
    filters?: IDocumentListFilters | unknown
  ): Promise<IPaginationData<ContractOption>>
  loadManufacturerOptions(filters?: IDocumentFilters): Promise<IXtAutocompleteOption[]>
  loadLotSerialRegistrationTypeOptions(filters?: IDocumentFilters): Promise<IXtAutocompleteOption[]>
  loadCRMAccounts(
    page: number,
    limit: number,
    searchFilter: string | null,
    filters?: ICRMAccountDocumentFilters
  ): Promise<IPaginationData<CRMAccountDocumentOption>>
  loadVendorOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadVendorGroupOptions(
    page: number,
    limit: number,
    searchFilter: string | null,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>>
  loadItemOptions(
    page: number,
    limit: number,
    filter: string | null,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>>
  loadItemOptionsNumberAndName(
    page: number,
    limit: number,
    filter: string | null,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>>
  fetchGenerationSettingsData(
    source: DocumentType,
    inputParams: DocnumberSetting[],
    readOnlyParams: DocnumberSetting[]
  ): Promise<IGenerationSettingsData>
  loadReportNameOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadOrders(
    page: number,
    limit: number,
    filter: string | null,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<DocumentOrderOption>>

  loadInventoryOptions(page: number, limit: number, searchFilter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>

  loadAccountingPeriods(filters?: IDocumentListFilters, sortOptions?: ISortOption[]): Promise<AccountingPeriodOption[]>

  loadDocumentLotSerialOptions(
    page: number,
    limit: number,
    filter: string | null,
    filters: IDocumentListFilters
  ): Promise<IPaginationData<DocumentOption>>

  loadItemSiteLocations(
    page: number | undefined,
    limit: number | undefined,
    searchFilter: string | null,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>>
}

export class DocumentsUtilsService implements IDocumentsUtilsService {
  constructor(private readonly documentsService: IDocumentsService) {
    bindAllInstanceMethods(this)
  }

  public loadTerms(
    page?: number,
    limit?: number,
    searchFilter: string | null = null,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(
      DocumentType.Terms,
      page,
      limit,
      { ...filters, search_pattern: searchFilter },
      numberAndDescriptionInlineMapper
    )
  }

  public async loadProspectOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.Prospect, page, limit, { search_pattern: filter }, numberAndNameInlineMapper)
  }

  public loadShippingZones(page?: number, limit?: number, filter?: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.ShipZone, page, limit, { search_pattern: filter }, numberAndNameMapper)
  }

  public loadTaxCodes(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.TaxCode, page, limit, undefined, defaultMapper)
  }

  public loadTaxZones(page?: number, limit?: number, filter: string | null = null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.TaxZone, page, limit, { search_pattern: filter }, numberAndDescriptionMapper)
  }

  public loadLocales(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.Locale, page, limit, { search_pattern: filter })
  }

  public loadSalesReps(page?: number, limit?: number, filter: string | null = null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.SalesRep, page, limit, { search_pattern: filter }, numberAndNameMapper)
  }

  public loadShippingForm(page?: number, limit?: number, filter: string | null = null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.ShipForm, page, limit, { search_pattern: filter }, numberAndNameMapper)
  }

  public loadShippingCharges(page?: number, limit?: number, filter: string | null = null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.ShipCharges, page, limit, { search_pattern: filter }, numberAndDescriptionMapper)
  }

  public async loadSiteOptions(filters?: ISitesDocumentFilters): Promise<IXtAutocompleteOption[]> {
    const { data } = await this.requestDocuments(DocumentType.Warehouse, undefined, undefined, { ...filters, active: true })
    return data
  }

  public loadSites(page?: number, limit?: number, filter: string | null = null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.Warehouse, page, limit, { search_pattern: filter, active: true })
  }

  public async loadSaleTypes(page?: number, limit?: number, filter: string | null = null): Promise<IPaginationData<DocumentOption>> {
    const paginationParams = checkPaginationParams(page, limit)

    const { data, total } = await this.documentsService.getDocuments(DocumentType.SaleType, paginationParams, { search_pattern: filter })

    return { total, data: data.map((document) => documentWithDataMapper(document, numberAndDescriptionMapper)) }
  }

  public loadItemTypes(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.ItemTypes, page, limit, { search_pattern: filter }, nameMapper)
  }

  public loadClassCodes(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.ClassCode, page, limit, { search_pattern: filter }, nameMapper)
  }

  public loadClassCodesNumberAndDescription(
    page: number,
    limit: number,
    filter: string | null
  ): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.ClassCode, page, limit, { search_pattern: filter }, numberAndDescriptionMapper)
  }

  public loadProductCategories(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.ProdCategory, page, limit, { search_pattern: filter }, nameAndDescriptionInlineMapper)
  }

  public loadPlannerCodes(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.PlanCode, page, limit, { search_pattern: filter }, nameAndDescriptionInlineMapper)
  }

  public loadCostCategories(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.CostCategory, page, limit, { search_pattern: filter }, nameAndDescriptionInlineMapper)
  }

  public async loadFreightClasses(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.FreightClass, page, limit, { search_pattern: filter }, nameAndDescriptionInlineMapper)
  }

  public loadItemGroups(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.ItemGroups, page, limit, { search_pattern: filter }, numberAndNameMapper)
  }

  public loadTaxTypes(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.TaxType, page, limit, { search_pattern: filter }, numberAndNameMapper)
  }

  public loadAccountOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.Account, page, limit, { search_pattern: filter })
  }

  public loadAccountTypeOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.AccountType, page, limit, { search_pattern: filter }, numberAndNameMapper)
  }

  public loadOpportunityTypes(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.OpportunityType, page, limit, { search_pattern: filter }, numberAndNameInlineMapper)
  }

  public async loadCharacteristicGroupOptions(): Promise<IXtAutocompleteOption[]> {
    const { data } = await this.requestDocuments(DocumentType.CharacteristicGroup, undefined, undefined, undefined)
    return data
  }

  public loadEmployeeTypeOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.Employee, page, limit, { search_pattern: filter }, numberAndNameInlineMapper)
  }

  public async loadPrivilegeModulesOptions(): Promise<IXtAutocompleteOption[]> {
    const { data } = await this.requestDocuments(DocumentType.PrivilegeModules, undefined, undefined, undefined, numberAndNameMapper)
    return data
  }

  public loadPOTypes(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.PurchaseOrderType, page, limit, { search_pattern: filter }, defaultMapper)
  }

  public loadPOTypesNumberAndDescription(
    page: number,
    limit: number,
    filter: string | null
  ): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.PurchaseOrderType, page, limit, { search_pattern: filter }, numberAndDescriptionMapper)
  }

  public loadProjectOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.Project, page, limit, { search_pattern: filter, showInactive: true }, numberAndNameMapper)
  }

  public async loadDocumentListOptions(
    page: number,
    limit: number,
    filter: string | null,
    filters: DocumentLocationFilters
  ): Promise<IPaginationData<DocumentLocationOption>> {
    // TODO: fix after rebase
    // documentWithDataMapper(document, numberAndDescriptionMapper)) }
    const paginationParams = checkPaginationParams(page, limit)

    const { total, data } = await this.documentsService.getLocations(paginationParams, { ...filters, search_pattern: filter })
    return {
      total,
      data: data.map((document) => ({ ...document, ...numberAndDescriptionMapper(document) })),
    }
  }

  public async loadDocumentDefaultListOption(): Promise<DocumentLocationOption | null> {
    // TODO: fix after rebase
    // documentWithDataMapper(document, numberAndDescriptionMapper)) }

    const { data } = await this.documentsService.getLocations({ page: 1, limit: 1 })
    if (!Array.isArray(data) || !data.length) {
      return null
    }

    return data.map((document) => ({ ...document, ...numberAndDescriptionMapper(document) }))[0]
  }

  public async loadDocumentLotSerialOptions(
    page: number,
    limit: number,
    filter: string | null,
    filters: IDocumentListFilters
  ): Promise<IPaginationData<DocumentOption>> {
    const paginationParams = checkPaginationParams(page, limit)

    const { total, data } = await this.documentsService.getLotSerial(paginationParams, { ...filters, search_pattern: filter })
    return {
      total,
      data: data.map((document) => documentWithDataMapper(document, defaultMapper)),
    }
  }

  public loadCustomers(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.Customer, page, limit, { search_pattern: filter }, numberAndNameMapper)
  }

  public loadCustomerTypeOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.CustomerType, page, limit, { search_pattern: filter }, numberAndDescriptionMapper)
  }

  public loadDocumentPriorityOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.Priority, page, limit, { search_pattern: filter }, nameMapper)
  }

  public loadDocumentOppSourceOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.OppSource, page, limit, { search_pattern: filter })
  }

  public loadDocumentTaxZoneOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.TaxZone, page, limit, { search_pattern: filter })
  }

  public loadHonorificOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.Honorific, page, limit, { search_pattern: filter }, nameMapper)
  }

  public loadOpportunityTypeOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.OpportunityType, page, limit, { search_pattern: filter })
  }

  public async loadContractOptions(
    page: number,
    limit: number,
    searchFilter?: string | null,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<ContractOption>> {
    const paginationParams = checkPaginationParams(page, limit)

    const { total, data } = await this.documentsService.getContracts(paginationParams, { ...filters, search_pattern: searchFilter })
    return {
      total,
      data: data.map((contract) => ({ ...contract, id: contract.number, label: contract.number })),
    }
  }

  public async loadManufacturerOptions(filters?: IDocumentFilters): Promise<IXtAutocompleteOption[]> {
    const { data } = await this.requestDocuments(DocumentType.Manufacturer, undefined, undefined, filters)
    return data
  }

  public async loadLotSerialRegistrationTypeOptions(filters?: IDocumentFilters): Promise<IXtAutocompleteOption[]> {
    const { data } = await this.requestDocuments(DocumentType.LotSerialRegistrationType, undefined, undefined, filters)
    return data
  }

  public loadVendorOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.Vendor, page, limit, { search_pattern: filter }, numberAndNameMapper)
  }

  public loadVendorGroupOptions(
    page: number,
    limit: number,
    searchFilter: string | null,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.VendorGroup, page, limit, { ...filters, search_pattern: searchFilter }, defaultMapper)
  }

  public loadOpportunityStages(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.OpportunityStage, page, limit, { search_pattern: filter })
  }

  public loadReportNameOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.ReportName, page, limit, { search_pattern: filter })
  }

  public loadUserOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.User, page, limit, { search_pattern: filter }, numberAndNameInlineMapper)
  }

  public loadUomOptions(page?: number, limit?: number, filter: string | null = null): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.UOM, page, limit, { search_pattern: filter })
  }

  public async loadCRMAccounts(
    page: number,
    limit: number,
    searchFilter: string | null,
    filters?: ICRMAccountDocumentFilters
  ): Promise<IPaginationData<CRMAccountDocumentOption>> {
    const { data, total } = await this.documentsService.getCRMAccounts({ page, limit }, { ...filters, search_pattern: searchFilter })
    return {
      data: data.map((account) => ({ ...account, id: account.number, label: account.name })),
      total,
    }
  }

  public async loadOrders(
    page: number,
    limit: number,
    filter: string | null = null,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<DocumentOrderOption>> {
    const paginationParams = checkPaginationParams(page, limit)

    const { total, data } = await this.documentsService.getOrders(paginationParams, {
      search_pattern: filter,
      ...filters,
    })
    return {
      total,
      data: data.map((value) => ({ ...value, id: value.number, label: value.number })),
    }
  }

  public async loadItemOptions(
    page: number,
    limit: number,
    searchFilter: string | null,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.Item, page, limit, { search_pattern: searchFilter, ...filters }, numberAndDescriptionMapper)
  }

  public async loadItemOptionsNumberAndName(
    page: number,
    limit: number,
    searchFilter: string | null,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.Item, page, limit, { search_pattern: searchFilter, ...filters }, numberAndNameMapper)
  }

  public loadInventoryOptions(
    page: number,
    limit: number,
    filter: string | null = null,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.Invoice, page, limit, { search_pattern: filter, ...filters })
  }

  public async loadAccountingPeriods(filters?: IDocumentListFilters, sortOptions?: ISortOption[]): Promise<AccountingPeriodOption[]> {
    const { data } = await this.documentsService.getAccountingPeriods(undefined, filters, sortOptions)

    const accountLabel = (start: string | null, end: string | null): string => {
      const resultArr = []
      if (start) {
        resultArr.push(start)
      }
      if (end) {
        resultArr.push(end)
      }
      return resultArr.length ? resultArr.join(' - ') : 'N/A'
    }

    return data.map((period) => ({
      ...period,
      id: period.number,
      label: accountLabel(period.period_start, period.period_end),
    }))
  }

  public async loadItemSiteLocations(
    page: number | undefined,
    limit: number | undefined,
    searchFilter: string | null,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>> {
    return this.requestDocuments(DocumentType.List, page, limit, { search_pattern: searchFilter, ...filters }, numberAndDescriptionMapper)
  }

  public async fetchGenerationSettingsData(
    source: DocumentType,
    inputParams: DocnumberSetting[],
    readOnlyParams: DocnumberSetting[]
  ): Promise<IGenerationSettingsData> {
    const { setting, number } = await this.documentsService.getDocnumber(source)
    if (inputParams.includes(setting)) {
      return { isReadOnly: false, number }
    }
    const isReadOnly = readOnlyParams.includes(setting)
    return { isReadOnly, number }
  }

  private async requestDocuments(
    type: DocumentType,
    page?: number,
    limit?: number,
    filters?: IDocumentListFilters,
    mapper: DocumentMapper = defaultMapper
  ): Promise<IPaginationData<IXtAutocompleteOption>> {
    const paginationParams = checkPaginationParams(page, limit)

    const { total, data } = await this.documentsService.getDocuments(type, paginationParams, filters)
    return {
      total,
      data: data.map(mapper),
    }
  }
}
