import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { booleanColumnConverter, convertPhoneData, phoneColumnConverter } from 'common/utils/table.utils'
import { ContactFilter, IContactAccount, PhoneRole } from 'contacts/contacts.types'
import { ContactAction, IContactTableItem } from 'contacts/contact-list/contact-list.types'
import { convertAccountNumbers } from 'contacts/contact-list/contact-list.utils'
import { renderEmailCell } from 'components/controls/email/email-link/email-link.utils'
import { renderPhoneCell } from 'components/controls/phone/phone-link/phone-link.utils'
import { ContactFilterPanel } from '../contacts.types'

export const contactActionsEditMode: IXtTableCellAction[] = [
  {
    name: ContactAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: ContactAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: ContactAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const contactColumns: IXtTableColumn<IContactTableItem>[] = [
  {
    id: 'first_name',
    field: 'first_name',
    headerName: 'First Name',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'last_name',
    field: 'last_name',
    headerName: 'Last Name',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'active',
    field: 'active',
    headerName: 'Active Contact',
    flex: '1 0 180px',
    width: 180,
    converter: booleanColumnConverter,
  },
  {
    id: 'accounts',
    field: 'accounts',
    headerName: 'CRM Account(s)',
    flex: '1 0 220px',
    width: 220,
    converter: (accounts: unknown) => convertAccountNumbers(accounts as IContactAccount[]), // TODO: fix after TableHead types update,
    unsortable: true,
  },
  {
    id: 'company',
    field: 'company',
    headerName: 'Company',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'job_title',
    field: 'job_title',
    headerName: 'Title',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'office_phone',
    headerName: 'Office #',
    flex: '1 0 290px',
    width: 290,
    unsortable: true,
    renderCell: (item) => renderPhoneCell(convertPhoneData(item?.phones, PhoneRole.Office)),
  },
  {
    id: 'mobile_phone',
    headerName: 'Mobile #',
    flex: '1 0 280px',
    width: 280,
    unsortable: true,
    renderCell: (item) => renderPhoneCell(convertPhoneData(item?.phones, PhoneRole.Mobile)),
  },
  {
    id: 'fax_phone',
    field: 'phones',
    headerName: 'Fax #',
    flex: '1 0 200px',
    converter: phoneColumnConverter(PhoneRole.Fax),
    width: 200,
    unsortable: true,
  },
  {
    id: 'email',
    headerName: 'Email',
    flex: '1 0 160px',
    width: 160,
    renderCell: (item) => renderEmailCell(item?.email),
  },
  {
    id: 'city',
    field: 'contact_address.city',
    headerName: 'City',
    flex: '1 0 140px',
    width: 140,
    unsortable: true,
  },
  {
    id: 'state',
    field: 'contact_address.state',
    headerName: 'State',
    flex: '1 0 110px',
    width: 110,
    unsortable: true,
  },
  {
    id: 'postalcode',
    field: 'contact_address.postalcode',
    headerName: 'Postal Code',
    flex: '1 0 140px',
    width: 140,
    unsortable: true,
  },
  {
    id: 'is_lead',
    field: 'is_lead',
    headerName: 'CRM Lead',
    flex: '1 0 120px',
    width: 120,
    converter: booleanColumnConverter,
  },
  {
    id: 'prospect',
    field: 'prospect',
    headerName: 'Prospect',
    flex: '1 0 120px',
    width: 120,
    converter: booleanColumnConverter,
  },
  {
    id: 'customer',
    field: 'customer',
    headerName: 'Customer',
    flex: '1 0 120px',
    width: 120,
    converter: booleanColumnConverter,
  },
]

export const defaultFilterValues: ContactFilterPanel = {
  [ContactFilter.ShowInactive]: false,
  [ContactFilter.PostalCode]: '',
  [ContactFilter.StatePatten]: '',
  [ContactFilter.CityPattern]: '',
  [ContactFilter.AccountNumber]: '',
  [ContactFilter.Owner]: null,
  [ContactFilter.NamePattern]: '',
  [ContactFilter.CompanyPattern]: '',
}
