import type { IAsyncRoute, IRoute } from 'common/routing/routing.types'
import type { OrdersModuleDefinition } from './orders.module'
import { QuotesGuard } from 'quotes/quotes.guard'
import { quoteRouteFactory } from 'quotes/quotes.routes'
import { SalesOrdersGuard } from 'sales-orders/sales-orders.guard'
import { salesOrdersRouteFactory } from 'sales-orders/sales-orders.routes'
import { purchaseOrdersRouteFactory } from 'purchase-orders/purchase-orders.routes'
import { PurchaseOrdersGuard } from 'purchase-orders/purchase-orders.guard'
import { purchaseRequestsRouteFactory } from 'purchase-requests/purchase-requests.routes'
import { PurchaseRequestsGuard } from 'purchase-requests/purchase-requests.guard'
import { itemSourceRouteFactory } from 'item-source/item-source.routes'
import { ItemSourcesGuard } from 'item-source/item-source.guard'
import { ordersRoutePath, ordersRoutes } from './orders.constants'
import { OrdersModule } from './orders.module'
import { OrdersGuard } from './orders.guard'

export function ordersRouteFactory(moduleDefinition: OrdersModuleDefinition): IRoute[] {
  return [
    {
      path: ordersRoutes.quotes,
      name: 'Quotes',
      children: quoteRouteFactory(moduleDefinition),
      guards: [QuotesGuard],
    },
    {
      path: ordersRoutes.salesOrders,
      name: 'Sales Orders',
      children: salesOrdersRouteFactory(moduleDefinition),
      guards: [SalesOrdersGuard],
    },
    {
      path: ordersRoutes.purchaseOrders,
      name: 'Purchase Orders',
      children: purchaseOrdersRouteFactory(moduleDefinition),
      guards: [PurchaseOrdersGuard],
    },
    {
      path: ordersRoutes.purchaseRequests,
      name: 'Purchase Requests',
      children: purchaseRequestsRouteFactory(moduleDefinition),
      guards: [PurchaseRequestsGuard],
    },
    {
      path: ordersRoutes.itemSources,
      name: 'Item Sources',
      children: itemSourceRouteFactory(moduleDefinition),
      guards: [ItemSourcesGuard],
    },
  ]
}

export const ordersRoute: IAsyncRoute<OrdersModuleDefinition> = {
  path: ordersRoutePath,
  name: 'Orders',
  module: OrdersModule,
  guards: [OrdersGuard],
  childrenRoutesFactory: ordersRouteFactory,
}
