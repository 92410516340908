import { ILazyModule } from '../core/react-lazy-modules/react-lazy-modules.types'
import { homeModule } from './home.constants'
import { TasksModule } from '../tasks/tasks.module'

export type HomeModuleDefinition = typeof import('./home.exports')

export const HomeModule: ILazyModule<HomeModuleDefinition> = {
  name: homeModule,
  resolver: () => import('./home.exports'),
  dependencies: [TasksModule],
}
