import * as React from 'react'
import { FC, memo, useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormDatePicker, FormSelectField, FormXtAutocomplete } from 'common/utils/form/form.components'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useXtSelect } from 'components/controls/xt-select/xt-select-hook'
import { useDictionaryModule } from 'dictionary/dictionary-module-hook'
import {
  getAutocompleteInputLabelInline,
  renderColumnOption,
  renderInlineOption,
} from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { ProspectFormField, ProspectFormLabel, IProspectForm } from '../prospect-details.types'
import { IProspectDetailsGeneralTab } from './prospect-details-tabs.types'
import * as styles from '../prospect-details.module.scss'

export const ProspectDetailsGeneralTab: FC<IProspectDetailsGeneralTab> = memo(({ disabled, shouldSetDefaults }) => {
  const { control, setValue, getValues } = useFormContext<IProspectForm>()

  const { DictionaryUtilsService } = useDictionaryModule()
  const { DocumentsUtilsService } = useDocumentsModule()

  const { options: sites, getFirstAvailableValue } = useXtSelect(DocumentsUtilsService.loadSiteOptions, { sold: true })

  useEffect(() => {
    const site = getValues(ProspectFormField.Site)
    if (!site && shouldSetDefaults) {
      const option = getFirstAvailableValue()
      setValue(ProspectFormField.Site, option)
    }
  }, [getFirstAvailableValue, getValues, setValue, shouldSetDefaults])

  const handleAssignedUserChange = useCallback<(option: IXtAutocompleteOption) => void>(
    (option) => {
      setValue(ProspectFormField.AssignedUser, option, { shouldDirty: true })
      setValue(ProspectFormField.AssignedDate, option ? new Date() : null, { shouldDirty: true, shouldValidate: true })
    },
    [setValue]
  )

  return (
    <div className={styles.generalTabMainDetailsGrid}>
      <FormSelectField options={sites} disabled={disabled} control={control} name={ProspectFormField.Site} label={ProspectFormLabel.Site} />
      <FormXtAutocomplete
        disabled={disabled}
        control={control}
        name={ProspectFormField.SalesRep}
        label={ProspectFormLabel.SalesRep}
        loadOptions={DocumentsUtilsService.loadSalesReps}
        renderOption={renderInlineOption}
        getInputLabel={getAutocompleteInputLabelInline}
      />
      <FormXtAutocomplete
        disabled={disabled}
        control={control}
        name={ProspectFormField.TaxZone}
        label={ProspectFormLabel.TaxZone}
        loadOptions={DocumentsUtilsService.loadTaxZones}
        renderOption={renderColumnOption}
        getInputLabel={getAutocompleteInputLabelInline}
      />
      <FormXtAutocomplete
        disabled={disabled}
        control={control}
        name={ProspectFormField.Source}
        label={ProspectFormLabel.Source}
        loadOptions={DocumentsUtilsService.loadDocumentOppSourceOptions}
      />
      <FormXtAutocomplete
        disabled={disabled}
        control={control}
        name={ProspectFormField.Owner}
        label={ProspectFormLabel.Owner}
        loadOptions={DocumentsUtilsService.loadUserOptions}
      />
      <FormXtAutocomplete
        disabled={disabled}
        control={control}
        name={ProspectFormField.AssignedUser}
        label={ProspectFormLabel.AssignedUser}
        loadOptions={DocumentsUtilsService.loadUserOptions}
        onChange={handleAssignedUserChange}
      />
      <FormDatePicker control={control} disabled={disabled} name={ProspectFormField.AssignedDate} label={ProspectFormLabel.AssignedDate} />
      <FormDatePicker control={control} disabled={disabled} name={ProspectFormField.LastTouched} label={ProspectFormLabel.LastTouched} />
      <FormDatePicker control={control} disabled name={ProspectFormField.Created} label={ProspectFormLabel.Created} />
      <FormDatePicker control={control} disabled name={ProspectFormField.LastUpdated} label={ProspectFormLabel.LastUpdated} />
      <FormXtAutocomplete
        disabled={disabled}
        control={control}
        name={ProspectFormField.Currency}
        label={ProspectFormLabel.Currency}
        loadOptions={DictionaryUtilsService.loadCurrencyOptions}
      />
    </div>
  )
})
