import * as React from 'react'
import { FC, useCallback } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { confirmationMessages, xsMq } from 'common/constants'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { XtList } from 'components/list/list'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { useSharedModule } from 'shared/shared-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { XtMode } from 'common/common.types'
import { IAppliedCharacteristic, IAppliedCharacteristicNew } from '../characteristics.types'
import * as styles from '../characteristics.module.scss'
import { CharacteristicDialog } from '../characteristic-dialog/characteristic-dialog'
import { useCharacteristicsModule } from '../characteristics-module-hook'
import { AppliedCharacteristicAction, ICharacteristicDialogData, IXtCharacteristics } from './characteristics.types'
import { AppliedCharacteristicActionsEditMode, characteristicColumns, defaultCharacteristicDialogData } from './characteristics.constants'

/**
 *  XtCharacteristics displays a list of characteristics applied to a certain entity (the entity type is
 *  determined by the usedOn property) with the ability to add new characteristics and edit the existing ones.
 */
export const XtCharacteristics: FC<IXtCharacteristics> = React.memo(
  ({ characteristics, disabled: isViewMode = false, usedOnFilter, onDelete, onUpdate, onCreate, loading }) => {
    const { CharacteristicsService } = useCharacteristicsModule()
    const { ErrorHandler } = useCoreModule()
    const { useDialog } = useSharedModule()

    const {
      openDialog: openCharacteristicDialog,
      closeDialog: closeCharacteristicDialog,
      open: characteristicDialogOpen,
      data: characteristicDialogData,
    } = useDialog<ICharacteristicDialogData>(defaultCharacteristicDialogData)

    const {
      open: confirmationDialogOpen,
      data: confirmationDialogData,
      openDialog: openConfirmationDialog,
      closeDialog: closeConfirmationDialog,
    } = useDialog<IAppliedCharacteristic | null>(null)

    const isMobile = useMediaQuery(xsMq)

    const openCharacteristicDialogEditMode = useCallback<(item: IAppliedCharacteristic) => Promise<void>>(
      async ({ characteristic, characteristic_value, id }) => {
        try {
          const characteristicData = await CharacteristicsService.get(characteristic)
          openCharacteristicDialog({ mode: XtMode.Edit, characteristic: characteristicData, value: characteristic_value, id })
        } catch (e) {
          ErrorHandler.handleError(e)
        }
      },
      [CharacteristicsService, ErrorHandler, openCharacteristicDialog]
    )

    const onAction = useCallback<(item: IAppliedCharacteristic, action: AppliedCharacteristicAction) => Promise<void>>(
      async (item, action) => {
        switch (action) {
          case AppliedCharacteristicAction.Delete: {
            openConfirmationDialog(item)
            break
          }
          case AppliedCharacteristicAction.Edit: {
            await openCharacteristicDialogEditMode(item)
            break
          }
        }
      },
      [openCharacteristicDialogEditMode, openConfirmationDialog]
    )

    const onConfirmCharacteristicDialog = async (formData: IAppliedCharacteristicNew): Promise<void> => {
      try {
        const { id, mode } = characteristicDialogData
        if (mode === XtMode.Edit && id) {
          onUpdate({ ...formData, id })
        } else if (mode === XtMode.New) {
          // negative IDs are used for newly-added characteristics, to avoid clashes with positive previous ones
          onCreate({ ...formData, id: -new Date().getTime() })
        }
        closeCharacteristicDialog()
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    }

    const disabled = isViewMode || loading

    const handleRowClick = useCallback<(item: IAppliedCharacteristic) => Promise<void>>(
      async (item) => {
        if (disabled) {
          return
        }
        await openCharacteristicDialogEditMode(item)
      },
      [disabled, openCharacteristicDialogEditMode]
    )

    const handleDeletion = (): void => {
      if (confirmationDialogData) {
        onDelete(confirmationDialogData)
      }
      closeConfirmationDialog()
    }

    return (
      <div className={styles.characteristics}>
        <XtConfirmationDialog
          open={confirmationDialogOpen}
          message={confirmationMessages.deleted}
          title="Delete Characteristic"
          confirmationButtonLabel="Delete"
          onConfirm={handleDeletion}
          onClose={closeConfirmationDialog}
        />
        <XtDialog className="xt-dialog-details-content" open={characteristicDialogOpen} animation={XtDialogAnimation.FadeAnimation}>
          <CharacteristicDialog
            onConfirm={onConfirmCharacteristicDialog}
            characteristic={characteristicDialogData.characteristic}
            value={characteristicDialogData.value}
            usedOnFilter={usedOnFilter}
            onClose={closeCharacteristicDialog}
          />
        </XtDialog>

        <XtResponsiveButton
          disabled={disabled || isViewMode}
          onClick={() => openCharacteristicDialog(defaultCharacteristicDialogData)}
          icon={SvgIconIds.ADD_CIRCLE}
          className={styles.characteristicsAddButton}
          loading={loading}
          label="New Characteristic"
        />

        <div className={styles.characteristicsList}>
          <XtList
            loading={loading}
            actions={disabled ? [] : AppliedCharacteristicActionsEditMode}
            data={characteristics}
            isMobile={isMobile}
            onAction={onAction}
            columns={characteristicColumns}
            onRowClick={handleRowClick}
          />
        </div>
      </div>
    )
  }
)
