import React, { FC, useState } from 'react'
import { useXtForm } from 'common/hooks/form/form'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { FormTextAreaField } from 'common/utils/form/form.components'
import { useCoreModule } from 'core/core-module-hook'
import { IPhoneMessageFormState, PhoneMessageField } from './phone-message-dialog.types'
import { PhoneMessageDialogFormSchema } from './phone-message-dialog.validation'
import { IBasePhoneDialog } from '../phone-action-dialog.types'
import * as styles from '../phone-action-dialog.module.scss'

export const PhoneMessageDialog: FC<IBasePhoneDialog> = ({ phone, onClose }) => {
  const { ErrorHandler, ToastService, PhoneService } = useCoreModule()

  const [loading, setLoading] = useState<boolean>(false)
  const {
    handleSubmit,
    control,
    formState: { isDirty, isSubmitting },
  } = useXtForm<IPhoneMessageFormState>({
    defaultValues: { [PhoneMessageField.Message]: '', [PhoneMessageField.File]: null },
    validationSchema: PhoneMessageDialogFormSchema,
    mode: 'onBlur',
  })

  const onCancel = () => {
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave the dialog? Updates will not be applied.')) {
      return
    }
    onClose()
  }

  const onSaveForm = async (data: IPhoneMessageFormState) => {
    try {
      setLoading(true)
      await PhoneService.smsPhone({ to: phone, message: data.message, image: data.file }) // TODO: update formData
      setLoading(false)
      onClose()
      ToastService.showSuccess(`Message to ${phone} has been sent.`)
    } catch (error) {
      setLoading(false)
      ErrorHandler.handleError(error)
    }
  }

  const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(onSaveForm, (error) => console.error(error))
    void eventHandler(e)
  }

  return (
    <form onSubmit={submitForm} className={styles.phoneMessageDialog}>
      <p className={styles.title}>{`User's phone: ${phone}`}</p>
      <FormTextAreaField rows={3} control={control} name={PhoneMessageField.Message} placeholder="Enter a text message" />
      {/* TODO: It must be hidden for a time(incident 16119) */}
      {/* <Controller */}
      {/*  control={control} */}
      {/*  name={DocumentDialogFormField.File} */}
      {/*  render={({ field: { value, onChange }, fieldState: { error } }) => ( */}
      {/*    <FileUpload */}
      {/*      disabled // TODO: update */}
      {/*      file={value} */}
      {/*      onChange={onChange} */}
      {/*      */}
      {/*      error={error?.message} */}
      {/*    /> */}
      {/*  )} */}
      {/* /> */}

      <div className={styles.phoneDialogButtons}>
        <XtButton label="Cancel" onClick={onCancel} />
        <XtButton loading={loading} disabled={isSubmitting || !isDirty} type="submit" label="Send" />
      </div>
    </form>
  )
}
