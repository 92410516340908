import { defineCountryOption, defineCurrencyLabel } from 'dictionary/dictionary.utils'
import { IPaginationData } from 'common/common.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { CountryStates, CurrencyOption, ICountry, IDictionaryService } from './dictionary.types'

const defaultCountryStates = {
  initialState: '',
  initialCountry: null,
}

export interface IDictionaryUtilsService {
  loadCurrencyOptions(page: number, limit: number, filter: string | null): Promise<IPaginationData<CurrencyOption>>
  loadCountries(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>>
  loadStates(
    page: number,
    limit: number,
    filter?: string | null,
    countryId?: string | null
  ): Promise<IPaginationData<IXtAutocompleteOption>>
  getCountryStates(countryId?: string, stateId?: string): Promise<CountryStates>
  loadCountry(countryCode: string): Promise<ICountry>
}

export class DictionaryUtilsService implements IDictionaryUtilsService {
  constructor(private readonly dictionaryService: IDictionaryService) {
    bindAllInstanceMethods(this)
  }

  public async loadCurrencyOptions(page: number, limit: number, searchFilter: string | null): Promise<IPaginationData<CurrencyOption>> {
    const { data, total } = await this.dictionaryService.getCurrencies({ page, limit }, { currency: searchFilter })
    return {
      data: data.map((currency) => ({
        ...currency,
        id: currency.iso_code,
        label: defineCurrencyLabel(currency.iso_code, currency.symbol),
      })),
      total,
    }
  }

  public async loadStates(
    page: number,
    limit: number,
    filter: string | null,
    countryId: string | null
  ): Promise<IPaginationData<IXtAutocompleteOption>> {
    if (!countryId) {
      return { data: [], total: 0 }
    }

    const { total, data } = await this.dictionaryService.getStates(countryId, { limit, page }, { state_pattern: filter })

    return {
      total,
      data: data.map(({ state_code }) => defineAutocompleteOption(state_code)),
    }
  }

  public async loadCountries(page: number, limit: number, filter: string | null): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { data, total } = await this.dictionaryService.getAll({ page, limit }, { country_pattern: filter })

    return {
      data: data.map(defineCountryOption),
      total,
    }
  }

  public async loadCountry(countryCode: string): Promise<ICountry> {
    const country = await this.dictionaryService.get(countryCode)

    return country
  }

  public async getCountryStates(countryId?: string, stateId: string = ''): Promise<CountryStates> {
    if (!countryId) {
      return defaultCountryStates
    }
    try {
      const [states, country] = await Promise.all([this.dictionaryService.getStates(countryId), this.dictionaryService.get(countryId)])
      const state = stateId ? states.data.find(({ state_code }) => state_code === stateId) : null
      return {
        initialState: state ? defineAutocompleteOption(state.state_code) : stateId,
        initialCountry: defineCountryOption(country),
      }
    } catch {
      return defaultCountryStates
    }
  }
}
