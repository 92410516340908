import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { IHttpClient } from '../core/services/http-client'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from '../common/common.types'
import { prepareRequestParams } from '../common/utils/request.utils'
import { IAuthService } from '../auth/auth.types'
import { ISortOption } from '../components/table/table-head/table-head.types'
import {
  IPrivilegesPayload,
  IRolesPayload,
  ISitesPayload,
  IUser,
  IUserFilters,
  IUserPayload,
  IUserPrivilege,
  IUserRole,
  IUserSite,
} from './users.types'

export interface IUsersService {
  user$: Observable<IUser | null>
  getCurrentUser(): IUser | null
  getAll: (params: IPaginationParams, filters?: IUserFilters, sortOption?: ISortOption[]) => Promise<IPaginationData<IUser>>
  get: (username: string) => Promise<IUser>
  update(data: IUserPayload): Promise<void>
  create(data: IUserPayload): Promise<void>
  changePassword: (username: string, password: string) => Promise<void>

  getPrivilegesByRoles(roles: string[]): Promise<string[]>
  getAvailablePrivileges(username: string, module?: string): Promise<IUserPrivilege[]>
  getSelectedPrivileges(username: string, module?: string): Promise<IUserPrivilege[]>
  updatePrivileges(data: IPrivilegesPayload, username: string): Promise<void>

  getAvailableRoles(username: string): Promise<IUserRole[]>
  getSelectedRoles(username: string): Promise<IUserRole[]>
  updateRoles(data: IRolesPayload, username: string): Promise<void>

  getAvailableSites(username: string): Promise<IUserSite[]>
  getSelectedSites(username: string): Promise<IUserSite[]>
  updateSites(data: ISitesPayload, username: string): Promise<void>
}

export class UsersService implements IUsersService {
  private userSubject: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null)
  public user$: Observable<IUser | null> = this.userSubject.asObservable()

  constructor(private readonly apiClient: IHttpClient, authService: IAuthService) {
    authService.isAuthenticated$
      .pipe(
        map(() => authService.getUsername()),
        switchMap(async (username) => (username ? this.get(username) : null)),
        catchError((error) => {
          console.error(error)
          return of(null)
        })
      )
      .subscribe((user) => {
        this.userSubject.next(user)
      })
  }

  public getCurrentUser(): IUser | null {
    return this.userSubject.value
  }

  public async getAll(
    paginationParams: IPaginationParams,
    filters?: IUserFilters,
    sortOption?: ISortOption[]
  ): Promise<IPaginationData<IUser>> {
    const params = prepareRequestParams(paginationParams, filters, sortOption)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IUser>>('/users', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(username: string): Promise<IUser> {
    const {
      data: { data },
    } = await this.apiClient.get<IResponse<IUser>>(`/users/${username}`)

    this.userSubject.next(data)
    return data
  }

  public async changePassword(username: string, password: string): Promise<void> {
    const body = {
      data: {
        username,
        password,
      },
    }
    await this.apiClient.post<IResponse<string>>(`/users/update`, body)
  }

  public async update(data: IUserPayload): Promise<void> {
    const body = { data }
    await this.apiClient.post('/users/update', body)
  }

  public async create(data: IUserPayload): Promise<void> {
    const body = { data }
    await this.apiClient.post('/users/create', body)
  }

  public async getPrivilegesByRoles(roles: string[]): Promise<string[]> {
    const queryParams = roles.map((role) => `role=${role}`).join('&')
    const {
      data: { data },
    } = await this.apiClient.get<IResponse<string[]>>(`/roleprivileges?${queryParams}`)

    return Array.isArray(data) ? data : []
  }

  public async getAvailablePrivileges(username: string, module?: string): Promise<IUserPrivilege[]> {
    const {
      data: { data },
    } = await this.apiClient.get<IPaginationResponse<IUserPrivilege>>(`/users/${username}/privileges/available`, { params: { module } })

    return Array.isArray(data) ? data : []
  }

  public async getSelectedPrivileges(username: string, module?: string): Promise<IUserPrivilege[]> {
    const {
      data: { data },
    } = await this.apiClient.get<IPaginationResponse<IUserPrivilege>>(`/users/${username}/privileges/selected`, { params: { module } })

    return Array.isArray(data) ? data : []
  }

  public async updatePrivileges(data: IPrivilegesPayload, username: string): Promise<void> {
    const body = { data }
    await this.apiClient.post(`/users/${username}/privileges/update`, body)
  }

  public async getAvailableRoles(username: string): Promise<IUserRole[]> {
    const {
      data: { data },
    } = await this.apiClient.get<IPaginationResponse<IUserRole>>(`/users/${username}/roles/available`)

    return Array.isArray(data) ? data : []
  }

  public async getSelectedRoles(username: string): Promise<IUserRole[]> {
    const {
      data: { data },
    } = await this.apiClient.get<IPaginationResponse<IUserRole>>(`/users/${username}/roles/selected`)

    return Array.isArray(data) ? data : []
  }

  public async updateRoles(data: IRolesPayload, username: string): Promise<void> {
    const body = { data }
    await this.apiClient.post(`/users/${username}/roles/update`, body)
  }

  public async getAvailableSites(username: string): Promise<IUserSite[]> {
    const {
      data: { data },
    } = await this.apiClient.get<IPaginationResponse<IUserSite>>(`/users/${username}/sites/available`)

    return Array.isArray(data) ? data : []
  }

  public async getSelectedSites(username: string): Promise<IUserSite[]> {
    const {
      data: { data },
    } = await this.apiClient.get<IPaginationResponse<IUserSite>>(`/users/${username}/sites/selected`)

    return Array.isArray(data) ? data : []
  }

  public async updateSites(data: ISitesPayload, username: string): Promise<void> {
    const body = { data }
    await this.apiClient.post(`/users/${username}/sites/update`, body)
  }
}
