import React, { FC, memo } from 'react'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { DocumentType, UsedOnValue } from 'documents/documents.types'
import { ICustomer } from 'companies/customers/customers.types'
import { IRemarksHook } from 'comments/remarks/remarks-hook'
import { IDocumentsHook } from 'documents/documents-hook'
import { ICharacteristicsHook } from 'characteristics/characteristics-hook'
import { XtMode } from 'common/common.types'
import { UserPermission } from 'auth/auth.types'
import { useCommentsModule } from 'comments/comments-module-hook'
import { useCharacteristicsModule } from 'characteristics/characteristics-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { MultipleFormRegister } from 'common/hooks/form/multiple-form-manager/multiple-form-manager.types'
import * as styles from './customer-details-tabs.module.scss'
import { AddressTab } from './addresses-tab/address-tab'
import { CRMTabs } from './crm-tab/crm-tabs'
import { SalesTabs } from './sales-tab/sales-tabs'
import { ICustomerCombinedState } from '../customer-details.types'

interface ICustomerDetailsTabs {
  disabled: boolean
  customer: ICustomer | null
  register: MultipleFormRegister<ICustomerCombinedState>
  isMobile: boolean
  remarks: IRemarksHook
  characteristics: ICharacteristicsHook
  documents: IDocumentsHook
  mode: XtMode
}

enum ItemDetailsTabName {
  Addresses = 'Addresses',
  Characteristics = 'Characteristics',
  CRM = 'CRM',
  Sales = 'Sales',
  Documents = 'Documents',
  Remarks = 'Remarks',
}

export const CustomerDetailsTabs: FC<ICustomerDetailsTabs> = memo(
  ({ disabled, customer, register, isMobile, remarks, characteristics, documents, mode }) => {
    const { XtRemarks } = useCommentsModule()
    const { XtCharacteristics } = useCharacteristicsModule()
    const { XtDocuments } = useDocumentsModule()
    const { PermissionsService } = useAuthModule()

    const canViewContacts = PermissionsService.hasSomePermission([
      UserPermission.MaintainAllContacts,
      UserPermission.MaintainPersonalContacts,
      UserPermission.ViewPersonalContacts,
      UserPermission.ViewAllContacts,
    ])

    const canViewActivities = PermissionsService.hasSomePermission([
      UserPermission.MaintainAllTaskItems,
      UserPermission.MaintainPersonalTaskItems,
      UserPermission.ViewAllTaskItems,
      UserPermission.ViewPersonalTaskItems,
    ])
    const canEditCustomer = PermissionsService.hasPermission(UserPermission.MaintainCustomerMasters)

    const canViewQuotes = PermissionsService.hasSomePermission([UserPermission.MaintainQuotes, UserPermission.ViewQuotes])
    const canViewSalesOrders = PermissionsService.hasSomePermission([UserPermission.MaintainSalesOrders, UserPermission.ViewSalesOrders])

    const tabsIsEditMode = [
      {
        name: ItemDetailsTabName.Addresses,
        template: <AddressTab disabled={disabled} isMobile={isMobile} customer={customer} register={register} />,
      },
      {
        name: ItemDetailsTabName.Characteristics,
        template: (
          <XtCharacteristics
            usedOnFilter={UsedOnValue.Customer}
            disabled={disabled}
            onCreate={characteristics.createCharacteristic}
            onUpdate={characteristics.updateCharacteristic}
            onDelete={characteristics.deleteCharacteristic}
            characteristics={characteristics.characteristics}
          />
        ),
      },
      {
        name: ItemDetailsTabName.CRM,
        hidden: !canViewContacts && !canViewActivities,
        template: (
          <CRMTabs
            isMobile={isMobile}
            disabled={disabled}
            customer={customer}
            canViewContacts={canViewContacts}
            canViewActivities={canViewActivities}
            canEditCustomer={canEditCustomer}
          />
        ),
      },
      {
        name: ItemDetailsTabName.Sales,
        template: (
          <SalesTabs
            mode={mode}
            isMobile={isMobile}
            disabled={disabled}
            customer={customer}
            canViewQuotes={canViewQuotes}
            canViewSalesOrders={canViewSalesOrders}
          />
        ),
      },
      {
        name: ItemDetailsTabName.Documents,
        template: (
          <XtDocuments
            disabled={disabled}
            onDocumentCreate={documents.saveDocument}
            onDocumentsCreate={documents.saveDocuments}
            onDocumentDelete={documents.deleteDocument}
            onDocumentUpdate={documents.updateDocument}
            tableState={documents.state}
            pagination={documents.pagination}
            mode={mode}
          />
        ),
      },
      {
        name: ItemDetailsTabName.Remarks,
        template: (
          <XtRemarks
            source={DocumentType.Customer}
            canLoadMore={remarks.canLoadMore}
            loadMore={remarks.loadMore}
            onCommentsUpdate={remarks.updateComment}
            onCommentsSave={remarks.saveComment}
            comments={remarks.comments}
            username={remarks.username}
            textAreaOnChange={remarks.setNotes}
            textAreaValue={remarks.notes}
            textAreaName="Notes"
            disabled={disabled}
          />
        ),
      },
    ]

    const tabsIsNewMode = [
      {
        name: ItemDetailsTabName.Addresses,
        template: <AddressTab disabled={disabled} isMobile={isMobile} customer={customer} register={register} />,
      },
    ]

    const tabs = mode === XtMode.New ? tabsIsNewMode : tabsIsEditMode

    return <XtTabs classNameRoot={styles.customerDetailsTabs} tabs={tabs} />
  }
)
