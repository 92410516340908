import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { FilterType, IFilter } from 'components/pagefilter/filter/filter.types'
import { IDocumentsUtilsService } from 'documents/documents-utils.service'
import { ContactFilter } from 'contacts/contacts.types'
import { IContactTableItem } from 'contacts/contact-list/contact-list.types'
import { contactActionsViewMode } from 'contacts/contact-list/contact-list.constants'
import { contactActionsEditMode } from './contacts-list.constants'

export function getContactActions(item: IContactTableItem): IXtTableCellAction[] {
  return item.editable ? contactActionsEditMode : contactActionsViewMode
}

export function resolveFilters({ loadAccountOptions, loadUserOptions }: IDocumentsUtilsService): IFilter[] {
  return [
    {
      type: FilterType.Autocomplete,
      label: 'Owner',
      fieldName: ContactFilter.Owner,
      autocompleteProps: {
        loadOptions: loadUserOptions,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: 'Account',
      fieldName: ContactFilter.AccountNumber,
      autocompleteProps: {
        loadOptions: loadAccountOptions,
      },
    },
    {
      type: FilterType.Text,
      label: 'Company pattern',
      fieldName: ContactFilter.CompanyPattern,
    },
    {
      type: FilterType.Text,
      label: 'Name pattern',
      fieldName: ContactFilter.NamePattern,
    },
    {
      type: FilterType.Text,
      label: 'City Pattern',
      fieldName: ContactFilter.CityPattern,
    },
    {
      type: FilterType.Text,
      label: 'State Pattern',
      fieldName: ContactFilter.StatePatten,
    },
    {
      type: FilterType.Text,
      label: 'Postal Code pattern',
      fieldName: ContactFilter.PostalCode,
    },
  ]
}
