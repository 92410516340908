import { IAsyncRoute, IRoute } from 'common/routing/routing.types'
import { XtMode } from 'common/common.types'
import { buildPageComponent } from 'common/routing/routing.utils'
import { productsRoutePath, productsRoutes } from './products.constants'
import { ItemsGuard } from './items/items.guard'
import { ProductsGuard } from './products.guard'
import { InventoryGuard } from './inventory/inventory.guard'
import { BomGuard } from './bom/bom.guard'
import { RoutingGuard } from './routing/routing.guard'
import { RoutingDetailsGuard } from './routing/routing-details/routing-details.guard'
import { BomDetailsGuard } from './bom/bom-details/bom-details.guard'
import { ProductsModule, ProductsModuleDefinition } from './products.module'

function productRouteFactory({
  ItemsList,
  ItemDetails,
  BomDetailsPage,
  BomList,
  RoutingDetails,
  RoutingList,
  InventoryList,
}: ProductsModuleDefinition): IRoute[] {
  return [
    {
      path: productsRoutes.items,
      name: 'Items',
      guards: [ItemsGuard],
      children: [
        {
          path: '',
          exact: true,
          component: ItemsList,
        },
        {
          path: `/:itemNumber/${XtMode.Edit}`, // TODO: implement guards
          component: (props) => buildPageComponent(props, XtMode.Edit, ItemDetails),
        },
        {
          path: `/:itemNumber/${XtMode.View}`, // TODO: implement guards
          component: (props) => buildPageComponent(props, XtMode.View, ItemDetails),
        },
        {
          path: `/${XtMode.New}`, // TODO: implement guards
          component: (props) => buildPageComponent(props, XtMode.New, ItemDetails),
        },
      ],
    },
    {
      path: productsRoutes.inventory,
      name: 'Inventory Availability',
      guards: [InventoryGuard],
      children: [
        {
          path: '',
          exact: true,
          component: InventoryList,
        },
      ],
    },
    {
      path: productsRoutes.bom,
      name: 'Bill of Materials',
      guards: [BomGuard],
      children: [
        {
          path: '',
          exact: true,
          component: BomList,
        },
        {
          guards: [BomDetailsGuard],
          path: `/${XtMode.New}`,
          component: (props) => buildPageComponent(props, XtMode.New, BomDetailsPage),
        },
        {
          path: `/:itemNumber/${XtMode.View}`,
          component: (props) => buildPageComponent(props, XtMode.View, BomDetailsPage),
        },
        {
          guards: [BomDetailsGuard],
          path: `/:itemNumber/${XtMode.Edit}`,
          component: (props) => buildPageComponent(props, XtMode.Edit, BomDetailsPage),
        },
      ],
    },
    {
      path: productsRoutes.routing,
      name: 'Routing',
      guards: [RoutingGuard],
      children: [
        {
          path: '',
          exact: true,
          component: RoutingList,
        },
        {
          path: `/:itemNumber/${XtMode.Edit}`,
          component: (props) => buildPageComponent(props, XtMode.Edit, RoutingDetails),
          guards: [RoutingDetailsGuard],
        },
        {
          path: `/:itemNumber/${XtMode.View}`,
          component: (props) => buildPageComponent(props, XtMode.View, RoutingDetails),
        },
        {
          path: `/${XtMode.New}`,
          component: (props) => buildPageComponent(props, XtMode.New, RoutingDetails),
          guards: [RoutingDetailsGuard],
        },
      ],
    },
  ]
}

export const productRoute: IAsyncRoute<ProductsModuleDefinition> = {
  name: 'Products',
  path: productsRoutePath,
  guards: [ProductsGuard],
  module: ProductsModule,
  childrenRoutesFactory: productRouteFactory,
}
