import type { FC } from 'react'
import type { IXtNavbar } from './navbar.types'
import React from 'react'
import { Tab, Tabs } from '@mui/material'
import { NavLink, useLocation } from 'react-router-dom'
import * as styles from './navbar.module.scss'

export const XtNavbar: FC<IXtNavbar> = function XtNavBar({ options }) {
  const location = useLocation()

  const currentTabIndex = options.findIndex((option) => {
    const currentTabRegEx = new RegExp(`^${option.path}.*`)
    return currentTabRegEx.test(location.pathname)
  })

  const currentTab = currentTabIndex === -1 ? 0 : currentTabIndex

  return (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      value={currentTab}
      classes={{ flexContainer: styles.tabsContainer, indicator: styles.indicator }}
      textColor="inherit"
    >
      {options.map(({ path, name }) => (
        <Tab
          key={path}
          label={name}
          classes={{ root: styles.navContainer, textColorInherit: styles.tabTextInherit }}
          to={path}
          component={NavLink}
        />
      ))}
    </Tabs>
  )
}
