import React, { FC, useState } from 'react'
import { useXtForm } from 'common/hooks/form/form'
import { FormCheckboxLabel, FormField } from 'common/utils/form/form.components'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { useCoreModule } from 'core/core-module-hook'
import {
  IPhoneCallTwilioDialog,
  IPhoneCallTwilioFormState,
  PhoneCallTwilioField,
  PhoneCallTwilioLabel,
} from './phone-call-twilio-dialog.types'
import { PhoneCallViaTwilioDialogFormSchema } from './phone-call-twilio-dialog.validation'
import * as styles from '../../phone-action-dialog.module.scss'

export const PhoneCallTwilioDialog: FC<IPhoneCallTwilioDialog> = ({ onClose, phone, onSubmit }) => {
  const { ErrorHandler, ToastService, PhoneService } = useCoreModule()

  const [loading, setLoading] = useState<boolean>(false)
  const {
    handleSubmit,
    control,
    formState: { isDirty, isSubmitting },
  } = useXtForm<IPhoneCallTwilioFormState>({
    defaultValues: { [PhoneCallTwilioField.Phone]: '', [PhoneCallTwilioField.IsRecordCall]: false },
    validationSchema: PhoneCallViaTwilioDialogFormSchema,
    mode: 'onBlur',
  })

  const onSaveForm: (formData: IPhoneCallTwilioFormState) => Promise<void> = async (formData) => {
    try {
      setLoading(true)
      await PhoneService.callPhone({ to: phone, from: formData.phone, record: formData.is_record_call })
      ToastService.showSuccess(`Call to ${formData.phone} has been initiated`)
      setLoading(false)
      onSubmit()
    } catch (error) {
      setLoading(false)
      ErrorHandler.handleError(error)
    }
  }

  const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(onSaveForm)
    void eventHandler(e)
  }

  const onCancel: VoidFunction = () => {
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave the dialog? Updates will not be applied.')) {
      return
    }
    onClose()
  }
  const disabled = loading || isSubmitting

  return (
    <form onSubmit={submitForm} className={styles.phoneTwilioDialog}>
      <p className={styles.title}>{`User's phone: ${phone}`}</p>
      <FormField
        /*  mask={phoneNumberMask} */
        disabled={disabled}
        control={control}
        name={PhoneCallTwilioField.Phone}
        label={PhoneCallTwilioLabel.Phone}
      />
      <FormCheckboxLabel
        disabled={disabled}
        control={control}
        name={PhoneCallTwilioField.IsRecordCall}
        label={PhoneCallTwilioLabel.IsRecordCall}
      />
      <div className={styles.phoneDialogButtons}>
        <XtButton label="Cancel" onClick={onCancel} />
        <XtButton loading={loading} disabled={!isDirty || isSubmitting} type="submit" label="Call" />
      </div>
    </form>
  )
}
