import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useXtForm } from 'common/hooks/form/form'
import { FormField } from 'common/utils/form/form.components'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { useCoreModule } from 'core/core-module-hook'
import { IResetPasswordForm, ResetPasswordFormField } from './reset-password.types'
import { resetPasswordValidation } from './reset-password.validation'
import { AuthPageContainer } from '../auth-page-container'
import * as styles from '../auth.module.scss'
import { loginRoutePath } from '../auth.constants'
import { useAuthModule } from '../auth-module-hook'

export const ResetPassword: FC = () => {
  const { AuthService } = useAuthModule()
  const { ToastService, ErrorHandler } = useCoreModule()

  const history = useHistory()
  const [isLoading, setLoading] = useState<boolean>(false)

  const { control, handleSubmit } = useXtForm<IResetPasswordForm>({
    defaultValues: { [ResetPasswordFormField.Email]: '' },
    mode: 'onBlur',
    validationSchema: resetPasswordValidation,
  })

  const openLoginScreen: VoidFunction = () => history.push(loginRoutePath)

  const onSubmit: (data: IResetPasswordForm) => Promise<void> = async ({ email }) => {
    try {
      setLoading(true)
      await AuthService.resetPassword(email)
      setLoading(false)
      ToastService.showSuccess(`We have sent you the instructions to the following email address ${email}`)
      openLoginScreen()
    } catch (e) {
      ErrorHandler.handleError(e)
      setLoading(false)
    }
  }

  return (
    <AuthPageContainer>
      <form className={styles.authResetPassword} onSubmit={handleSubmit(onSubmit)}>
        <FormField
          name={ResetPasswordFormField.Email}
          control={control}
          label="Email Address"
          disabled={isLoading}
          className={styles.authFields}
        />
        <div className={styles.authScreenButtons}>
          <XtButton labelClass={styles.authScreenButtonsLabel} label="Cancel" onClick={openLoginScreen} disabled={isLoading} />
          <XtButton labelClass={styles.authScreenButtonsLabel} label="Send Reset Email" type="submit" loading={isLoading} />
        </div>
      </form>
    </AuthPageContainer>
  )
}
