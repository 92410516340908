import * as React from 'react'
import { useEffect } from 'react'
import {
  IXtAutocompleteOption,
  IXtAutocompleteParams,
  XtAutocompleteLoadOptionsFunc,
  XtAutocompleteFilterFunc,
} from './xt-autocomplete.types'
import { XtAutocompletePlain } from './xt-autocomplete-plain'
import { useXtAutocomplete } from './xt-autocomplete-hook'

function extractFilterFunctionArgs<Option extends IXtAutocompleteOption, TFilters>(
  filters: TFilters | undefined
): Parameters<XtAutocompleteFilterFunc<Option, TFilters>> {
  const args = filters !== undefined ? [filters] : []
  return args as Parameters<XtAutocompleteFilterFunc<Option, TFilters>>
}

export function XtAutocomplete<LoadFunc extends XtAutocompleteLoadOptionsFunc>({
  value,
  onChange,
  onBlur,
  loadOptions,
  extraOption,
  limit,
  renderOption,
  placeholder,
  disabled,
  className,
  error,
  hidden,
  getInputLabel,
  noOptionsText,
  getOptionDisabled,
  filters,
  multiple,
  disableInput,
  clearable = true,
}: IXtAutocompleteParams<LoadFunc>): React.ReactElement {
  const { options, loading, filter, search, reset, loadMoreOptions } = useXtAutocomplete(loadOptions, filters, false, limit, extraOption)

  useEffect(() => {
    void filter(...extractFilterFunctionArgs(filters))
  }, [filter, filters])

  return (
    <XtAutocompletePlain
      hidden={hidden}
      error={error}
      placeholder={placeholder}
      getInputLabel={getInputLabel}
      value={value}
      onChange={onChange}
      options={options}
      onBlur={onBlur}
      loading={loading}
      getOptionDisabled={getOptionDisabled}
      className={className}
      renderOption={renderOption}
      noOptionsText={noOptionsText}
      disabled={disabled}
      loadMore={loadMoreOptions}
      reset={reset}
      filter={search}
      multiple={multiple}
      disableInput={disableInput}
      disableClearable={!clearable}
    />
  )
}
