import { Overwrite } from 'utility-types'
import { XtMode } from 'common/common.types'
import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { ISalesOrder, ISalesOrderFilters, SalesOrderFilterFieldName } from 'sales-shared/sales-orders.types'
import { AttachmentOptions } from 'sales-shared/attach-dialog/attach-dialog.types'
import { IFilterFormState } from 'components/pagefilter/filter/filter.types'
import { IAccountFulfilled } from 'companies/accounts/accounts.types'

export type SalesOrderRow = Overwrite<
  Pick<
    ISalesOrder,
    | 'order_number'
    | 'order_date'
    | 'scheduled_date'
    | 'order_total'
    | 'ship_to'
    | 'sale_type'
    | 'customer_number'
    | 'customer_name'
    | 'customer_po_number'
    | 'ordermargin'
    | 'ordermarginpercent'
    | 'hold_type'
  >,
  {
    ship_to: string
  }
> & { id: string; status: string }

export enum SalesOrdersListAction {
  View = 'view',
  Edit = 'edit',
  Delete = 'delete',
  Copy = 'copy',
  CopyForCustomer = 'copy for customer',
  Print = 'print',
}

export interface ISalesOrdersListGeneral<TFilters extends IFilterFormState> extends AttachmentOptions {
  disabled: boolean
  isMobile: boolean
  shouldOpenDetailsInDialog: boolean
  mode: XtMode
  actions: IXtTableCellAction[]
  filters: TFilters
  account: IAccountFulfilled
  columns?: IXtTableColumn<SalesOrderRow>[]
  getItemActions?: (item: SalesOrderRow, actions: IXtTableCellAction[]) => IXtTableCellAction[]
}

export type SalesOrderFiltersPanel = Overwrite<
  Pick<ISalesOrderFilters, SalesOrderFilterFieldName.StartDate | SalesOrderFilterFieldName.EndDate | SalesOrderFilterFieldName.ShowClosed>,
  {
    [SalesOrderFilterFieldName.StartDate]: Date | null
    [SalesOrderFilterFieldName.EndDate]: Date | null
    [SalesOrderFilterFieldName.ShowClosed]: boolean
  }
>
