import React, { FC, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMediaQuery } from '@material-ui/core'
import { switchMap } from 'rxjs/operators'
import { FormCheckboxLabel, FormField, FormRadioGroup, FormXtAutocomplete } from 'common/utils/form/form.components'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { DocnumberSetting, DocumentType, IAttachedDocumentWithFile } from 'documents/documents.types'
import { xsMq } from 'common/constants'
import { LoadingSpinner } from 'components/loading-spinner'
import { cls } from 'common/utils/utils'
import { NewComment } from 'comments/comments.types'
import { AdditionalDataCreationError } from 'common/common.types'
import { renderColumnOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { convertMode } from 'common/utils/mode.utils'
import { UserPermission } from 'auth/auth.types'
import { useCharacteristicsModule } from 'characteristics/characteristics-module-hook'
import { useCommentsModule } from 'comments/comments-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { useAccountDetailsChanges } from './account-details-changes.hook'
import { ActiveRolesSection } from './active-roles-section/active-roles-section'
import { AccountPayload, IRoles } from '../accounts.types'
import { AccountDetailsTabs } from './account-details-tabs/account-details-tabs'
import { accountDetailsValidationSchema } from './account-details.validation'
import { defineAccountDetailsFormState } from './account-details.utils'
import { accountDetailsType, defaultAccountDetailsFormValues } from './account-details.constants'
import * as styles from './account-details.module.scss'
import { AccountDetailsField, AccountDetailsLabel, IAccountDetails, IAccountsDetails, IAccountDetailsState } from './account-details.types'
import { useAccountsModule } from '../accounts-module-hook'
import { IAccountInput } from './account-details-tabs/account-details-tabs.types'

const defaultAccountDetailsState = {
  loading: true,
  account: null,
}

export const AccountDetails: FC<IAccountDetails> = ({ mode, accountNumber, onCancel, className }) => {
  const { AccountsService } = useAccountsModule()
  const { useGenerationSettings, useDocuments, DocumentsUtilsService } = useDocumentsModule()
  const { useRemarks } = useCommentsModule()
  const { useCharacteristics } = useCharacteristicsModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { PermissionsService } = useAuthModule()

  const { isViewMode, isNewMode } = convertMode(mode)

  const [roles, setRoles] = useState<null | IRoles>(null)
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, dirtyFields },
    reset,
    setValue,
    watch,
  } = useForm<IAccountsDetails>({
    defaultValues: defaultAccountDetailsFormValues,
    mode: 'onBlur',
    resolver: yupResolver(accountDetailsValidationSchema),
  })

  const accountName = watch(AccountDetailsField.AccountName)

  const canEditOwnerField = PermissionsService.hasPermission(UserPermission.EditOwner)
  const isFormDirty = Boolean(Object.keys(dirtyFields).length)

  const isMobile = useMediaQuery(xsMq)

  const [state, setState] = useState<IAccountDetailsState>(defaultAccountDetailsState)

  const remarksState = useRemarks(DocumentType.Account)
  const characteristicsState = useCharacteristics([])
  const documentsState = useDocuments(DocumentType.Account, state.account?.number)

  const { isReadOnly, number: settingsNumber } = useGenerationSettings({
    isNewMode,
    readOnlyParams: [DocnumberSetting.Automatic],
    inputParams: [DocnumberSetting.Override, DocnumberSetting.Manual],
    source: DocumentType.Account,
  })

  const { handleChanges, documentsUpdate$, accountUpdate$, contactsUpdate$, tasksUpdate$ } = useAccountDetailsChanges()

  const disabled = isViewMode || isSubmitting
  const isDirty = isFormDirty || remarksState.isDirty || characteristicsState.isDirty || documentsState.isDirty

  async function init(): Promise<void> {
    try {
      if (!accountNumber || isNewMode) {
        setState((prevState) => ({ ...prevState, loading: false }))
        return
      }
      const accountData = await AccountsService.get(accountNumber)
      reset(defineAccountDetailsFormState(accountData))
      setRoles(accountData.roles)
      setState((prev) => ({
        ...prev,
        loading: false,
        account: accountData,
      }))
      characteristicsState.reset(accountData.account_characteristics ?? [])
      remarksState.reset(accountNumber, accountData.notes)
    } catch (error) {
      ErrorHandler.handleError(error)
      setState((prevState) => ({ ...prevState, loading: false }))
    }
  }

  useEffect(() => void init(), [])

  // TODO fix documentsState.refresh so there are no so many changes - right now we subscribe to documentUpdate$ too often
  useEffect(() => {
    const sub = documentsUpdate$.pipe(switchMap(documentsState.refresh)).subscribe()

    return () => sub.unsubscribe()
  }, [documentsState.refresh])

  useEffect(() => {
    if (isNewMode && settingsNumber) {
      setValue(AccountDetailsField.AccountNumber, settingsNumber)
    }
  }, [isNewMode, settingsNumber])

  useEffect(() => {
    const sub = accountUpdate$.pipe(switchMap(init)).subscribe()

    return () => sub.unsubscribe()
  }, [])

  const accountInput: IAccountInput = useMemo(
    () => ({
      account_number: isNewMode ? null : state.account?.number ?? null,
      account_name: isNewMode ? null : state.account?.name ?? null,
      editable: isNewMode ? false : Boolean(state.account?.editable),
    }),
    [state.account, isNewMode]
  )

  const onClose: VoidFunction = () => {
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave this page? Updates will not be applied.')) {
      return
    }
    onCancel()
  }

  const saveAccount = async (account: AccountPayload, comments: NewComment[], documents: IAttachedDocumentWithFile[]): Promise<void> => {
    try {
      await AccountsService.create(account, comments, documents)
    } catch (e) {
      if (e instanceof AdditionalDataCreationError) {
        ErrorHandler.handleError(e)
      } else {
        throw e
      }
    }
  }

  const onSubmit = async (formData: IAccountsDetails): Promise<void> => {
    try {
      const accountDetailsData: AccountPayload = {
        ...formData,
        account_characteristics: characteristicsState.characteristics,
        notes: remarksState.notes,
        owner: formData[AccountDetailsField.Owner]?.id ?? null,
        parent: formData[AccountDetailsField.Parent]?.id ?? null,
      }

      if (isNewMode) {
        await saveAccount(accountDetailsData, remarksState.comments, documentsState.getUnsavedDocuments())
        ToastService.showSuccess(`Account ${accountDetailsData.number} has been created.`)
      } else {
        await AccountsService.update(accountDetailsData)
        ToastService.showSuccess(`Account ${accountDetailsData.number} has been updated.`)
      }
      onCancel()
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }

  return (
    <div className={className}>
      {state.loading && <LoadingSpinner />}
      <form onSubmit={handleSubmit(onSubmit)} hidden={state.loading}>
        <div className={cls('xt-page-header', styles.accountDetailsHeader)}>
          <h1 className="xt-page-title">{isNewMode ? 'New Account' : `${state.account?.number}: ${state.account?.name}`}</h1>
          <div className={isViewMode ? styles.controlButtonsBlockViewMode : styles.controlButtonsBlock}>
            <XtButton label={AccountDetailsLabel.Cancel} onClick={onClose} />
            <XtButton label={AccountDetailsLabel.Save} type="submit" hidden={isViewMode} disabled={!isDirty} />
          </div>
        </div>
        <div className={styles.accountDetailsMainContainer}>
          <div className={styles.accountDetailsHeaderSection}>
            <div className={styles.accountDetailsFormContainer}>
              <FormField
                name={AccountDetailsField.AccountNumber}
                control={control}
                label={AccountDetailsLabel.AccountNumber}
                disabled={disabled || !isNewMode || isReadOnly}
              />
              <FormField
                name={AccountDetailsField.AccountName}
                control={control}
                label={AccountDetailsLabel.AccountName}
                disabled={disabled}
              />
              <FormXtAutocomplete
                name={AccountDetailsField.Parent}
                control={control}
                label={AccountDetailsLabel.Parent}
                renderOption={renderColumnOption}
                loadOptions={DocumentsUtilsService.loadAccountOptions}
                noOptionsText="No matching objects found."
                disabled={disabled}
              />
              <FormXtAutocomplete
                name={AccountDetailsField.Owner}
                control={control}
                label={AccountDetailsLabel.Owner}
                loadOptions={DocumentsUtilsService.loadUserOptions}
                renderOption={renderColumnOption}
                disabled={disabled || !canEditOwnerField}
              />
              <FormCheckboxLabel
                name={AccountDetailsField.Active}
                control={control}
                label={AccountDetailsField.Active}
                className={styles.accountDetailsCheckboxActive}
                disabled={disabled}
              />
              <FormRadioGroup
                options={accountDetailsType}
                name={AccountDetailsField.Type}
                control={control}
                className={styles.accountDetailsRadioGroup}
                disabled={disabled}
              />
            </div>
            {accountNumber && roles && (
              <ActiveRolesSection
                onChange={handleChanges}
                disabled={disabled}
                roles={roles}
                accountNumber={accountNumber}
                accountName={accountName}
              />
            )}
          </div>
        </div>
        <div className={styles.accountDetailsTabs}>
          {!state.loading && (
            <AccountDetailsTabs
              account={accountInput}
              isMobile={isMobile}
              disabled={disabled}
              remarks={remarksState}
              characteristics={characteristicsState}
              documents={documentsState}
              mode={mode}
              contactsUpdate$={contactsUpdate$}
              tasksUpdate$={tasksUpdate$}
            />
          )}
        </div>
      </form>
    </div>
  )
}
