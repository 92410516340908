import { Overwrite, Required } from 'utility-types'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { IAttachedDocumentWithFile } from 'documents/documents.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { CustomerCreatePayload, CustomerPayload, ICustomer, ISalesRep } from '../customers.types'
import { ICustomersTableItem } from '../customers-list/customers-list.types'

export interface ICustomersServices {
  CustomersService: ICustomersService
  CustomersUtilsService: ICustomersUtilsService
}

export interface ICustomersService {
  getAll(paginationParams: IPaginationParams, filters?: ICustomerFilters, sortOptions?: ISortOption[]): Promise<IPaginationData<ICustomer>>
  get(customer: string): Promise<ICustomer>
  getDuplicatedProspect(prospectNumber: string): Promise<boolean>
  getSalesReps(paginationParams?: IPaginationParams): Promise<IPaginationData<ISalesRep>>
  delete(customer_number: string): Promise<void>
  update(data: CustomerPayload): Promise<void>
  getCustomerPDF(customerNumber: string): Promise<Blob>
  create(data: CustomerCreatePayload, documents: IAttachedDocumentWithFile[]): Promise<void>
}

export interface ICustomersUtilsService {
  fetchCustomers(
    filters: CustomerFiltersPanel,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<ICustomersTableItem>>
  printCustomer(customerNumber: string): Promise<void>
}

export enum FilterFieldName {
  ShowInactive = 'showInactive',
  CustomerNumberPattern = 'customer_number_pattern',
  CustomerNamePattern = 'customer_name',
  CustomerType = 'customer_type',
  ContactNamePattern = 'contact_name',
  CityPattern = 'city',
  StatePattern = 'state',
  SalesRep = 'sales_rep',
  ShowDetail = 'showDetail',
}

export enum FilterLabel {
  ShowInactive = 'Show Inactive',
  CustomerNumberPattern = 'Customer Number Pattern',
  CustomerNamePattern = 'Customer Name Pattern',
  CustomerType = 'Customer Type',
  ContactNamePattern = 'Contact Name Pattern',
  CityPattern = 'City Pattern',
  StatePattern = 'State Pattern',
  SalesRep = 'Sales Rep.',
}

export interface ICustomerFilters {
  [FilterFieldName.ShowInactive]?: boolean
  [FilterFieldName.CustomerNumberPattern]?: string
  [FilterFieldName.CustomerNamePattern]?: string
  [FilterFieldName.CustomerType]?: string
  [FilterFieldName.ContactNamePattern]?: string
  [FilterFieldName.CityPattern]?: string
  [FilterFieldName.StatePattern]?: string
  [FilterFieldName.SalesRep]?: string
  [FilterFieldName.ShowDetail]?: boolean
}

export type CustomerFiltersPanel = Overwrite<
  Required<Omit<ICustomerFilters, FilterFieldName.ShowDetail>>,
  { [FilterFieldName.CustomerType]?: IXtAutocompleteOption | null }
>
