import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import { cls } from 'common/utils/utils'
import { homeRoutePath } from 'home/home.constants'
import logo from 'assets/img/x.png'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import * as styles from './sidebar-panel-dynamic.module.scss'
import * as mainStyles from '../sidebar.module.scss'
import { IXtSidebarPanelDynamic } from '../sidebar.types'

export const XtSidebarPanelDynamic: FC<IXtSidebarPanelDynamic> = ({
  sidebarOptions,
  onOptionClick,
  onProfileKeyDown,
  toggleProfile,
  shown,
  onClose,
}) => (
  <div className={styles.sidebarContainer}>
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
    <div className={shown ? styles.sidebarOverlay : ''} onClick={onClose} />
    <div className={cls(styles.sidebar, shown ? styles.sidebarShown : styles.sidebarHidden)}>
      <IconButton classes={{ root: 'xtSidebarDynamicPanelCloseIcon' }} onClick={onClose}>
        <SvgIcon iconId={SvgIconIds.CLOSE_ICON} className={styles.closeIconSize} />
      </IconButton>
      <div className={mainStyles.sidebarLogo}>
        <NavLink to={homeRoutePath}>
          <img className={mainStyles.sidebarLogoImage} src={logo} alt="xTuple Logo" />
        </NavLink>
      </div>
      <div className={mainStyles.sidebarContent}>
        <ul className={cls(styles.sidebarOptions)}>
          {sidebarOptions
            .filter(({ disabled }) => !disabled)
            .map(({ path, name, icon, exact }) => (
              <li key={path}>
                <NavLink
                  onClick={onOptionClick}
                  to={path}
                  exact={exact}
                  className={mainStyles.sidebarOptionLink}
                  activeClassName={mainStyles.sidebarOptionActive}
                  title={name}
                >
                  <div className={mainStyles.sidebarOption}>
                    <SvgIcon className={mainStyles.sidebarOptionIcon} iconId={icon} />
                    <p className={mainStyles.sidebarOptionLabel}>{name}</p>
                  </div>
                </NavLink>
              </li>
            ))}
        </ul>
        <div
          className={cls(mainStyles.sidebarOption, mainStyles.sidebarOptionLink, mainStyles.profileButton, styles.profileButton)}
          onClick={toggleProfile}
          tabIndex={0}
          role="button"
          onKeyDown={onProfileKeyDown}
        >
          <SvgIcon className={mainStyles.sidebarOptionIcon} iconId={SvgIconIds.USER_ICON} />
          <p className={mainStyles.sidebarOptionLabel}>Profile</p>
        </div>
      </div>
    </div>
  </div>
)
