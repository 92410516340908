import React, { BaseSyntheticEvent, FC, useCallback, useState } from 'react'

import { validatePhone } from 'common/validation/validation'
import { cls } from 'common/utils/utils'
import { UserPermission } from 'auth/auth.types'
import { useAuthModule } from 'auth/auth-module-hook'
import { XtTableCellActions } from '../../../table/table-cell-actions/table-cell-actions'
import { XtDialog, XtDialogAnimation } from '../../../xt-dialog/xt-dialog'
import { PhoneActionDialog } from '../phone-action-dialog/phone-action-dialog'
import { IPhoneLink, IPhoneLinkState, PhoneAction } from './phone-link.types'
import { initialPhoneState, definePhoneLinkActions } from './phone-link.constants'
import * as styles from './phone-link.module.scss'
import { PhoneInvalidDialog } from '../phone-invalid-dialog/phone-invalid-dialog'

function stopEventPropagation(event: BaseSyntheticEvent): void {
  event.stopPropagation()
}

export const PhoneLink: FC<IPhoneLink> = ({ phone, className, showMessageAction }) => {
  const { PermissionsService } = useAuthModule()

  const [phoneState, setPhoneState] = useState<IPhoneLinkState>(initialPhoneState)
  const [isInvalidPhoneDialogOpen, setInvalidPhoneDialogState] = useState<boolean>(false)

  const canSendSMS = PermissionsService.hasPermission(UserPermission.CanSendSMS)
  const canMakeVoiceCalls = PermissionsService.hasPermission(UserPermission.CanMakeVoiceCalls)

  const closeDialog = useCallback(() => {
    setPhoneState(initialPhoneState)
  }, [])

  const closeInvalidNumberDialog = useCallback(() => {
    setInvalidPhoneDialogState(false)
  }, [isInvalidPhoneDialogOpen])

  const handleIconClick = useCallback(
    (action: string) => {
      if (!phone || !validatePhone(phone)) {
        setInvalidPhoneDialogState(true)
        return
      }
      setPhoneState({ phone, action: action as PhoneAction, open: true })
    },
    [phone]
  )

  return (
    <div className={styles.phoneLink}>
      <span className={cls(styles.phoneValue, className)} title={phone}>
        {phone}
      </span>
      {phone && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
        <div onClick={stopEventPropagation} onKeyDown={stopEventPropagation}>
          <XtDialog className="xt-dialog-details-content" open={phoneState.open} animation={XtDialogAnimation.FadeAnimation}>
            <PhoneActionDialog phoneState={phoneState} onClose={closeDialog} />
          </XtDialog>
          <XtDialog className="xt-dialog-details-content" open={isInvalidPhoneDialogOpen} animation={XtDialogAnimation.FadeAnimation}>
            <PhoneInvalidDialog onClose={closeInvalidNumberDialog} />
          </XtDialog>
          <XtTableCellActions
            className={styles.phoneActionIcon}
            actions={definePhoneLinkActions(showMessageAction, canMakeVoiceCalls, canSendSMS)}
            onClick={handleIconClick}
          />
        </div>
      )}
    </div>
  )
}
