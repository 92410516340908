export const shippingModule = 'shippingModule'
export const shippingServiceToken = 'shippingServiceToken'
export const shippingUtilsServiceToken = 'shippingUtilsServiceToken'
export const shippingRoutePath = '/shipping'
export const shipmentsLabel = 'Shipments'

export const shippingRoutePaths = {
  recallOrders: 'recall-orders',
  issueToShipping: 'issue-to-shipping',
}
