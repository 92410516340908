export interface IFooterRoutes {
  name: string
  link: string
}

export const footerRoutes: IFooterRoutes[] = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'xTuple',
    link: 'https://www.xtuple.com',
  },
  {
    name: 'Support',
    link: 'https://portal.xtuple.com',
  },
  {
    name: 'Customer Portal',
    link: 'https://portal.xtuple.com',
  },
  {
    name: 'Forums',
    link: 'https://forums.xtuple.com',
  },
]
