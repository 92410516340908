import React, { FC, useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { useMediaQuery } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router'
import { cls } from 'common/utils/utils'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { confirmationMessages, xsMq } from 'common/constants'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { useTable } from 'common/hooks/useTable'
import { XtList } from 'components/list/list'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { XtMode } from 'common/common.types'
import { UserPermission } from 'auth/auth.types'
import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { tasksRoutePath } from 'tasks/tasks.constants'
import { quotesRoutePath } from 'quotes/quotes.constants'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { XtPageFilter } from 'components/pagefilter/pagefilter'
import { usePageFilter } from 'components/pagefilter/pagefilter.utils'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { resolveFilters } from './opportunities-list.utils'
import {
  defaultFilterValues,
  IOpportunitiesTableItem,
  OpportunitiesColumns,
  OpportunitiesListActionsEditMode,
  OpportunityAction,
} from './opportunities-list.constants'
import * as styles from './opportunities-list.module.scss'
import { useOpportunitiesModule } from '../opportunities-module-hook'
import { OpportunitiesFilter, OpportunitiesFiltersPanel } from '../services/opportunities-services.types'
import { opportunitiesKanbanRoutePath } from '../opportunities.constants'

export const OpportunitiesList: FC = () => {
  const { OpportunitiesService, OpportunitiesUtilsService } = useOpportunitiesModule()
  const { DocumentsUtilsService } = useDocumentsModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { PermissionsService } = useAuthModule()

  const history = useHistory()
  const { path } = useRouteMatch()

  const canCreateOpportunity = PermissionsService.hasSomePermission([
    UserPermission.MaintainAllOpportunities,
    UserPermission.MaintainPersonalOpportunities,
  ])

  const canCreateQuote = PermissionsService.hasPermission(UserPermission.MaintainQuotes)

  const canCreateTask = PermissionsService.hasSomePermission([
    UserPermission.MaintainAllTaskItems,
    UserPermission.MaintainPersonalTaskItems,
  ])

  const { itemId: itemIdToDelete, open: confirmationDialogOpen, openDialog, closeDialog } = useConfirmationDialog<string>()
  const {
    state: { loading, sortOptions, filters: tableFilters, data },
    setLoading,
    refresh,
    filter,
    pagination,
    sort,
  } = useTable<IOpportunitiesTableItem, OpportunitiesFiltersPanel>(
    defaultFilterValues,
    OpportunitiesUtilsService.fetchOpportunities,
    undefined,
    PageFilterMapping.Opportunity
  )

  const opportunitiesListFilters = usePageFilter<OpportunitiesFiltersPanel>(PageFilterMapping.Opportunity)

  const onOpenOpportunityDetails = useCallback<(opportunityNumber: string, mode: XtMode) => void>(
    (number, mode) => {
      history.push(`${path}/${number}/${mode}`)
    },
    [history, path]
  )

  const deactivateOpportunity = useCallback<(itemId: string) => Promise<void>>(
    async (itemId) => {
      try {
        await OpportunitiesService.deactivate(itemId)
        ToastService.showSuccess(`Opportunity ${itemId} has been deactivated.`)
        await refresh()
      } catch (error) {
        ErrorHandler.handleError(error as Error)
      }
    },
    [ErrorHandler, OpportunitiesService, ToastService, refresh]
  )

  const handleAction = useCallback<(item: IOpportunitiesTableItem, action: OpportunityAction) => void>(
    (item, action) => {
      const opportunityNumber = item.id.toString()
      switch (action) {
        case OpportunityAction.Delete: {
          openDialog(opportunityNumber)
          break
        }

        case OpportunityAction.Deactivate: {
          void deactivateOpportunity(opportunityNumber)
          break
        }

        case OpportunityAction.View: {
          onOpenOpportunityDetails(opportunityNumber, XtMode.View)
          break
        }

        case OpportunityAction.Edit: {
          onOpenOpportunityDetails(opportunityNumber, XtMode.Edit)
          break
        }

        case OpportunityAction.CreateQuote: {
          history.push(`${quotesRoutePath}/${XtMode.New}/opportunity/${opportunityNumber}/${item.account}`)
          break
        }

        case OpportunityAction.CreateTask: {
          history.push(`${tasksRoutePath}/${XtMode.New}/opportunity/${opportunityNumber}`)
          break
        }
        default:
          break
      }
    },
    [deactivateOpportunity, history, onOpenOpportunityDetails, openDialog]
  )

  const isMobile = useMediaQuery(xsMq)

  const handleDeletion = useCallback<VoidFunction>(async () => {
    closeDialog()
    if (itemIdToDelete) {
      try {
        setLoading(true)
        await OpportunitiesService.delete(itemIdToDelete)
        await refresh()
        ToastService.showSuccess(`Opportunity ${itemIdToDelete} has been deleted.`)
        setLoading(false)
      } catch (error) {
        ErrorHandler.handleError(error as Error)
        setLoading(false)
      }
    }
  }, [closeDialog, itemIdToDelete, setLoading, OpportunitiesService, refresh, ToastService, ErrorHandler])

  const openOpportunityDetailsDialog = useCallback(
    (item: IOpportunitiesTableItem) => {
      onOpenOpportunityDetails(item.id.toString(), item.editable ? XtMode.Edit : XtMode.View)
    },
    [onOpenOpportunityDetails]
  )

  const getOpportunitiesActions = useCallback(
    (item: Pick<IOpportunitiesTableItem, 'active' | 'editable'>, actions: IXtTableCellAction[]): IXtTableCellAction[] =>
      actions.filter((action) => {
        if (!item.editable && action.name !== OpportunityAction.View) {
          return false
        }
        switch (action.name) {
          case OpportunityAction.CreateTask:
            return canCreateTask
          case OpportunityAction.CreateQuote: {
            return canCreateQuote
          }
          case OpportunityAction.Deactivate: {
            return item.active
          }
          default:
            return true
        }
      }),
    [canCreateQuote, canCreateTask]
  )

  const handleShowInactiveFilterChange = (checked: boolean): void => {
    const params = { ...tableFilters, [OpportunitiesFilter.ShowInactive]: checked }
    void filter(params)
    void opportunitiesListFilters.handleLastUsedFilter(params)
  }

  return (
    <div className={cls('xt-content', styles.listContent)}>
      <XtConfirmationDialog
        open={confirmationDialogOpen}
        message={confirmationMessages.deleted}
        title="Delete Opportunity"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeDialog}
      />

      <div className={styles.listControls}>
        <div className={cls('xt-section-border', styles.listHeader)}>
          <h1 className="xt-page-title">Opportunities</h1>
          <XtResponsiveButton
            disabled={!canCreateOpportunity}
            label="New Opportunity"
            icon={SvgIconIds.ADD_CIRCLE}
            onClick={() => history.push(`${path}/${XtMode.New}`)}
          />
        </div>
        <div className={styles.filtersContainer}>
          <XtPageFilter
            state={opportunitiesListFilters}
            defaultFilterValues={defaultFilterValues}
            resolveFilters={() => resolveFilters(DocumentsUtilsService)}
            filter={filter}
            tableFilters={tableFilters}
          />
          <XtCheckbox
            className={styles.opportunitiesListCheckbox}
            label="Show Inactive"
            value={Boolean(tableFilters[OpportunitiesFilter.ShowInactive])}
            onChange={handleShowInactiveFilterChange}
            disabled={loading}
          />
          <div className={styles.opportunitiesListLinksGroup}>
            <NavLink
              to={opportunitiesKanbanRoutePath}
              className={styles.opportunitiesListLink}
              activeClassName={styles.opportunitiesListLinkActive}
              title="Kanban View"
            >
              <div>
                <SvgIcon iconId={SvgIconIds.VIEW_KANBAN} className={styles.opportunitiesListLinkIcon} />
                <span className="visible-hidden">Kanban View</span>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      <XtList
        actions={OpportunitiesListActionsEditMode}
        onRowClick={openOpportunityDetailsDialog}
        onAction={handleAction}
        isMobile={isMobile}
        pagination={pagination}
        loading={loading}
        data={data}
        columns={OpportunitiesColumns}
        getItemActions={getOpportunitiesActions}
        sortOptions={sortOptions}
        onColumnHeaderClick={sort}
      />
    </div>
  )
}
