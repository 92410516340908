import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Subject, Observable, Subscription } from 'rxjs'

import {
  MultipleFormGetFormValue,
  MultipleFormGetValue,
  IMultipleFormStateManagerHook,
  MultipleFormMap,
  MultipleFormStateMap,
  MultipleFormSubmitHandler,
  MultipleFormRegister,
  MultipleFormValidator,
  MultipleFormGetFormState,
  FormChanges,
} from './multiple-form-manager.types'
import { initialCombinedState } from './multiple-form-manager.constants'
import {
  buildMultipleFormObservable,
  convertCombinedFormState,
  convertCombinedFormStateToValue,
  validateNestedForms,
} from './multiple-form-manager.utils'
import { IFormState } from '../form.types'

export function useMultipleFormStateManager<
  FormType extends { [Key in keyof FormType]: FormType[Key] }
>(): IMultipleFormStateManagerHook<FormType> {
  const [combinedState, setCombinedState] = useState<IFormState>(initialCombinedState)
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const multipleFormRef = useRef<MultipleFormMap<FormType>>(new Map())
  const multipleFormStateRef = useRef<MultipleFormStateMap<FormType>>(new Map())
  const multipleFormObservableSubscriptionRef = useRef<Subscription | null>(null)

  const combinedFormStateSubject = useRef<Subject<FormChanges<FormType>>>(new Subject<FormChanges<FormType>>())
  const multipleFormChanges$ = useMemo<Observable<FormChanges<FormType>>>(() => combinedFormStateSubject.current.asObservable(), [])

  const register = useCallback<MultipleFormRegister<FormType>>((registerKey, formChanges$, validate, actualState) => {
    multipleFormRef.current.set(registerKey, { formChanges$, validate })
    multipleFormStateRef.current.set(registerKey, { data: actualState.data, state: actualState.state })

    const combinedFormState = convertCombinedFormState(multipleFormStateRef.current)
    combinedFormStateSubject.current.next(combinedFormState)
    setCombinedState((prev) => ({ ...prev, ...combinedFormState.state }))

    multipleFormObservableSubscriptionRef.current?.unsubscribe()

    multipleFormObservableSubscriptionRef.current = buildMultipleFormObservable(multipleFormRef.current).subscribe(
      ({ key, formChanges }) => {
        multipleFormStateRef.current.set(key, formChanges)
        const updatedState = convertCombinedFormState(multipleFormStateRef.current)
        combinedFormStateSubject.current.next(updatedState)
        setCombinedState((prev) => ({ ...prev, ...updatedState.state }))
      }
    )
  }, [])

  const getFormValue = useCallback<MultipleFormGetFormValue<FormType>>(
    <Key extends keyof FormType>(key: Key) => multipleFormStateRef.current.get(key)?.data as FormType[Key] | undefined,
    []
  )

  const getFormState = useCallback<MultipleFormGetFormState<FormType>>((key) => multipleFormStateRef.current.get(key)?.state, [])

  const getValue = useCallback<MultipleFormGetValue<FormType>>(() => convertCombinedFormStateToValue(multipleFormStateRef.current), [])

  const getState = useCallback<() => IFormState>(() => convertCombinedFormState(multipleFormStateRef.current).state, [])

  const validate = useCallback<MultipleFormValidator>(async () => validateNestedForms(multipleFormRef.current), [])

  const handleSubmit = useCallback<MultipleFormSubmitHandler<FormType>>(
    (onSave) => async () => {
      try {
        setSubmitting(true)
        const isValid = await validate()
        if (isValid) {
          await onSave(getValue())
        }
      } catch (e) {
        console.error(e)
      } finally {
        setSubmitting(false)
      }
    },
    [validate, getValue]
  )

  useEffect(
    () => () => {
      multipleFormObservableSubscriptionRef.current?.unsubscribe()
    },
    []
  )

  return {
    multipleFormChanges$,
    register,
    getFormValue,
    getFormState,
    getValue,
    getState,
    validate,
    formState: { ...combinedState, isSubmitting },
    handleSubmit,
  }
}
