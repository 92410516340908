import { NumberTypeUtils } from './type.utils'
import { IPaginationParams } from '../common.types'

export const cls = (...classes: (string | false | undefined)[]): string => classes.filter(Boolean).join(' ')

export const sleep = (ms: number): Promise<ReturnType<typeof setTimeout>> => new Promise((resolve) => setTimeout(resolve, ms))

export const reorderArray = <TData>(list: TData[], oldIndex: number, newIndex: number): TData[] => {
  const result = Array.from(list)
  const [removed] = result.splice(oldIndex, 1)
  result.splice(newIndex, 0, removed)

  return result
}

export const checkPaginationParams = (page?: number, limit?: number): IPaginationParams | undefined =>
  typeof page === 'number' && typeof limit === 'number' ? { page, limit } : undefined

export function convertNumberWithThousandSeparatorAndDigits(value: string | number, minDigits: number = 2, fixedDigits?: boolean): string {
  if (!NumberTypeUtils.isNumber(value)) {
    return (0).toLocaleString('us-US', { minimumFractionDigits: minDigits, maximumFractionDigits: fixedDigits ? minDigits : 16 })
  }
  return Number(value).toLocaleString('us-US', {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: fixedDigits ? minDigits : 16,
  })
}

export function numberConverter(value: unknown, fixedDigits = 2, fixedFractionDigits: boolean = true): string {
  return convertNumberWithThousandSeparatorAndDigits(Number(value), fixedDigits, fixedFractionDigits)
}

export function isSomeTextSelected(): boolean {
  const selection = window.getSelection()
  return selection?.type === 'Range'
}

// TODO: get rid of me when app will be updated to es2021
export function replaceAll(str: string, match: string, replacement: string): string {
  const regExp = new RegExp(match, 'g')
  return str.replace(regExp, replacement)
}

export function isInitializer<TValue>(value: TValue | (() => TValue)): value is () => TValue {
  return typeof value === 'function'
}

export function once<A extends never[], R>(fn: (...arg: A) => R): (...arg: A) => R {
  let result: R
  let called: boolean
  return (...args: A) => {
    if (!called) {
      called = true
      result = fn.apply(null, args)
    }
    return result
  }
}

export const removeEmptyFields = (obj: { [key: string]: unknown }): { [key: string]: unknown } => {
  return Object.keys(obj).reduce((acc, key) => {
    const _acc: { [key: string]: unknown } = acc

    if (typeof obj[key] !== 'undefined') {
      _acc[key] = obj[key]
    }
    return _acc
  }, {})
}
