import { useCallback, useState } from 'react'
import { XtMode } from 'common/common.types'

export interface ITaskDetailsDialogHookState {
  taskId: number | null
  taskDetailsDialogMode: XtMode
  taskDetailsDialogOpen: boolean
}

export interface ITaskDetailsDialogHook extends ITaskDetailsDialogHookState {
  openTaskDetailsDialog(id: number, mode: XtMode): void
  closeTaskDetailsDialog(): void
}

const defaultState: ITaskDetailsDialogHookState = {
  taskId: null,
  taskDetailsDialogMode: XtMode.View,
  taskDetailsDialogOpen: false,
}

export function useTaskDetailsDialogHook(): ITaskDetailsDialogHook {
  const [state, setState] = useState<ITaskDetailsDialogHookState>(defaultState)

  const openTaskDetailsDialog = useCallback<(id: number, mode: XtMode) => void>((id, mode) => {
    setState({ taskId: id, taskDetailsDialogMode: mode, taskDetailsDialogOpen: true })
  }, [])

  const closeTaskDetailsDialog = useCallback<VoidFunction>(() => {
    setState(defaultState)
  }, [])

  return {
    ...state,
    openTaskDetailsDialog,
    closeTaskDetailsDialog,
  }
}
