import { isSafari } from 'react-device-detect'
import * as breakpoints from '../../../assets/sass/breakpoints.module.scss'

export function checkIsLandscape({ innerHeight, innerWidth, screen: { availHeight, availWidth } }: Window): boolean {
  if (!isSafari) {
    return availHeight < availWidth
  }
  return innerHeight < innerWidth
}

export function checkIsMobile({ screen: { availWidth, availHeight } }: Window, isLandscape: boolean): boolean {
  const mobileWidthBreakpoint = Number(breakpoints.xs.replace('px', ''))

  if (!isSafari) {
    const deviceWidth = isLandscape ? availHeight : availWidth
    return deviceWidth < mobileWidthBreakpoint
  }
  return availWidth < mobileWidthBreakpoint
}
