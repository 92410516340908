// extracted by mini-css-extract-plugin
export var root = "opportunities-list_root__a5u6f";
export var xtContent = "opportunities-list_xt-content__6kvKl";
export var xtContentWithRemarks = "opportunities-list_xt-content-with-remarks__ZJOpW";
export var muiFormHelperTextRoot = "opportunities-list_MuiFormHelperText-root__HXIM9";
export var xtListPageContainer = "opportunities-list_xt-list-page-container__tmbl9";
export var listContent = "opportunities-list_listContent__pC-x8";
export var muiInputBaseRoot = "opportunities-list_MuiInputBase-root__j00NW";
export var muiTextFieldRoot = "opportunities-list_MuiTextField-root__R9IPi";
export var muiTablePaginationRoot = "opportunities-list_MuiTablePagination-root__bqR76";
export var muiTablePaginationSelectRoot = "opportunities-list_MuiTablePagination-selectRoot__ZBPC5";
export var muiTablePaginationSelect = "opportunities-list_MuiTablePagination-select__yhZxO";
export var xtPageTitle = "opportunities-list_xt-page-title__FZlii";
export var xtSectionBorder = "opportunities-list_xt-section-border__XyTp7";
export var xtPageHeader = "opportunities-list_xt-page-header__OxZiF";
export var xtDialogScrollable = "opportunities-list_xt-dialog-scrollable__S5htb";
export var reactToastNotificationsContainer = "opportunities-list_react-toast-notifications__container__8-k+A";
export var xtScrollbarStyle = "opportunities-list_xt-scrollbar-style__goyjX";
export var xtModalDetailsContent = "opportunities-list_xt-modal-details-content__8PXLU";
export var xtDialogDetailsContent = "opportunities-list_xt-dialog-details-content__IpOV2";
export var xtLazyModuleLoaderTabContainer = "opportunities-list_xt-lazy-module-loader-tab-container__ifWSW";
export var xtTextTruncated = "opportunities-list_xt-text-truncated__osBT5";
export var xtSummaryContent = "opportunities-list_xt-summary-content__CmHgM";
export var xtSummaryLabel = "opportunities-list_xt-summary-label__VsIS8";
export var visibleHidden = "opportunities-list_visible-hidden__mwRe+";
export var listHeader = "opportunities-list_listHeader__QO9TT";
export var listControls = "opportunities-list_listControls__NlIef";
export var filterButton = "opportunities-list_filterButton__jtK6R";
export var opportunitiesListCheckbox = "opportunities-list_opportunitiesListCheckbox__VBt66";
export var filtersContainer = "opportunities-list_filtersContainer__hlvUd";
export var opportunitiesListLinksGroup = "opportunities-list_opportunitiesListLinksGroup__TUjTx";
export var opportunitiesListLink = "opportunities-list_opportunitiesListLink__9qbIh";
export var opportunitiesListLinkActive = "opportunities-list_opportunitiesListLinkActive__dJVMK";
export var opportunitiesListLinkIcon = "opportunities-list_opportunitiesListLinkIcon__wn8Zd";