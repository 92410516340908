export enum SvgIconIds {
  ADD_CIRCLE = 'addCircle',
  CREATE = 'create',
  DELETE = 'delete',
  PRINT = 'print',
  PRODUCTS = 'products',
  ITEM_SOURCES = 'item-sources-icon',
  QUOTES = 'quotes',
  TASKS = 'tasks',
  VISIBILITY = 'visibility',
  COUNTER = 'counter',
  COMPARE_ARROW = 'compareArrow',
  EXPIRE = 'expire',
  EXPAND_MORE = 'expandMore',
  MARK_AS_COMPLETED = 'markAsCompleted',
  ARROW = 'arrow',
  ARROW_CIRCLE = 'arrow-circle',
  TWO_ARROW_CIRCLE = 'two-arrow-circle',
  CLOSE_ICON = 'close-icon',
  FILTER = 'filter',
  ADD_FILE = 'addFile',
  ATTACH = 'attach',
  DOWNLOAD = 'download',
  CONVERT = 'convert',
  MOBILE_MENU = 'mobileMenu',
  USER_ICON = 'user-icon',
  CLOSE_CIRCLE = 'closeCircle',
  OUTLINE_CLOSE_CIRLCE = 'outlineCloseCircle',
  SEARCH = 'searchIcon',
  HOME = 'home-icon',
  CRM = 'crm-icon',
  SALES_ORDERS = 'sales-orders-icon',
  EMAIL = 'email',
  CHECKED_CIRCLE = 'checkedCircle',
  PHONE = 'phone',
  CHAT = 'chat',
  DASHBOARD = 'dashboard-icon',
  CALENDAR_ICON = 'calendar-icon',
  INFO_ICON = 'info-icon',
  ALERT_ICON = 'alert-icon',
  SETTINGS = 'settings-icon',
  VENDORS = 'vendors-icon',
  PURCHASE_ORDERS = 'purchase-orders-icon',
  RUNNING_AVAILABILITY = 'running-availability-icon',
  PURCHASE_REQUESTS = 'purchase-requests-icon',
  COSTING = 'costing-icon',
  RENAME = 'rename-icon',
  SHIPPING = 'shipping-icon',
  MAINTAIN_SHIPPING = 'maintain-shipping-icon',
  RECALL = 'recall-icon',
  ADDITIONAL = 'additional-icon',
  LIST_ICON = 'list-alt',
  DONE_ICON = 'done-outline',
  CLOCK = 'clock-icon',
  RELEASE = 'release-icon',
  UNRELEASE = 'unrelease-icon',
  VIEW_ORDER = 'view-order-icon',
  EDIT_ORDER = 'edit-order-icon',
  CHARACTERISTICS = 'characteristics-icon',
  DOCUMENTS = 'documents-icon',
  INVENTORY_ADJUSTMENT = 'inventory-adjustment-icon',
  VIEW_KANBAN = 'view-kanban',
}

export interface ISvgIconProps {
  iconId: SvgIconIds
  className?: string
  hidden?: boolean
}
