// extracted by mini-css-extract-plugin
export var root = "accounts-list_root__j9OqN";
export var xtContent = "accounts-list_xt-content__+SKia";
export var xtContentWithRemarks = "accounts-list_xt-content-with-remarks__ghRxc";
export var muiFormHelperTextRoot = "accounts-list_MuiFormHelperText-root__v-1Gg";
export var xtListPageContainer = "accounts-list_xt-list-page-container__peDvO";
export var listContent = "accounts-list_listContent__8hnZy";
export var muiInputBaseRoot = "accounts-list_MuiInputBase-root__30LXK";
export var muiTextFieldRoot = "accounts-list_MuiTextField-root__Cq-W1";
export var muiTablePaginationRoot = "accounts-list_MuiTablePagination-root__dmIDL";
export var muiTablePaginationSelectRoot = "accounts-list_MuiTablePagination-selectRoot__ZxLcK";
export var muiTablePaginationSelect = "accounts-list_MuiTablePagination-select__iRvLB";
export var xtPageTitle = "accounts-list_xt-page-title__aQ5xp";
export var xtSectionBorder = "accounts-list_xt-section-border__49UK8";
export var xtPageHeader = "accounts-list_xt-page-header__uRxlK";
export var xtDialogScrollable = "accounts-list_xt-dialog-scrollable__-7lOd";
export var reactToastNotificationsContainer = "accounts-list_react-toast-notifications__container__PQuSp";
export var xtScrollbarStyle = "accounts-list_xt-scrollbar-style__8Bfmm";
export var xtModalDetailsContent = "accounts-list_xt-modal-details-content__NvOlW";
export var xtDialogDetailsContent = "accounts-list_xt-dialog-details-content__Ep-gM";
export var xtLazyModuleLoaderTabContainer = "accounts-list_xt-lazy-module-loader-tab-container__3Bibq";
export var xtTextTruncated = "accounts-list_xt-text-truncated__8czcl";
export var xtSummaryContent = "accounts-list_xt-summary-content__YrX+k";
export var xtSummaryLabel = "accounts-list_xt-summary-label__0axTK";
export var visibleHidden = "accounts-list_visible-hidden__H7AdL";
export var listHeader = "accounts-list_listHeader__ofYW+";
export var listControls = "accounts-list_listControls__7o0nl";
export var filterButton = "accounts-list_filterButton__v5uuQ";
export var accountsListCheckbox = "accounts-list_accountsListCheckbox__oMaCq";
export var filtersContainer = "accounts-list_filtersContainer__5YRK9";