import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { CoreModule } from 'core/core.module'
import { AuthModule } from 'auth/auth.module'
import { DocumentsModule } from 'documents/documents.module'
import { CommentsModule } from 'comments/comments.module'
import { CharacteristicsModule } from 'characteristics/characteristics.module'
import { TasksModule } from 'tasks/tasks.module'
import { ContactsModule } from 'contacts/contacts.module'
import { DictionaryModule } from 'dictionary/dictionary.module'
import { SharedModule } from 'shared/shared-module'
import { prospectsModuleToken } from './prospects.constants'
import { ProspectsServicesModule } from './services/prospects-services.module'

export type ProspectsModuleDefinition = typeof import('./prospects.export')

export const ProspectsModule: ILazyModule<ProspectsModuleDefinition> = {
  name: prospectsModuleToken,
  resolver: () => import('./prospects.export'),
  dependencies: [
    CoreModule,
    SharedModule,
    ProspectsServicesModule,
    DictionaryModule,
    AuthModule,
    DocumentsModule,
    CommentsModule,
    CharacteristicsModule,
    TasksModule,
    ContactsModule,
  ],
}
