import { useState, useCallback, useEffect, useContext, createContext } from 'react'
import { IPageFilter, IParsedPageFilter, LastUsed, PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { buildPageFilerValues, parseFilterValues } from 'core/services/pagefilters/pagefilters.utils'
import { once } from 'common/utils/utils'
import { FilterOption, IHandlePageFilter, IPageFilterSelect, IXtPageFilterContext } from './pagefilter.types'
import { IFilterFormState } from './filter/filter.types'

export const createPageFilterContext = once(<TFilter>() => createContext({} as TFilter))

export const usePageFilterContext = <TFilter extends IFilterFormState>(): IXtPageFilterContext<TFilter> =>
  useContext(createPageFilterContext<IXtPageFilterContext<TFilter>>())

function usePageFilterSelect(pagename: PageFilterMapping): IPageFilterSelect {
  const { PageFilterUtilsService, ErrorHandler } = useCoreModule()

  const [options, setOptions] = useState<FilterOption[]>([])
  const [lastUsedFilter, setLastUsedFilter] = useState<IParsedPageFilter | null>(null)

  const requestOptions = async (): Promise<void> => {
    try {
      const { filters } = await PageFilterUtilsService.getAllFilter(pagename)

      const filterOptions = filters
        ? filters
            .filter((filter: IPageFilter) => filter.filtername !== LastUsed)
            .map((item: IPageFilter) => ({ id: item.filtername, value: item?.filtervalues ?? '', label: item.filtername }))
        : []

      const lastUsedResp = filters ? filters.find((filter: IPageFilter) => filter.filtername === LastUsed) : null

      if (lastUsedResp) {
        try {
          const lastUsed = parseFilterValues<IParsedPageFilter>(lastUsedResp?.filtervalues ?? '')
          setLastUsedFilter(lastUsed ?? null)
        } catch (e) {
          setLastUsedFilter(null)
        }
      }
      setOptions(filterOptions)
    } catch (e) {
      ErrorHandler.handleError(e)
      setOptions([])
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    void requestOptions()
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reset = useCallback(async () => requestOptions(), [])

  return {
    reset,
    options,
    lastUsedFilter,
    setLastUsedFilter,
  }
}

export function usePageFilter<TFilter extends IFilterFormState>(pagename: PageFilterMapping): IHandlePageFilter<TFilter> {
  const { PageFilterUtilsService, ErrorHandler } = useCoreModule()
  const { AuthService } = useAuthModule()

  const { options: pageFilters, lastUsedFilter, reset, setLastUsedFilter } = usePageFilterSelect(pagename)

  const username = AuthService.getUsername()

  const deletePageFilter = useCallback(
    async (filter: FilterOption): Promise<void> => {
      try {
        if (!confirm(`Are you sure you want to remove filters pre-set ${filter?.label}?`)) {
          return
        }
        if (username) {
          await PageFilterUtilsService.deleteFilter({ pagename, username, filtername: filter.id })
          await reset()
        }
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    },
    [username, PageFilterUtilsService, pagename, reset, ErrorHandler]
  )

  const handleLastUsedFilter = useCallback(
    async (formValues: TFilter): Promise<void> => {
      try {
        const filterValues = buildPageFilerValues(formValues)
        if (username && filterValues) {
          if (lastUsedFilter) {
            const newLastUsed = await PageFilterUtilsService.updateLastUsedFilter({ pagename, username, filterValues })
            setLastUsedFilter(newLastUsed)
          } else {
            const newLastUsed = await PageFilterUtilsService.createLastUsedFilter({ pagename, username, filterValues })
            setLastUsedFilter(newLastUsed)
          }
        }
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    },
    [username, lastUsedFilter, PageFilterUtilsService, pagename, setLastUsedFilter, ErrorHandler]
  )

  const updatePageFilter = useCallback(
    async (formValues: TFilter, filtername: string, shared?: boolean): Promise<void> => {
      const filterValues = buildPageFilerValues(formValues)

      if (username && filterValues) {
        let data = {
          pagename,
          username,
          filters: [
            {
              shared,
              filtername,
              filtervalues: filterValues,
            },
          ],
        }
        await PageFilterUtilsService.updateFilter(data)
      }
    },
    [username, pagename, PageFilterUtilsService]
  )

  const createPageFilter = useCallback(
    async (formValues: TFilter, filtername: string, shared?: boolean): Promise<void> => {
      const filterValues = buildPageFilerValues(formValues)

      if (username && filterValues) {
        let data = {
          pagename,
          username,
          filters: [
            {
              shared,
              filtername,
              filtervalues: filterValues,
            },
          ],
        }
        await PageFilterUtilsService.createFilter(data)
        await reset()
      }
    },
    [username, pagename, PageFilterUtilsService, reset]
  )

  return {
    pagename,
    pageFilters,
    lastUsedFilter: lastUsedFilter as TFilter,
    deletePageFilter,
    handleLastUsedFilter,
    updatePageFilter,
    createPageFilter,
  }
}
