import * as React from 'react'
import { FC, memo, useCallback, useEffect, useState } from 'react'
import { FormCheckboxLabel, FormDatePicker, FormField, FormXtAutocomplete } from 'common/utils/form/form.components'
import { useXtForm } from 'common/hooks/form/form'
import { cls } from 'common/utils/utils'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { maxNumber } from 'common/validation/validation'
import { IContact } from 'contacts/contacts.types'
import { XtQuickAddButton } from 'components/buttons/xt-quick-add-button/xt-quick-add-button'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { getAutocompleteInputLabelAsId, renderColumnOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { XtMode } from 'common/common.types'
import { UserPermission } from 'auth/auth.types'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { NumberTypeUtils } from 'common/utils/type.utils'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useContactsModule } from 'contacts/contacts-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { convertContactOption } from 'contacts/contacts.utils'
import { useDictionaryModule } from 'dictionary/dictionary-module-hook'
import { defineGeneralFormData, maxProbabilityValue } from './general-form.utils'
import { IOpportunityFormValues, OpportunityFormField, OpportunityFormLabel } from '../opportunity-details.types'
import { IGeneralForm } from './general-form.types'
import { GeneralFormSchema } from './general-form.validation'
import * as styles from './general-form.module.scss'
import { oppGeneralFormKey } from './general-form.constants'
import { OpportunityStageOption } from '../../opportunities.types'
import { useOpportunitiesModule } from '../../opportunities-module-hook'

export const GeneralForm: FC<IGeneralForm> = memo(({ disabled, opportunity, isMobile, user, register }) => {
  const { DocumentsUtilsService } = useDocumentsModule()
  const { FormContactSearch, ContactDialogForm } = useContactsModule()
  const { PermissionsService } = useAuthModule()
  const { DictionaryUtilsService } = useDictionaryModule()
  const { OpportunitiesUtilsService } = useOpportunitiesModule()

  const { control, reset, formChanges$, trigger, setValue, watch, formState, getValues } = useXtForm<IOpportunityFormValues>({
    defaultValues: defineGeneralFormData(opportunity, user),
    validationSchema: GeneralFormSchema,
    mode: 'onBlur',
  })

  const canEditOwnerField = PermissionsService.hasPermission(UserPermission.EditOwner)
  const canMaintainContact = PermissionsService.hasSomePermission([
    UserPermission.MaintainAllContacts,
    UserPermission.MaintainPersonalContacts,
  ])
  const [openContact, setOpenContact] = useState<boolean>(false)

  const account = watch(OpportunityFormField.Account)
  const startDate = watch(OpportunityFormField.StartDate)

  useEffect(() => {
    reset(defineGeneralFormData(opportunity, user))
  }, [reset, opportunity, user])

  const closeContactDialogForm = useCallback<VoidFunction>(() => {
    setOpenContact(false)
  }, [])

  const onContactCreation = useCallback<(payload: IContact) => void>(
    (payload) => {
      setValue(OpportunityFormField.Contact, convertContactOption(payload), { shouldDirty: true, shouldValidate: true })
    },
    [setValue]
  )

  const handleAssignedToChange = useCallback<(option: IXtAutocompleteOption) => void>(
    (option) => {
      setValue(OpportunityFormField.AssignedTo, option, { shouldDirty: true })
      setValue(OpportunityFormField.AssigneeDate, option ? new Date() : null, { shouldDirty: true })
    },
    [setValue]
  )

  const handleStageChange = useCallback<(option: OpportunityStageOption | null) => void>(
    (option) => {
      setValue(OpportunityFormField.Stage, option, { shouldValidate: true, shouldDirty: true })
      if (NumberTypeUtils.isNumber(option?.probability_percent)) {
        setValue(OpportunityFormField.Probability, option?.probability_percent ?? 0, {
          shouldDirty: true,
        })
      }
      if (option?.mark_opportunity_inactive) {
        setValue(OpportunityFormField.Active, false)
      }
    },
    [setValue]
  )

  useEffect(() => {
    register(oppGeneralFormKey, formChanges$, trigger, { data: getValues(), state: formState })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, formChanges$])

  return (
    <form>
      <XtDialog className="xt-modal-details-content" open={openContact} animation={XtDialogAnimation.SlideAnimation}>
        <ContactDialogForm
          onCreate={onContactCreation}
          onClose={closeContactDialogForm}
          contact={null}
          mode={XtMode.New}
          serverSideUpdate
        />
      </XtDialog>
      <div className={styles.checkboxActive}>
        <FormCheckboxLabel control={control} disabled={disabled} name={OpportunityFormField.Active} label={OpportunityFormLabel.Active} />
      </div>
      <div className={styles.generalFormFields}>
        <div className={styles.leftSide}>
          <FormField disabled control={control} name={OpportunityFormField.Number} label={OpportunityFormLabel.Number} />
          <FormField control={control} disabled={disabled} name={OpportunityFormField.Name} label={OpportunityFormLabel.Name} />
          <FormXtAutocomplete
            control={control}
            name={OpportunityFormField.Account}
            label={OpportunityFormLabel.Account}
            loadOptions={DocumentsUtilsService.loadCRMAccounts}
            disabled={disabled}
          />
          <DecimalFormField
            disabled={disabled}
            name={OpportunityFormField.Amount}
            control={control}
            label={OpportunityFormLabel.Amount}
            maxValue={maxNumber}
            allowNegative={false}
          />
          <FormXtAutocomplete
            disabled={disabled}
            loadOptions={DictionaryUtilsService.loadCurrencyOptions}
            name={OpportunityFormField.Currency}
            control={control}
            label={OpportunityFormLabel.Currency}
          />
          <FormXtAutocomplete
            disabled={disabled || !canEditOwnerField}
            control={control}
            name={OpportunityFormField.Owner}
            label={OpportunityFormLabel.Owner}
            loadOptions={DocumentsUtilsService.loadUserOptions}
          />
          <FormXtAutocomplete
            disabled={disabled}
            control={control}
            name={OpportunityFormField.AssignedTo}
            label={OpportunityFormLabel.AssignedTo}
            onChange={handleAssignedToChange}
            loadOptions={DocumentsUtilsService.loadUserOptions}
          />
          <FormXtAutocomplete
            disabled={disabled}
            control={control}
            name={OpportunityFormField.Project}
            label={OpportunityFormLabel.Project}
            loadOptions={DocumentsUtilsService.loadProjectOptions}
            getInputLabel={getAutocompleteInputLabelAsId}
            renderOption={renderColumnOption}
          />
          <FormXtAutocomplete
            disabled={disabled}
            control={control}
            name={OpportunityFormField.OppType}
            label={OpportunityFormLabel.OppType}
            loadOptions={DocumentsUtilsService.loadOpportunityTypeOptions}
          />
          <FormXtAutocomplete
            disabled={disabled}
            control={control}
            name={OpportunityFormField.Stage}
            label={OpportunityFormLabel.Stage}
            loadOptions={OpportunitiesUtilsService.loadOpportunityStageOptions}
            onChange={handleStageChange}
          />
          <FormXtAutocomplete
            disabled={disabled}
            control={control}
            name={OpportunityFormField.Priority}
            label={OpportunityFormLabel.Priority}
            loadOptions={DocumentsUtilsService.loadDocumentPriorityOptions}
          />
          <FormXtAutocomplete
            disabled={disabled}
            control={control}
            name={OpportunityFormField.LeadSource}
            label={OpportunityFormLabel.LeadSource}
            loadOptions={DocumentsUtilsService.loadDocumentOppSourceOptions}
          />
          <DecimalFormField
            disabled={disabled}
            control={control}
            name={OpportunityFormField.Probability}
            label={OpportunityFormLabel.Probability}
            maxValue={maxProbabilityValue}
            fixedDecimalScale={0}
            allowNegative={false}
          />
        </div>
        <div className={styles.rightSide}>
          <div className={styles.contactSection}>
            <FormContactSearch
              control={control}
              name={OpportunityFormField.Contact}
              disabled={disabled || !canMaintainContact}
              className={cls(styles.contactField, account?.id && styles.contactFilterByAccountCheckbox)}
              accountNumber={account?.id}
              isMobile={isMobile}
              label={OpportunityFormLabel.Contact}
            />
            <XtQuickAddButton disabled={disabled || !canMaintainContact} onClick={() => setOpenContact(true)} />
          </div>
          <div className={styles.dateFields}>
            <FormDatePicker
              name={OpportunityFormField.StartDate}
              label={OpportunityFormLabel.StartDate}
              control={control}
              disabled={disabled}
            />
            <FormDatePicker
              name={OpportunityFormField.AssigneeDate}
              label={OpportunityFormLabel.AssigneeDate}
              control={control}
              disabled={disabled}
              minDate={startDate}
            />
            <FormDatePicker
              name={OpportunityFormField.TargetDate}
              label={OpportunityFormLabel.TargetDate}
              control={control}
              disabled={disabled}
              minDate={startDate}
            />
            <FormDatePicker
              name={OpportunityFormField.ActualDate}
              label={OpportunityFormLabel.ActualDate}
              control={control}
              disabled={disabled}
              minDate={startDate}
            />
          </div>
        </div>
      </div>
    </form>
  )
})
