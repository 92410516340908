import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { QuotesAction } from 'sales-shared/quotes.types'

export const QuotesListActionsEditMode: IXtTableCellAction[] = [
  {
    name: QuotesAction.Edit,
    label: QuotesAction.Edit,
    icon: SvgIconIds.CREATE,
  },
  {
    name: QuotesAction.View,
    label: QuotesAction.View,
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: QuotesAction.Delete,
    label: QuotesAction.Delete,
    icon: SvgIconIds.DELETE,
  },
  {
    name: QuotesAction.Copy,
    label: QuotesAction.Copy,
    icon: SvgIconIds.ADD_FILE,
  },
  {
    name: QuotesAction.CopyTo,
    label: QuotesAction.CopyTo,
    icon: SvgIconIds.COMPARE_ARROW,
  },
  {
    name: QuotesAction.PrintForm,
    label: QuotesAction.PrintForm,
    icon: SvgIconIds.PRINT,
  },
]
