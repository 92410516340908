import { XtMode } from 'common/common.types'
import { StageTaskOption, TaskStatusOption } from './tasks.types'
import { defineTaskOption } from './tasks.utils'

export const tasksRoutes = {
  calendar: 'calendar',
  kanban: 'kanban',
}

export const tasksRoutePath: string = '/activities/tasks'
export const tasksCalendarRoutePath: string = `${tasksRoutePath}/${tasksRoutes.calendar}`
export const tasksKanbanRoutePath: string = `${tasksRoutePath}/${tasksRoutes.kanban}`
export const tasksModule = 'TasksModule'
export const tasksServiceToken = 'TasksService'
export const tasksUtilsServiceToken = 'TasksUtilsServiceToken'
export const tasksKanbanServiceToken = 'TasksKanbanService'
export const tasksKanbanUtilsServiceToken = 'TasksKanbanUtilsService'

export const defaultTaskDetailsDialogState = { id: null, mode: XtMode.New, open: false }

export const taskStatusOptions: TaskStatusOption[] = Object.values(StageTaskOption).map(defineTaskOption)
