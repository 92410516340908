import type { IXtMode } from 'common/common.types'
import type { IOpportunityRequestParams } from './opportunity-details.types'
import React, { FC, useCallback } from 'react'
import { useHistory, useParams } from 'react-router'
import { activitiesRoutePath, activitiesRoutes } from 'activities/activities.constants'
import { OpportunityDetails } from './opportunity-details'

export const OpportunityDetailsPage: FC<IXtMode> = ({ mode }) => {
  const { opportunityNumber } = useParams<IOpportunityRequestParams>()
  const history = useHistory()

  const onClose = useCallback<VoidFunction>(() => {
    history.push(`${activitiesRoutePath}/${activitiesRoutes.opportunities}`)
  }, [history])

  return <OpportunityDetails onClose={onClose} mode={mode} opportunityNumber={opportunityNumber} />
}
