export const productsRoutePath: string = '/products'
export const productsModule: string = 'ProductsModule'
export const routingServiceToken: string = 'RoutingService'
export const bomServiceToken: string = 'BomService'
export const itemsServiceToken: string = 'ItemsService'
export const itemSitesServiceToken: string = 'ItemSitesService'
export const itemSitesUtilsServiceToken: string = 'ItemSitesUtilsService'
export const bomItemsServiceToken: string = 'BomItemsService'
export const bomUtilsServiceToken: string = 'BomUtilsService'
export const itemsUtilsServiceToken: string = 'ItemsUtilsService'
export const routingUtilsServiceToken: string = 'RoutingUtilsService'

export const productsRoutes = {
  items: 'items',
  inventory: 'inventory',
  bom: 'bom',
  routing: 'routing',
}
