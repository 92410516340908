import type { IAsyncRoute, IRoute } from 'common/routing/routing.types'
import { XtMode } from 'common/common.types'
import { buildPageComponent } from 'common/routing/routing.utils'
import { VendorGuard } from 'vendor/vendor.guard'
import { vendorRouteFactory } from 'vendor/vendor.routes'
import { AccountDetailsGuard } from './accounts/account-details/account-details.guard'
import { AccountDetailsPage } from './accounts/accounts.exports'
import { AccountsGuard } from './accounts/accounts.guard'
import { companiesRoutePath, companiesRoutes } from './companies.constants'
import { CompaniesGuard } from './companies.guard'
import { CompaniesModule, CompaniesModuleDefinition } from './companies.module'
import { CustomerDetailsGuard } from './customers/customer-details/customer-details.guard'
import { CustomerDetailsPage } from './customers/customers.export'
import { CustomersGuard } from './customers/customers.guard'
import { ProspectDetailsGuard } from './prospects/prospect-details/prospect-details.guard'
import { ProspectDetailsPage } from './prospects/prospects.export'
import { ProspectsGuard } from './prospects/prospects.guard'
import { ContactsGuard } from './contacts/contacts.guard'
import { CompaniesContactsList, ContactsDetailsPage } from './contacts/contacts.export'
import { ContactsDetailsGuard } from './contacts/contacts-details.guard'

function companiesRouteFactory(moduleDefinition: CompaniesModuleDefinition): IRoute[] {
  const { AccountsList, CustomersList, ProspectsList } = moduleDefinition
  return [
    {
      path: companiesRoutes.allAccounts,
      name: 'All Accounts',
      guards: [AccountsGuard],
      children: [
        {
          path: '',
          exact: true,
          component: AccountsList,
        },
        {
          path: `/:id/${XtMode.Edit}`,
          component: (props) => buildPageComponent(props, XtMode.Edit, AccountDetailsPage),
          guards: [AccountDetailsGuard],
        },
        {
          path: `/:id/${XtMode.View}`,
          component: (props) => buildPageComponent(props, XtMode.View, AccountDetailsPage),
        },
        {
          path: `/${XtMode.New}`,
          component: (props) => buildPageComponent(props, XtMode.New, AccountDetailsPage),
          guards: [AccountDetailsGuard],
        },
      ],
    },
    {
      path: companiesRoutes.customers,
      name: 'Customers',
      guards: [CustomersGuard],
      children: [
        {
          path: '',
          exact: true,
          component: CustomersList,
        },
        {
          path: `/:customerNumber/${XtMode.Edit}`,
          component: (props) => buildPageComponent(props, XtMode.Edit, CustomerDetailsPage),
          guards: [CustomerDetailsGuard],
        },
        {
          path: `/:customerNumber/${XtMode.View}`,
          component: (props) => buildPageComponent(props, XtMode.View, CustomerDetailsPage),
        },
        {
          path: `/${XtMode.New}`,
          component: (props) => buildPageComponent(props, XtMode.New, CustomerDetailsPage),
          guards: [CustomerDetailsGuard],
        },
      ],
    },
    {
      path: companiesRoutes.prospects,
      name: 'Prospects',
      guards: [ProspectsGuard],
      children: [
        {
          path: '',
          exact: true,
          component: ProspectsList,
        },
        {
          path: `/:prospectNumber/${XtMode.Edit}`,
          component: (props) => buildPageComponent(props, XtMode.Edit, ProspectDetailsPage),
          guards: [ProspectDetailsGuard],
        },
        {
          path: `/:prospectNumber/${XtMode.View}`,
          component: (props) => buildPageComponent(props, XtMode.View, ProspectDetailsPage),
        },
        {
          path: `/${XtMode.New}`,
          component: (props) => buildPageComponent(props, XtMode.New, ProspectDetailsPage),
          guards: [ProspectDetailsGuard],
        },
      ],
    },
    {
      path: companiesRoutes.vendors,
      name: 'Vendors',
      guards: [VendorGuard],
      children: vendorRouteFactory(moduleDefinition),
    },
    {
      path: companiesRoutes.contacts,
      name: 'Contacts',
      guards: [ContactsGuard],
      children: [
        {
          path: '',
          exact: true,
          component: CompaniesContactsList,
        },
        {
          path: `/:contactNumber/${XtMode.Edit}`,
          component: (props) => buildPageComponent(props, XtMode.Edit, ContactsDetailsPage),
          guards: [ContactsDetailsGuard],
        },
        {
          path: `/:contactNumber/${XtMode.View}`,
          component: (props) => buildPageComponent(props, XtMode.View, ContactsDetailsPage),
        },
        {
          path: `/${XtMode.New}`,
          component: (props) => buildPageComponent(props, XtMode.New, ContactsDetailsPage),
          guards: [ContactsDetailsGuard],
        },
      ],
    },
  ]
}

export const companiesRoute: IAsyncRoute<CompaniesModuleDefinition> = {
  path: companiesRoutePath,
  module: CompaniesModule,
  name: 'Company',
  guards: [CompaniesGuard],
  childrenRoutesFactory: companiesRouteFactory,
}
