import { renderEmailCell } from 'components/controls/email/email-link/email-link.utils'
import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { dateConverter } from 'common/utils/date.utils'
import { renderPhoneCell } from 'components/controls/phone/phone-link/phone-link.utils'
import { convertPhoneData } from 'common/utils/table.utils'
import { PhoneRole } from 'contacts/contacts.types'
import { ProspectAction, IProspectTableItem } from './prospects-list.types'
import { ProspectFilterPanel, ProspectFiltersFieldName } from '../services/prospects-services.types'

export const defaultDeletionState = {
  CRMProspectNumberId: '',
  confirmationOpen: false,
}

export const ProspectActionsEditMode: IXtTableCellAction[] = [
  {
    name: ProspectAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: ProspectAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: ProspectAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const ProspectActionsViewMode: IXtTableCellAction[] = [
  {
    name: ProspectAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
]

export const prospectColumns: IXtTableColumn<IProspectTableItem>[] = [
  {
    id: 'number',
    field: 'number',
    headerName: 'Number',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'name',
    field: 'name',
    headerName: 'Name',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'created',
    field: 'created',
    headerName: 'Created',
    flex: '1 0 140px',
    width: 140,
    converter: dateConverter,
  },
  {
    id: 'owner',
    field: 'owner',
    headerName: 'Owner',
    flex: '1 0 170px',
    width: 170,
  },
  {
    id: 'assigned_user',
    field: 'assigned_user',
    headerName: 'Assigned',
    flex: '1 0 170px',
    width: 170,
  },
  {
    id: 'source',
    field: 'source',
    headerName: 'Source',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'first_name',
    field: 'first_name',
    headerName: 'First Name',
    flex: '1 0 170px',
    width: 170,
    unsortable: true,
  },
  {
    id: 'last_name',
    field: 'last_name',
    headerName: 'Last Name',
    flex: '1 0 170px',
    width: 170,
    unsortable: true,
  },
  {
    id: 'phone',
    headerName: 'Phone',
    flex: '1 0 300px',
    width: 300,
    unsortable: true,
    renderCell: (item) => renderPhoneCell(convertPhoneData(item?.primary_contact?.phones, PhoneRole.Office)),
  },
  {
    id: 'email',
    headerName: 'Email',
    flex: '1 0 260px',
    width: 260,
    unsortable: true,
    renderCell: (item) => renderEmailCell(item?.primary_contact?.email),
  },
  {
    id: 'address',
    field: 'address',
    headerName: 'Address',
    flex: '1 0 260px',
    width: 260,
    unsortable: true,
  },
  {
    id: 'city',
    field: 'city',
    headerName: 'City',
    flex: '1 0 140px',
    width: 140,
    unsortable: true,
  },
  {
    id: 'state',
    field: 'state',
    headerName: 'State',
    flex: '1 0 110px',
    width: 110,
    unsortable: true,
  },
  {
    id: 'country',
    field: 'country',
    headerName: 'Country',
    flex: '1 0 120px',
    width: 120,
    unsortable: true,
  },
  {
    id: 'postalcode',
    field: 'postalcode',
    headerName: 'Postal Code',
    flex: '1 0 140px',
    width: 140,
    unsortable: true,
  },
]

export const defaultFilterValues: ProspectFilterPanel = {
  [ProspectFiltersFieldName.ShowInactive]: false,
  [ProspectFiltersFieldName.Owner]: null,
  [ProspectFiltersFieldName.Source]: null,
  [ProspectFiltersFieldName.AssignedTo]: null,
  [ProspectFiltersFieldName.ProspectNumber]: '',
  [ProspectFiltersFieldName.ProspectName]: '',
  [ProspectFiltersFieldName.ContactName]: '',
  [ProspectFiltersFieldName.City]: '',
  [ProspectFiltersFieldName.State]: '',
}
