import { IAppliedCharacteristic } from 'characteristics/characteristics.types'
import { characteristicsToPayload } from 'characteristics/characteristics.utils'
import { CustomerCreatePayload } from '../customers.types'
import { ICustomerMainForm } from './customer-details-form/customer-details-form.types'
import { ContactSectionFormField, IContactSectionForm } from '../../../contacts/components/contact-section/contact-section.types'

export function shouldDisableCustomerType(isNewMode: boolean, isEditMode: boolean, canCreateType: boolean, canEditType: boolean): boolean {
  const disabledInEditMode = isEditMode && !canEditType
  const disabledInNewMode = isNewMode && !canCreateType && !canEditType

  return disabledInNewMode || disabledInEditMode
}

export function convertCustomerPayload(
  mainForm: ICustomerMainForm,
  contactSectionForm: IContactSectionForm,
  characteristics: IAppliedCharacteristic[],
  notes: string
): CustomerCreatePayload {
  return {
    ...mainForm,
    billing_contact: contactSectionForm[ContactSectionFormField.Contact1],
    correspond_contact: contactSectionForm[ContactSectionFormField.Contact2],
    notes,
    customer_type: mainForm.customer_type?.id ?? '',
    customer_characteristics: characteristicsToPayload(characteristics),
  }
}
