import * as React from 'react'
import { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormCheckboxLabel, FormField } from 'common/utils/form/form.components'
import { ProspectFormField, ProspectFormLabel, IProspectForm } from '../prospect-details.types'
import { IProspectDetailsForm } from './prospect-details-form.types'
import * as styles from '../prospect-details.module.scss'

export const ProspectDetailsForm: FC<IProspectDetailsForm> = memo(({ disabled, disabledProspectNumber, disabledProspectName }) => {
  const { control } = useFormContext<IProspectForm>()

  return (
    <div className={styles.prospectDetailsMainContentForm}>
      <FormField
        control={control}
        disabled={disabled || disabledProspectNumber}
        name={ProspectFormField.Number}
        label={ProspectFormLabel.Number}
      />
      <FormField control={control} disabled={disabledProspectName} name={ProspectFormField.Name} label={ProspectFormLabel.Name} />
      <FormCheckboxLabel control={control} label={ProspectFormLabel.Active} name={ProspectFormField.Active} disabled={disabled} />
    </div>
  )
})
