import { IAsyncRoute } from 'common/routing/routing.types'
import { ShippingModule, ShippingModuleDefinition } from './shipping.module'
import { shipmentsLabel, shippingRoutePath, shippingRoutePaths } from './shipping.constants'
import { ShippingGuard } from './shipping.guard'
import { RecallOrdersToShippingGuard } from './components/recall-orders-to-shipping/recall-orders-to-shipping.guard'
import { ShipOrderGuard } from './components/ship-order/ship-order.guard'
import { PrintDialogsGuard } from './print-dialogs/print-dialogs.guard'
import { IssueToShippingGuard } from './components/issue-to-shipping/issue-to-shipping.guard'

export const shippingRoute: IAsyncRoute<ShippingModuleDefinition> = {
  path: shippingRoutePath,
  name: shipmentsLabel,
  module: ShippingModule,
  guards: [ShippingGuard],
  childrenRoutesFactory: ({ RecallOrdersToShipping, IssueToShippingList, PrintDialogsPage, ShipOrderPage }) => [
    {
      path: shippingRoutePaths.recallOrders,
      name: 'Recall Orders',
      exact: true,
      guards: [RecallOrdersToShippingGuard],
      children: [{ path: '', exact: true, component: RecallOrdersToShipping }],
    },
    {
      path: shippingRoutePaths.issueToShipping,
      name: 'Issue to Shipping',
      guards: [IssueToShippingGuard],
      children: [{ path: '', exact: true, component: IssueToShippingList }],
    },
    // TODO remove me
    {
      path: 'prints',
      name: 'Prints',
      exact: true,
      guards: [PrintDialogsGuard],
      children: [{ path: '', exact: true, component: PrintDialogsPage }],
    },
    {
      path: 'ship-order',
      name: 'Ship Order',
      exact: true,
      guards: [ShipOrderGuard],
      children: [{ path: '', exact: true, component: ShipOrderPage }],
    },
  ],
}
