import React, { FC } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { smMq } from 'common/constants'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { cls } from 'common/utils/utils'
import { IXtHeader } from './header.types'
import * as styles from './header.module.scss'
import { XtMenu } from './menu/menu'
import { SidebarService } from '../sidebar/sidebar.service'
import { XtNavbar } from './navbar/navbar'

export const XtHeader: FC<IXtHeader> = ({ navbarOptions, title = 'xTuple' }) => {
  const isTablet = useMediaQuery(smMq)

  const mobileSidebarToggle: VoidFunction = () => {
    SidebarService.toggle()
  }

  const shouldDisplayOptions = Array.isArray(navbarOptions) && navbarOptions.length

  return (
    <div className={styles.navbarContainer}>
      <div className={styles.mainBlock}>
        {isTablet && (
          <button className={styles.mobileMenu} type="button" onClick={mobileSidebarToggle} onKeyPress={mobileSidebarToggle} tabIndex={0}>
            <SvgIcon iconId={SvgIconIds.MOBILE_MENU} />
          </button>
        )}
        <div className={styles.headerContainer}>
          {shouldDisplayOptions ? (
            <XtNavbar options={navbarOptions ?? []} />
          ) : (
            <span className={cls(styles.brandText, 'xt-text-truncated')}>{title}</span>
          )}
        </div>
      </div>
      <div hidden={isTablet} className={styles.logout}>
        <XtMenu />
      </div>
    </div>
  )
}
