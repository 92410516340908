export type Nullable<T> = T | null

export interface IResponse<TData, TStatus = IResponseStatus> {
  data: TData
  status: TStatus
}

export interface IResponseStatus {
  code: string
  message: string
}
export interface IResponseStatusPagination extends IResponseStatus {
  totalrowcount: number
}

export interface IPaginationResponse<TData> {
  data: TData[] | object
  status: IResponseStatusPagination
}

export interface IPaginationData<TData> {
  data: TData[]
  total: number
}

export interface IPaginationParams {
  limit: number
  page: number
}

export interface IObjectWithId {
  id: string | number
}

export enum IssueMethod {
  Push = 'Push',
  Pull = 'Pull',
  Mixed = 'Mixed',
}

export class AdditionalDataCreationError extends Error {}

export enum XtMode {
  New = 'new',
  View = 'view',
  Edit = 'edit',
}

export interface IXtMode {
  mode: XtMode
}

export interface IConvertedMode {
  isViewMode: boolean
  isEditMode: boolean
  isNewMode: boolean
}
