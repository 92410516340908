import * as React from 'react'
import { forwardRef, KeyboardEventHandler } from 'react'
import { Key } from 'ts-key-enum'
import { CircularProgress } from '@material-ui/core'
import { SvgIcon } from '../../svg-icon/svg-icon'
import { cls } from '../../../common/utils/utils'
import * as styles from './xt-button.module.scss'
import { IconPosition, IXtButton, XtButtonVariant } from './xt-button.types'

export const XtButton = forwardRef<HTMLButtonElement, IXtButton>(
  (
    {
      icon,
      label,
      className,
      onClick,
      disabled = false,
      type = 'button',
      iconClass,
      labelClass,
      variant = XtButtonVariant.Primary,
      hidden = false,
      loading = false,
      iconPosition = IconPosition.Left,
    },
    ref
  ) => {
    const handleClick: () => void = () => {
      if (loading || disabled || typeof onClick !== 'function') {
        return
      }
      onClick()
    }

    const onKeyDown: KeyboardEventHandler = (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === Key.Enter) {
        handleClick()
      }
    }

    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        ref={ref}
        disabled={disabled}
        className={cls(styles.xtButton, className, styles[variant])}
        onClick={handleClick}
        onKeyDown={onKeyDown}
        hidden={hidden}
      >
        <div className={styles.xtButtonSpinner}>
          <CircularProgress color="inherit" hidden={!loading} size={styles.xtButtonSpinnerSize} />
        </div>
        {icon && iconPosition === IconPosition.Left && (
          <SvgIcon className={cls(styles.xtButtonIcon, iconClass, loading ? styles.xtButtonIconHidden : '')} iconId={icon} />
        )}
        <span className={cls(styles.xtButtonLabel, labelClass, loading ? styles.xtButtonLabelHidden : '')}>{label}</span>
        {icon && iconPosition === IconPosition.Right && (
          <SvgIcon className={cls(styles.xtButtonIcon, iconClass, loading ? styles.xtButtonIconHidden : '')} iconId={icon} />
        )}
      </button>
    )
  }
)
