import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from 'common/common.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { IHttpClient } from './http-client'

export enum UomTypes {
  Selling = 'Selling',
  Capacity = 'Capacity',
  MaterialIssue = 'MaterialIssue',
  AltCapacity = 'AltCapacity',
}

export interface IUomConversion {
  active: boolean
  uom_to: string
  to_value: number
  uom_from: string
  from_value: number
  fractional: boolean
  global?: boolean
  uom_types?: UomTypes[]
}

export interface IUom {
  name: string
  description: string
  use_for_item_weight: boolean
  uom_conversions: IUomConversion[]
  created: string
  lastupdated: string
}

export type InventoryUomOption = IUom & IXtAutocompleteOption

export type UomPayload = Omit<IUom, 'created' | 'lastupdated'>

export interface IUomService {
  getAll: (params: IPaginationParams, sortOptions?: ISortOption[]) => Promise<IPaginationData<IUom>>
  get(uom: string): Promise<IUom>
  create(data: UomPayload): Promise<void>
  delete(name: string): Promise<void>
  update(data: UomPayload): Promise<void>
  rename(rename: string, name: string): Promise<void>
}

export class UomService implements IUomService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getAll(paginationParams: IPaginationParams, sortOptions?: ISortOption[]): Promise<IPaginationData<IUom>> {
    const params = prepareRequestParams(paginationParams, undefined, sortOptions)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IUom>>('/uom', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async create(data: IUom): Promise<void> {
    await this.apiClient.post('/uom/create', { data })
  }

  public async delete(uom: string): Promise<void> {
    const body = { data: { name: uom } }
    await this.apiClient.post('/uom/delete', body)
  }

  public async update(data: IUom): Promise<void> {
    await this.apiClient.post('/uom/update', { data })
  }

  public async rename(rename: string, name: string): Promise<void> {
    const body = { data: { name } }
    await this.apiClient.post<IResponse<string>>(`/uom/${rename}/rename`, body)
  }

  public async get(uom: string): Promise<IUom> {
    const resp = await this.apiClient.get<IResponse<IUom>>(`/uom/${uom}`)
    if (!Object.keys(resp.data.data).length) {
      throw new Error(`UOM: ${uom} not found.`)
    }
    return resp.data.data
  }
}
