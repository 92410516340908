import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IFilter, IFilterFormState } from './filter.types'

export function clearFilterValues<
  TOption extends IXtAutocompleteOption,
  TFilters extends Array<IFilter<TOption>>,
  TFiltersState extends IFilterFormState
>(defaultValues: IFilterFormState, filters: TFilters): TFiltersState {
  const updatedValues: IFilterFormState = { ...defaultValues }

  filters.forEach(({ fieldName }) => {
    const hasValueProperty = Object.prototype.hasOwnProperty.call(updatedValues, fieldName)
    if (!hasValueProperty) {
      return
    }
    updatedValues[fieldName] = null
  })

  return updatedValues as TFiltersState
}
