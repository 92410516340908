import { parseServerDate } from 'common/utils/date.utils'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { defaultCurrency } from 'dictionary/dictionary.constants'
import { ProspectFormField, IProspectForm } from './prospect-details/prospect-details.types'
import { IProspect } from './prospects.types'

export const defineFormState = (data: IProspect | null, prospectName: string | null): IProspectForm => ({
  [ProspectFormField.Name]: prospectName ?? '',
  [ProspectFormField.Number]: data?.number ?? '',
  [ProspectFormField.Owner]: defineAutocompleteOption(data?.owner),
  [ProspectFormField.Active]: data?.active ?? true,
  [ProspectFormField.AssignedUser]: defineAutocompleteOption(data?.assigned_user),
  [ProspectFormField.AssignedDate]: parseServerDate(data?.assigned_date),
  [ProspectFormField.LastTouched]: parseServerDate(data?.last_touched),
  [ProspectFormField.Site]: defineAutocompleteOption(data?.site),
  [ProspectFormField.TaxZone]: defineAutocompleteOption(data?.tax_zone),
  [ProspectFormField.SalesRep]: defineAutocompleteOption(data?.sales_rep),
  [ProspectFormField.Source]: defineAutocompleteOption(data?.source),
  [ProspectFormField.Created]: parseServerDate(data?.created),
  [ProspectFormField.LastUpdated]: parseServerDate(data?.lastupdated),
  [ProspectFormField.Currency]: defineAutocompleteOption(data?.currency ?? defaultCurrency),
})
