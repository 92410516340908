import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { StageTaskOption, TaskStatusOption } from './tasks.types'

export function assigneesColumnConverter(items: unknown): string {
  if (!Array.isArray(items)) {
    return ''
  }
  return items.map(({ username }) => username).join(', ')
}

export function defineTaskOption(status: StageTaskOption): TaskStatusOption {
  return { ...defineAutocompleteOption(status), status }
}
