import * as React from 'react'
import { Control } from 'react-hook-form'
import { FormSelectField } from 'common/utils/form/form.components'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IFilterFormState, IFilter } from '../filter.types'

export function makeDropdownFilter<TOption extends IXtAutocompleteOption>(
  filter: IFilter<TOption>,
  control: Control<IFilterFormState>,
  multiple?: boolean
): JSX.Element {
  if (!filter.options || filter.options.length < 1) {
    throw new Error('No options are defined for a dropdown pagefilter')
  }

  return (
    <FormSelectField
      label={filter.label}
      key={`filter-${filter.fieldName}-controller`}
      control={control}
      name={filter.fieldName}
      options={filter.options}
      multiple={multiple}
      clearable
    />
  )
}
