import { useCallback, useEffect, useRef, useState } from 'react'
import { DocumentType } from 'documents/documents.types'
import { filterByField, updateByFieldValue } from 'common/utils/array.utils'
import { IPaginationParams } from 'common/common.types'
import { globalConstants } from 'common/constants'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { CommentItem } from '../comment/comment.types'
import { CommentCreateRequest } from '../comments.types'
import { IXtCommentsPaginationParams } from './comments'
import { useCommentsModule } from '../comments-module-hook'

type InitialData = {
  source: DocumentType
  sourceNumber: string | undefined
}

export interface ICommentsMeta {
  page: number
  limit: number
  total: number
}

export interface ICommentsHookState {
  comments: CommentItem[]
  loading: boolean
  isDirty: boolean
}

export interface ICommentsHook extends IXtCommentsPaginationParams {
  /**
   * The function returns true if a comment is saved successfully. Otherwise it returns false.
   * @param comment
   */
  saveComment(comment: CommentItem): Promise<boolean>
  updateComment(comment: CommentItem): Promise<void>
  resetComments(sourceNumber: string): void
  resetComments(comments: CommentItem[]): void
  loadMore(): Promise<void>
  canLoadMore(): boolean
  comments: CommentItem[]
  username: string
  isDirtyComments: boolean
}

function defineInitialCommentsHookState(initialComments: CommentItem[]): ICommentsHookState {
  return {
    comments: initialComments,
    loading: false,
    isDirty: false,
  }
}

function convertCommentItemToCreatePayload(commentItem: CommentItem, source: DocumentType, sourceNumber: string): CommentCreateRequest {
  const { comment, comment_type } = commentItem
  return {
    comment,
    comment_type,
    comment_source_number: sourceNumber,
    source,
  }
}

export function useComments(source: DocumentType, initialSourceNumber?: string, initialComments: CommentItem[] = []): ICommentsHook {
  const { ErrorHandler } = useCoreModule()
  const { AuthService } = useAuthModule()

  const initialDataRef = useRef<InitialData>({
    source,
    sourceNumber: initialSourceNumber,
  })

  const metaRef = useRef<ICommentsMeta>({ page: 1, limit: globalConstants.paginationLimit, total: 0 })
  const [state, setState] = useState<ICommentsHookState>(() => defineInitialCommentsHookState(initialComments))

  const { CommentsService } = useCommentsModule()

  const requestComments = useCallback<(paginationParams: IPaginationParams, sourceNumberValue: string) => Promise<void>>(
    async ({ limit, page }, sourceNumberValue) => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }))
        const { data, total } = await CommentsService.getForSource(initialDataRef.current.source, sourceNumberValue, { page, limit })
        metaRef.current = { total, page, limit }

        setState((prevState) => ({
          ...prevState,
          comments: filterByField([...prevState.comments, ...data], 'id'),
          loading: false,
          isDirty: prevState.isDirty,
        }))
      } catch (e) {
        ErrorHandler.handleError(e)
        setState((prevState) => ({ ...prevState, loading: false }))
      }
    },
    [CommentsService, ErrorHandler]
  )

  const saveComment = useCallback<(comment: CommentItem) => Promise<boolean>>(
    async (comment) => {
      const { sourceNumber, source: sourceValue } = initialDataRef.current

      if (!sourceNumber) {
        setState((prevState) => ({ ...prevState, comments: [comment, ...prevState.comments], isDirty: true }))
        return true
      }
      try {
        const payload = convertCommentItemToCreatePayload(comment, sourceValue, sourceNumber)
        const { id } = await CommentsService.create(payload)

        const createdComment = { ...comment, id }
        setState((prevState) => ({ ...prevState, comments: [createdComment, ...prevState.comments], isDirty: true }))

        return true
      } catch (e) {
        ErrorHandler.handleError(e)
        return false
      }
    },
    [CommentsService, ErrorHandler]
  )

  const updateComment = useCallback<(comment: CommentItem) => Promise<void>>(
    async (comment) => {
      try {
        const { sourceNumber, source: sourceValue } = initialDataRef.current

        if (!sourceNumber) {
          setState((prevState) => ({ ...prevState, comments: updateByFieldValue(prevState.comments, 'id', comment) }))
          return
        }
        await CommentsService.update({
          ...convertCommentItemToCreatePayload(comment, sourceValue, sourceNumber),
          id: comment.id,
        })
        setState((prevState) => ({ ...prevState, comments: updateByFieldValue(prevState.comments, 'id', comment) }))
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    },
    [CommentsService, ErrorHandler]
  )

  const canLoadMore = useCallback<() => boolean>(() => {
    const { limit, page, total } = metaRef.current
    return limit * page < total
  }, [])

  const loadMore = useCallback<() => Promise<void>>(async () => {
    const { limit, page } = metaRef.current
    if (state.loading || !canLoadMore() || !initialDataRef.current.sourceNumber) {
      return
    }

    await requestComments({ limit, page: page + 1 }, initialDataRef.current.sourceNumber)
  }, [state.loading, canLoadMore, requestComments])

  const resetComments = useCallback(
    (commentsData: string | CommentItem[]) => {
      if (typeof commentsData === 'string') {
        initialDataRef.current = { ...initialDataRef.current, sourceNumber: commentsData }
        void requestComments({ limit: metaRef.current.limit, page: 1 }, commentsData)
      } else {
        setState((prevState) => ({ ...prevState, comments: commentsData }))
      }
    },
    [requestComments]
  )

  useEffect(() => {
    if (initialDataRef.current.sourceNumber) {
      void requestComments({ limit: metaRef.current.limit, page: 1 }, initialDataRef.current.sourceNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    resetComments,
    saveComment,
    updateComment,
    loadMore,
    canLoadMore,
    comments: state.comments,
    username: AuthService.getUsername() ?? 'Unknown',
    isDirtyComments: state.isDirty,
  }
}
