import { Overwrite } from 'utility-types'
import { IComment } from 'comments/comments.types'
import { IAppliedCharacteristic, IAppliedCharacteristicNew } from '../characteristics/characteristics.types'
import { XtMode } from '../common/common.types'
import { IXtAutocompleteOption } from '../components/controls/xt-autocomplete/xt-autocomplete.types'

export interface IAssignee {
  username: string
  role: string | null
  assigned_date: string | null
}

export interface ITask {
  editable?: boolean
  id: number
  type: string
  number: string
  name: string
  description: string
  project_number: string
  project_description: string
  active: boolean
  status: StageTaskOption
  milestone: boolean
  priority: string
  owner: string
  assignees: IAssignee[] | null
  notes: string
  planned_start_date: string | null
  actual_start_date: string | null
  due_date: string | null
  completed_date: string | null
  percent_complete: number
  account_number: string
  account_name: string
  parent_type: string | null
  parent_number: string | null
  parent_task: string
  budget_hours: number
  actual_hours: number
  budget_expenses: number
  actual_expenses: number
  comments: IComment[] | null
  created: Date
  lastupdated: Date
  task_characteristics: IAppliedCharacteristic[] | null
  customer: string
}

export interface ITaskUpdatePayload
  extends Overwrite<
    Pick<
      ITask,
      | 'id'
      | 'owner'
      | 'completed_date'
      | 'due_date'
      | 'actual_start_date'
      | 'planned_start_date'
      | 'notes'
      | 'priority'
      | 'assignees'
      | 'name'
      | 'number'
      | 'status'
      | 'percent_complete'
      | 'comments'
      | 'task_characteristics'
      | 'parent_type'
      | 'parent_number'
    >,
    { task_characteristics: IAppliedCharacteristicNew[] }
  > {}

export interface ITaskCreatePayload extends Omit<ITaskUpdatePayload, 'id'> {}

export interface ITaskCreateResponse {
  task_id: number
}
export enum TaskParentType {
  Account = 'account',
  Prospect = 'prospect',
  Task = 'task',
  Opportunity = 'opportunity',
}

export enum TaskUpdateEventType {
  Create = 'TaskCreateEvent',
  Update = 'TaskUpdateEvent',
  Delete = 'TaskDeleteEvent',
  Complete = 'TaskCompleteEvent',
}

export interface ITaskUpdateEvent {
  type: TaskUpdateEventType
  taskId: number
}

export enum TaskListLabel {
  User = 'User',
  Owner = 'Owner',
  AssignedTo = 'Assigned To',
  Status = 'Task Status',
  Account = 'Account',
  Prospect = 'Prospect',
  MilestoneOnly = 'Milestone Tasks Only',
  PlannedStartBefore = 'Planned Start Date on or Before',
  PlannedStartAfter = 'Planned Start Date on or After',
  StartBefore = 'Start Date on or Before',
  StartAfter = 'Start Date on or After',
  DueBefore = 'Due Date on or Before',
  DueAfter = 'Due Date on or After',
  CompletedOnly = 'Show Completed Only',
  ShowCompleted = 'Show Completed',
  OnlyShowMyTasks = 'Only Show My Tasks',
}

export enum TaskListFilter {
  TaskId = 'taskid',
  Number = 'number',
  Name = 'name',
  User = 'user',
  Owner = 'owner',
  AssignedTo = 'assignedto',
  Status = 'status',
  Account = 'account',
  AccountGroup = 'accountgroup',
  Prospect = 'prospect',
  MilestoneOnly = 'milestoneonly',
  PlannedStartBefore = 'plannedstartbefore',
  PlannedStartAfter = 'plannedstartafter',
  StartBefore = 'startbefore',
  StartAfter = 'startafter',
  DueBefore = 'duebefore',
  DueAfter = 'dueafter',
  CompletedOnly = 'completedonly',
  ShowCompleted = 'showcompleted',
  LastUpdated = 'lastupdated',
  DueDate = 'duedate',
  Incident = 'incident',
  Opportunity = 'opportunity',
  Project = 'project',
  ShowDetail = 'showDetail',
}

export interface ITaskListFilters {
  [TaskListFilter.TaskId]?: string
  [TaskListFilter.Number]?: string
  [TaskListFilter.Name]?: string
  [TaskListFilter.LastUpdated]?: string
  [TaskListFilter.AssignedTo]?: string
  [TaskListFilter.Owner]?: string
  [TaskListFilter.User]?: string
  [TaskListFilter.ShowCompleted]?: boolean
  [TaskListFilter.MilestoneOnly]?: boolean
  [TaskListFilter.Status]?: string
  [TaskListFilter.DueDate]?: string
  [TaskListFilter.DueBefore]?: string
  [TaskListFilter.DueAfter]?: string
  [TaskListFilter.PlannedStartAfter]?: string
  [TaskListFilter.PlannedStartBefore]?: string
  [TaskListFilter.StartBefore]?: string
  [TaskListFilter.StartAfter]?: string
  [TaskListFilter.Account]?: string
  [TaskListFilter.AccountGroup]?: string
  [TaskListFilter.Incident]?: string
  [TaskListFilter.Opportunity]?: string
  [TaskListFilter.Prospect]?: string
  [TaskListFilter.Project]?: string
  [TaskListFilter.ShowDetail]?: boolean
  [TaskListFilter.CompletedOnly]?: boolean
}

export type CalendarTask = Overwrite<Omit<ITask, 'comments' | 'task_characteristics' | 'customer'>, { due_date: string }>

export interface ICalendarTaskByDate {
  tasks: CalendarTask[] | null
  due_date: string
  count: number
}

export enum TasksCalendarPeriod {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Range = 'range',
}

export enum TaskListFilterLabel {
  AllUsers = 'Show Tasks for All Users',
  Status = 'Task Status',
  User = 'User',
}

export enum TaskListFilterField {
  Period = 'period',
  FromDate = 'fromdate',
  ToDate = 'todate',
  AllUsers = 'allusers',
  Status = 'status',
  User = 'user',
}

export interface ITaskCalendarFilters {
  [TaskListFilterField.Period]: TasksCalendarPeriod
  [TaskListFilterField.FromDate]: string
  [TaskListFilterField.ToDate]?: string
  [TaskListFilterField.AllUsers]: boolean
  [TaskListFilterField.Status]?: string
  [TaskListFilterField.User]?: string
}

export interface ITaskDetailsDialogState {
  id: number | null
  mode: XtMode
  open: boolean
}

export enum StageTaskOption {
  New = 'New',
  Pending = 'Pending',
  InProcess = 'In-Process',
  Deferred = 'Deferred',
  Completed = 'Completed',
}

export type TaskStatusOption = IXtAutocompleteOption & { status: StageTaskOption }
