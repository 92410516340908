import { costingRoutePath } from './costing.constants'
import { CostingModule, CostingModuleDefinition } from './costing.module'
import { IRoute } from '../common/routing/routing.types'
import { bomRoutePath, bomRoutes } from './bom/bom.constants'
import { itemCostsRoutePath, itemCostsRoutes } from './item-costs/item-costs.constants'
import { quantityOnHandPath } from './quantity-on-hand/quantity-on-hand.constants'
import { CostingGuard } from './costing.guard'
import { BomGuard } from './bom/bom.guard'
import { QuantityOnHandGuard } from './quantity-on-hand/quantity-on-hand.guard'
import { InventoryHistoryGuard, ItemCostsGuard } from './item-costs/item-costs.guard'

export function costingRouteFactory({
  SummarizedList,
  SingleLevelList,
  HistoryList,
  SummaryListPage,
  ByClassCodeList,
  QuantityOnHandList,
  InventoryHistoryByLocationList,
  IndentedList,
  InventoryHistoryByLotSerialList,
  InventoryHistoryList,
}: CostingModuleDefinition): IRoute[] {
  return [
    { path: '', exact: true, redirectTo: `${costingRoutePath}/${bomRoutePath}/${bomRoutes.indented}` },

    { component: IndentedList, path: `${bomRoutePath}/${bomRoutes.indented}`, name: 'Indented', guards: [BomGuard] },
    { component: SingleLevelList, path: `${bomRoutePath}/${bomRoutes.singleLevel}`, name: 'Single Level', guards: [BomGuard] },
    { component: SummarizedList, path: `${bomRoutePath}/${bomRoutes.summarized}`, name: 'Summarized', guards: [BomGuard] },

    {
      component: InventoryHistoryList,
      path: `${itemCostsRoutePath}/${itemCostsRoutes.inventoryHistory}`,
      name: 'Inventory History',
      guards: [InventoryHistoryGuard],
    },
    {
      component: InventoryHistoryByLocationList,
      path: `${itemCostsRoutePath}/${itemCostsRoutes.inventoryHistoryByLocation}`,
      name: 'History by Location',
      guards: [InventoryHistoryGuard],
    },
    {
      component: InventoryHistoryByLotSerialList,
      path: `${itemCostsRoutePath}/${itemCostsRoutes.inventoryHistoryByLotSerial}`,
      name: 'History by Lot/Serial',
      guards: [InventoryHistoryGuard],
    },

    {
      component: ByClassCodeList,
      path: `${itemCostsRoutePath}/${itemCostsRoutes.byClassCode}`,
      name: 'By Class Code',
      guards: [ItemCostsGuard],
    },
    { component: HistoryList, path: `${itemCostsRoutePath}/${itemCostsRoutes.history}`, name: 'History', guards: [ItemCostsGuard] },
    { component: SummaryListPage, path: `${itemCostsRoutePath}/${itemCostsRoutes.summary}`, name: 'Summary', guards: [ItemCostsGuard] },

    { component: QuantityOnHandList, path: `${quantityOnHandPath}`, name: 'Quantity on Hand', guards: [QuantityOnHandGuard] },
  ]
}

export const costingRoute = {
  name: 'Costing',
  path: costingRoutePath,
  module: CostingModule,
  guards: [CostingGuard],
  childrenRoutesFactory: costingRouteFactory,
}
