import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { numberConverter } from 'common/utils/utils'
import { dateConverter } from 'common/utils/date.utils'
import { IObjectWithId } from 'common/common.types'
import { IOpportunity } from '../opportunities.types'
import { OpportunitiesFilter, OpportunitiesFiltersPanel } from '../services/opportunities-services.types'

export interface IOpportunitiesTableItem extends IObjectWithId, IOpportunity {}

export enum OpportunityAction {
  Delete = 'Delete',
  Edit = 'edit',
  View = 'view',
  Deactivate = 'Deactivate',
  CreateTask = 'Create Task ',
  CreateQuote = 'Create Quote',
}

export const OpportunitiesListActionsEditMode: IXtTableCellAction[] = [
  {
    name: OpportunityAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: OpportunityAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: OpportunityAction.Delete,
    label: OpportunityAction.Delete,
    icon: SvgIconIds.DELETE,
  },
  {
    name: OpportunityAction.Deactivate,
    label: OpportunityAction.Deactivate,
    icon: SvgIconIds.OUTLINE_CLOSE_CIRLCE,
  },
  {
    name: OpportunityAction.CreateTask,
    label: OpportunityAction.CreateTask,
    icon: SvgIconIds.TASKS,
  },
  {
    name: OpportunityAction.CreateQuote,
    label: OpportunityAction.CreateQuote,
    icon: SvgIconIds.QUOTES,
  },
]

export const OpportunitiesColumns: IXtTableColumn<IOpportunitiesTableItem>[] = [
  {
    id: 'name',
    field: 'name',
    headerName: 'Name',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'account',
    field: 'account',
    headerName: 'CRM Account',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'project',
    field: 'project',
    headerName: 'Project',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'owner',
    field: 'owner',
    headerName: 'Owner',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'stage',
    field: 'stage',
    headerName: 'Stage',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'priority',
    field: 'priority',
    headerName: 'Priority',
    flex: '1 0 140px',
    width: 140,
  },
  {
    id: 'source',
    field: 'source',
    headerName: 'Source',
    flex: '1 0 260px',
    width: 260,
  },

  {
    id: 'type',
    field: 'type',
    headerName: 'Type',
    flex: '1 0 260px',
    width: 260,
  },
  {
    id: 'probability_percent',
    field: 'probability_percent',
    headerName: 'Probability %',
    flex: '1 0 180px',
    width: 180,
  },
  {
    id: 'amount',
    field: 'amount',
    headerName: 'Amount',
    flex: '1 0 260px',
    width: 260,
    converter: numberConverter,
  },
  {
    id: 'weighted_value',
    field: 'weighted_value',
    headerName: 'Weighted Value',
    flex: '1 0 260px',
    width: 260,
  },
  {
    id: 'target_date',
    field: 'target_date',
    headerName: 'Target Date',
    flex: '1 0 140px',
    width: 140,
    converter: dateConverter,
  },
]

export const defaultFilterValues: OpportunitiesFiltersPanel = {
  [OpportunitiesFilter.ShowInactive]: false,
  [OpportunitiesFilter.User]: null,
  [OpportunitiesFilter.AssignedTo]: null,
  [OpportunitiesFilter.TargetDateOnOrAfter]: null,
  [OpportunitiesFilter.TargetDateOnOrBefore]: null,
  [OpportunitiesFilter.Project]: null,
  [OpportunitiesFilter.NamePattern]: '',
  [OpportunitiesFilter.Account]: null,
  [OpportunitiesFilter.Type]: null,
  [OpportunitiesFilter.TypePattern]: '',
  [OpportunitiesFilter.Source]: null,
  [OpportunitiesFilter.SourcePattern]: '',
  [OpportunitiesFilter.Stage]: null,
  [OpportunitiesFilter.StagePattern]: '',
  [OpportunitiesFilter.CreateDateOnOrAfter]: null,
  [OpportunitiesFilter.CreateDateOnOrBefore]: null,
}

export const defaultTaskDetailsDialogState = { parentNumber: null, open: false }
