import { Observable, Subject } from 'rxjs'
import {
  DocumentIdField,
  DocumentType,
  DocumentUpdateEventType,
  IAttachedDocument,
  IAttachedDocumentWithFile,
  ICreateAttachedFileDocumentRequest,
  ICRMAccountDocument,
  ICRMAccountDocumentFilters,
  IDetachDocumentRequest,
  IDocnumber,
  IDocument,
  IDocumentAccountingPeriod,
  IDocumentContract,
  IDocumentFilters,
  IDocumentListFilters,
  IDocumentLocation,
  IDocumentOrder,
  IDocumentsService,
  IDocumentUpdateEvent,
  IUpdateAttachedFileDocumentRequest,
} from './documents.types'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from '../common/common.types'
import { prepareRequestParams } from '../common/utils/request.utils'
import { characteristicsToPayload } from '../characteristics/characteristics.utils'
import { IHttpClient } from '../core/services/http-client'
import { ISortOption } from '../components/table/table-head/table-head.types'

export class DocumentsService implements IDocumentsService {
  private documentUpdateEventSubject: Subject<IDocumentUpdateEvent> = new Subject<IDocumentUpdateEvent>()
  public documentUpdate$: Observable<IDocumentUpdateEvent> = this.documentUpdateEventSubject.asObservable()

  constructor(private readonly apiClient: IHttpClient) {}

  public async getDocuments(
    docType: DocumentType,
    paginationParams?: IPaginationParams,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<IDocument>> {
    const params = prepareRequestParams(paginationParams, filters)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IDocument>>(`/documentlist/${docType}`, { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getAttachedForSource(
    source: DocumentType,
    sourceNumber: string,
    paginationParams: IPaginationParams,
    filters?: IDocumentFilters
  ): Promise<IPaginationData<IAttachedDocument>> {
    const params = prepareRequestParams(paginationParams, filters)
    const response = await this.apiClient.get<IPaginationResponse<IAttachedDocument>>(`/documents/${source}/${sourceNumber}`, { params })

    const {
      data: { data, status },
    } = response

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async detach(data: IDetachDocumentRequest): Promise<void> {
    await this.apiClient.post<IResponse<void>>('/documents/detach', { data })
    this.documentUpdateEventSubject.next({
      type: DocumentUpdateEventType.Delete,
      documentId: data.assignments.map(({ id }) => id.toString()),
    })
  }

  public async createFile(
    data: ICreateAttachedFileDocumentRequest,
    documentIdField: DocumentIdField = DocumentIdField.DocumentNumber
  ): Promise<void> {
    const formData = new FormData()
    formData.append('file', data.file, data.file_name ?? data.file.name)
    formData.append('document_type', data.document_type)
    formData.append(documentIdField, data.document_number)
    formData.append('document_notes', data.document_notes)
    if (data.document_characteristics) {
      formData.append('document_characteristics', JSON.stringify(data.document_characteristics))
    }
    await this.createDocument(formData)
    this.documentUpdateEventSubject.next({ type: DocumentUpdateEventType.Create, documentId: data.document_number })
  }

  public async createFileForSource(
    document: IAttachedDocumentWithFile,
    sourceType: DocumentType,
    sourceNumber: string,
    documentIdField?: DocumentIdField
  ): Promise<void> {
    await this.createFile(
      {
        file: document.file,
        document_type: sourceType,
        document_number: sourceNumber,
        document_notes: document.notes,
        file_name: document.name,
        document_characteristics: characteristicsToPayload(document.document_characteristics ?? []),
      },
      documentIdField
    )
  }

  public async createMultipleFiles(
    documents: IAttachedDocumentWithFile[],
    sourceType: DocumentType,
    sourceNumber: string,
    documentIdField: DocumentIdField = DocumentIdField.DocumentNumber
  ): Promise<void> {
    const formData = new FormData()
    formData.append('document_type', sourceType)
    formData.append(documentIdField, sourceNumber)
    for (let i = 0; i < documents.length; i++) {
      formData.append('file', documents[i].file)
    }
    await this.createDocument(formData)
    this.documentUpdateEventSubject.next({ type: DocumentUpdateEventType.Create, documentId: sourceNumber })
  }

  public async createFilesForSource(
    documents: IAttachedDocumentWithFile[],
    sourceType: DocumentType,
    sourceNumber: string,
    documentIdField?: DocumentIdField
  ): Promise<void> {
    await Promise.all(
      documents
        .filter((document) => document.file)
        .map((document) => this.createFileForSource(document, sourceType, sourceNumber, documentIdField))
    )
  }

  public async loadFileData(file_link: string): Promise<Blob> {
    const response = await this.apiClient.get<ArrayBuffer>(file_link, { responseType: 'arraybuffer' })
    return new Blob([response.data], {
      type: response.headers['content-type'],
    })
  }

  public async update(assignments: IUpdateAttachedFileDocumentRequest[]): Promise<void> {
    const body = { data: { assignments } }
    await this.apiClient.post<IPaginationResponse<IAttachedDocument[]>>('/documents/update', body)
    this.documentUpdateEventSubject.next({
      type: DocumentUpdateEventType.Update,
      documentId: assignments.map(({ id }) => id.toString()),
    })
  }

  public async getDocnumber(source: DocumentType): Promise<IDocnumber> {
    const {
      data: { data },
    } = await this.apiClient.get<IResponse<IDocnumber>>(`/docnumber/${source}`)

    if (!Object.keys(data).length) {
      throw new Error(`Document number with type: ${source} is not found.`)
    }

    return data
  }

  public async getCurrentDocnumber(source: DocumentType): Promise<IDocnumber> {
    const {
      data: { data },
    } = await this.apiClient.get<IResponse<IDocnumber>>(`/docnumber/${source}/current`)

    if (!Object.keys(data).length) {
      throw new Error(`Document number with type: ${source} is not found.`)
    }

    return data
  }

  public async setDocnumber(source: DocumentType, number: number): Promise<void> {
    const body = { data: { source, number } }
    await this.apiClient.post<IResponse<IDocnumber>>('/docnumber/set', body)
  }

  public async getCRMAccounts(
    paginationParams?: IPaginationParams,
    filters?: ICRMAccountDocumentFilters
  ): Promise<IPaginationData<ICRMAccountDocument>> {
    const params = prepareRequestParams(paginationParams, filters)

    const response = await this.apiClient.get<IPaginationResponse<ICRMAccountDocument>>('/documentlist/CRMA', {
      params,
    })
    const {
      data: { data, status },
    } = response
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getLocations(
    paginationParams?: IPaginationParams,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<IDocumentLocation>> {
    const params = prepareRequestParams(paginationParams, filters)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IDocumentLocation>>(`/documentlist/${DocumentType.List}`, {
      params,
    })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getContracts(
    paginationParams?: IPaginationParams,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<IDocumentContract>> {
    const params = prepareRequestParams(paginationParams, filters)

    const response = await this.apiClient.get<IPaginationResponse<IDocumentContract>>(`/documentlist/${DocumentType.Contract}`, {
      params,
    })

    const {
      data: { data, status },
    } = response

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getOrders(paginationParams?: IPaginationParams, filters?: IDocumentListFilters): Promise<IPaginationData<IDocumentOrder>> {
    const params = prepareRequestParams(paginationParams, filters)

    const response = await this.apiClient.get<IPaginationResponse<IDocumentOrder>>(`/documentlist/${DocumentType.Orders}`, {
      params,
    })

    const {
      data: { data, status },
    } = response

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getAccountingPeriods(
    paginationParams?: IPaginationParams,
    filters?: IDocumentListFilters,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IDocumentAccountingPeriod>> {
    const params = prepareRequestParams(paginationParams, filters, sortOptions)

    const response = await this.apiClient.get<IPaginationResponse<IDocumentAccountingPeriod>>(`/documentlist/${DocumentType.Period}`, {
      params,
    })

    const {
      data: { data, status },
    } = response

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getLotSerial(paginationParams?: IPaginationParams, filters?: IDocumentListFilters): Promise<IPaginationData<IDocument>> {
    const params = prepareRequestParams(paginationParams, filters)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IDocument>>(`/documentlist/${DocumentType.LotSerial}`, {
      params,
    })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  private async createDocument(formData: FormData): Promise<void> {
    const config = { headers: { 'content-type': 'multipart/controls-data' } }
    await this.apiClient.post<IResponse<void>>('/file/create', formData, config)
  }
}
