import { ContactsModule } from 'contacts/contacts.module'
import { ShipmentsModule } from 'shipments/shipments.module'
import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { CharacteristicsModule } from 'characteristics/characteristics.module'
import { CommentsModule } from 'comments/comments.module'
import { DocumentsModule } from 'documents/documents.module'
import { CoreModule } from 'core/core.module'
import { ProductsModule } from 'products/products.module'
import { ISalesOrdersService } from 'sales-shared/sales-orders.service'
import { salesOrdersServiceToken } from 'sales-shared/sales-shared.constants'
import { SalesOrdersModule } from 'sales-orders/sales-orders.module'
import { IAccountsService } from 'companies/accounts/services/accounts-services.types'
import { accountsServiceToken } from 'companies/accounts/accounts.constants'
import {
  inventoryAdjustmentModule,
  inventoryAdjustmentServiceToken,
  inventoryAdjustmentUtilsServiceToken,
} from './inventory-adjustments.constants'
import { IInventoryAdjustmentUtilsService } from './inventory-adjustments-utils.service'
import { IInventoryAdjustmentService } from './inventory-adjustments.service'
import { AccountsServicesModule } from '../companies/accounts/services/accounts-services.module'
import { IItemsService } from '../products/items/items.service'
import { itemsServiceToken } from '../products/products.constants'
import { IDocumentsService } from '../documents/documents.types'
import { documentsServiceToken } from '../documents/documents.constants'
import { IRegistrationService } from './inventory-adjustment-details/lot-serial/lot-serial-dialog/registration/registration.service'
import {
  registrationServiceToken,
  registrationUtilsServiceToken,
} from './inventory-adjustment-details/lot-serial/lot-serial-dialog/registration/registration.constants'
import { IRegistrationUtilsService } from './inventory-adjustment-details/lot-serial/lot-serial-dialog/registration/registration-utils.service'

export type InventoryAdjustmentModuleDefinition = typeof import('./inventory-adjustments.exports')

export interface IInventoryAdjustmentModuleServices {
  InventoryAdjustmentUtilsService: IInventoryAdjustmentUtilsService
  InventoryAdjustmentService: IInventoryAdjustmentService
  RegistrationService: IRegistrationService
  RegistrationUtilsService: IRegistrationUtilsService
}

export const InventoryAdjustmentModule: ILazyModule<InventoryAdjustmentModuleDefinition> = {
  name: inventoryAdjustmentModule,
  resolver: () => import('./inventory-adjustments.exports'),
  initializer: ({ InventoryAdjustmentService, InventoryAdjustmentUtilsService, RegistrationService, RegistrationUtilsService }) => {
    Injector.register<IInventoryAdjustmentService>(
      inventoryAdjustmentServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)

        return new InventoryAdjustmentService(httpClient, documentsService)
      },
      Scope.Singleton
    )

    Injector.register<IInventoryAdjustmentUtilsService>(
      inventoryAdjustmentUtilsServiceToken,
      (injector) => {
        const itemsService = injector.resolve<IItemsService>(itemsServiceToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)

        return new InventoryAdjustmentUtilsService(itemsService, documentsService)
      },
      Scope.Singleton
    )

    Injector.register<IRegistrationService>(
      registrationServiceToken,
      (injector) => new RegistrationService(injector.resolve<IHttpClient>(httpClientToken)),
      Scope.Singleton
    )

    Injector.register<IRegistrationUtilsService>(
      registrationUtilsServiceToken,
      (injector) => {
        const registrationService = injector.resolve<IRegistrationService>(registrationServiceToken)
        const accountsService = injector.resolve<IAccountsService>(accountsServiceToken)
        const salesOrderService = injector.resolve<ISalesOrdersService>(salesOrdersServiceToken)

        return new RegistrationUtilsService(registrationService, accountsService, salesOrderService)
      },
      Scope.Singleton
    )
  },
  dependencies: [
    CoreModule,
    ProductsModule,
    ContactsModule,
    ShipmentsModule,
    SalesOrdersModule,
    AccountsServicesModule,
    CharacteristicsModule,
    DocumentsModule,
    CommentsModule,
  ],
}
