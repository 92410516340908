import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Observable, Subject } from 'rxjs'
import { useMediaQuery } from '@material-ui/core'
import { cls } from 'common/utils/utils'
import { XtMode } from 'common/common.types'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { UserPermission } from 'auth/auth.types'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { xsMq } from 'common/constants'
import { useTasksModule } from 'tasks/tasks-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { defaultTaskDetailsDialogState } from 'tasks/tasks.constants'
import { ITaskDetailsDialogState } from 'tasks/tasks.types'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { useKanbanModule } from 'kanban/kanban-module-hook'
import { XtPageFilter } from 'components/pagefilter/pagefilter'
import { usePageFilter } from 'components/pagefilter/pagefilter.utils'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import * as styles from './tasks-board.module.scss'
import { defineDefaultFilters, resolveFilters } from './tasks-board.utils'
import { TaskKanbanColumnHeader } from './task-kanban-column-header/task-kanban-column-header'
import { TaskKanbanCard } from './task-kanban-card/task-kanban-card'
import { IKanbanTaskCard, IKanbanTaskColumn } from './tasks-board.types'
import { itemsPerRequest } from './tasks-board.constants'
import { IKanbanTasksFilterPanel, KanbanTasksFilter, KanbanTasksLabel } from '../tasks-kanban.types'

const footerTemplate: () => React.ReactElement = () => <div /> // TODO: fix me in future

export const TasksBoard: FC = () => {
  const { TaskDetails, TasksService, TasksKanbanUtilsService } = useTasksModule()
  const { DocumentsUtilsService } = useDocumentsModule()
  const { ErrorHandler } = useCoreModule()
  const { PermissionsService, AuthService } = useAuthModule()
  const { XtKanbanBoard, useKanbanHook } = useKanbanModule()

  const [taskDetailsDialog, setTaskDetailsDialog] = useState<ITaskDetailsDialogState>(defaultTaskDetailsDialogState)
  const { columns, initialiseBoard, setLoading, loading, findCard } = useKanbanHook<
    IKanbanTaskColumn,
    IKanbanTaskCard,
    IKanbanTasksFilterPanel
  >({
    fetchItems: TasksKanbanUtilsService.fetchTaskCards,
    cardsLimitPerRequest: itemsPerRequest,
  })

  const canManageTasks = PermissionsService.hasSomePermission([
    UserPermission.MaintainAllTaskItems,
    UserPermission.MaintainPersonalTaskItems,
  ])
  const username = AuthService.getUsername()
  const isMobile = useMediaQuery(xsMq)

  const defaultFilters = defineDefaultFilters(username)
  const filtersStateRef = useRef<IKanbanTasksFilterPanel>(defaultFilters)
  const resetFiltersSubject = useRef<Subject<IKanbanTasksFilterPanel>>(new Subject())
  const resetFilters$ = useRef<Observable<IKanbanTasksFilterPanel>>(resetFiltersSubject.current.asObservable())

  const filterBoard = useCallback<(boardFilters: IKanbanTasksFilterPanel) => Promise<void>>(
    async (boardFilters) => {
      try {
        setLoading(true)
        filtersStateRef.current = boardFilters
        const cols = await TasksKanbanUtilsService.requestColumns(boardFilters)
        initialiseBoard(cols)
      } catch (error) {
        ErrorHandler.handleError(error as Error)
      } finally {
        setLoading(false)
      }
    },
    [ErrorHandler, TasksKanbanUtilsService, initialiseBoard, setLoading]
  )

  const tasksListFilters = usePageFilter<IKanbanTasksFilterPanel>(PageFilterMapping.DashTasks)

  const handleCardClick = useCallback<(columnId: string, cardId: number) => void>(
    (columnId, cardId) => {
      try {
        const card = findCard(cardId, columnId)
        const mode = card.editable ? XtMode.Edit : XtMode.View
        setTaskDetailsDialog({ id: cardId, mode, open: true })
      } catch (error) {
        ErrorHandler.handleError(error as Error)
      }
    },
    [ErrorHandler, findCard]
  )

  const onShowCompletedChange = useCallback<(checked: boolean) => void>(
    (checked) => {
      const newFiltersState = { ...filtersStateRef.current, [KanbanTasksFilter.ShowCompleted]: checked }
      resetFiltersSubject.current.next(newFiltersState)
      void filterBoard(newFiltersState)
      void tasksListFilters.handleLastUsedFilter(newFiltersState)
    },
    [filterBoard, tasksListFilters]
  )

  const onShowCompletedOnlyChange = useCallback<(checked: boolean) => void>(
    (checked) => {
      const newFiltersState = { ...filtersStateRef.current, [KanbanTasksFilter.CompletedOnly]: checked }
      resetFiltersSubject.current.next(newFiltersState)
      void filterBoard(newFiltersState)
      void tasksListFilters.handleLastUsedFilter(newFiltersState)
    },
    [filterBoard, tasksListFilters]
  )

  const updateTaskPriority = useCallback<(taskId: number, priority: string) => Promise<void>>(
    async (taskId, priority) => {
      try {
        setLoading(true)
        await TasksService.updatePriority(taskId, priority)
      } catch (error) {
        ErrorHandler.handleError(error as Error)
      } finally {
        // board data should be reset in case of error or successful update
        await filterBoard(filtersStateRef.current)
        setLoading(false)
      }
    },
    [setLoading, TasksService, ErrorHandler, filterBoard]
  )

  const handleDragEnd = useCallback<(sourceColumnId: string, targetColumnId: string, position: number, card: IKanbanTaskCard) => boolean>(
    (sourceColumnId, targetColumnId, _position, card) => {
      if (sourceColumnId === targetColumnId) {
        return false
      }

      void updateTaskPriority(card.id, targetColumnId)
      return true
    },
    [updateTaskPriority]
  )

  const addNewTask = useCallback(() => setTaskDetailsDialog({ open: true, id: null, mode: XtMode.New }), [])

  const closeTaskDetailsDialog = useCallback(() => setTaskDetailsDialog(defaultTaskDetailsDialogState), [])

  const onSubmitTaskDetailsDialog = useCallback<VoidFunction>(async () => {
    closeTaskDetailsDialog()
    await filterBoard(filtersStateRef.current)
  }, [closeTaskDetailsDialog, filterBoard])

  // Board initialisation
  useEffect(() => {
    void filterBoard(filtersStateRef.current)
  }, [filterBoard])

  return (
    <div className={cls('xt-content', styles.tasksBoard)}>
      <XtDialog className="xt-modal-details-content" open={taskDetailsDialog.open} animation={XtDialogAnimation.SlideAnimation}>
        <TaskDetails
          onSubmit={onSubmitTaskDetailsDialog}
          mode={taskDetailsDialog.mode}
          id={taskDetailsDialog.id}
          onCancel={closeTaskDetailsDialog}
        />
      </XtDialog>
      <div className={styles.tasksBoardHeader}>
        <XtPageFilter
          state={tasksListFilters}
          defaultFilterValues={defaultFilters}
          resolveFilters={() => resolveFilters(DocumentsUtilsService)}
          filter={filterBoard}
          tableFilters={filtersStateRef.current}
          resetFilters={resetFilters$.current}
        />
        {isMobile && (
          <XtResponsiveButton
            onClick={addNewTask}
            disabled={!canManageTasks}
            className={styles.tasksBoardAddNewButton}
            label="New Task"
            icon={SvgIconIds.ADD_CIRCLE}
          />
        )}
        <div className={styles.tasksBoardCheckboxesFilter}>
          <XtCheckbox
            value={Boolean(filtersStateRef.current[KanbanTasksFilter.ShowCompleted])}
            label={KanbanTasksLabel.ShowCompleted}
            onChange={onShowCompletedChange}
            className={styles.tasksBoardCheckboxFilter}
            disabled={loading}
          />
          <XtCheckbox
            value={Boolean(filtersStateRef.current[KanbanTasksFilter.CompletedOnly])}
            label={KanbanTasksLabel.CompletedOnly}
            onChange={onShowCompletedOnlyChange}
            className={styles.tasksBoardCheckboxFilter}
            disabled={loading}
          />
        </div>
        {!isMobile && (
          <XtResponsiveButton
            onClick={addNewTask}
            disabled={!canManageTasks}
            className={styles.tasksBoardAddNewButton}
            label="New Task"
            icon={SvgIconIds.ADD_CIRCLE}
          />
        )}
      </div>
      <XtKanbanBoard
        draggable
        loading={loading}
        columns={columns}
        id="task_board"
        title="Task board"
        onCardClick={handleCardClick}
        onDragEnd={handleDragEnd}
        laneDraggable={false}
        className={cls('xt-tasks-kanban-board', 'xt-tasks-kanban-board-mobile')}
        cardTemplate={TaskKanbanCard}
        headerTemplate={TaskKanbanColumnHeader}
        footerTemplate={footerTemplate} // TODO: fix me in future
      />
    </div>
  )
}
