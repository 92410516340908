import React from 'react'
import { CircularProgress } from '@material-ui/core'

const loadingSpinnerSize = 15

interface ILoader {
  text?: string
  className?: string
  size?: number
}

export function LoadingSpinner({ text = 'Loading...', className, size = loadingSpinnerSize }: ILoader): JSX.Element {
  return (
    <div className={className}>
      <CircularProgress size={size} /> {text}
    </div>
  )
}
