import { IRoute } from '../common/routing/routing.types'
import { XtMode } from '../common/common.types'
import { buildPageComponent } from '../common/routing/routing.utils'
import { TaskDetailsGuard } from './task-details/task-details.guard'
import { TasksModuleDefinition } from './tasks.module'
import { TasksBoard } from './tasks-kanban/tasks-board/tasks-board'
import { TasksGuard } from './tasks.guard'
import { TasksCalendar } from './tasks-calendar/tasks-calendar'
import { tasksRoutes } from './tasks.constants'

export function tasksRouteFactory({ TaskListPage, TaskDetailsPage, TaskDetailsOpportunityNewPage }: TasksModuleDefinition): IRoute[] {
  return [
    {
      path: '',
      exact: true,
      component: TaskListPage,
    },
    {
      path: `/:id/${XtMode.Edit}`,
      component: (props) => buildPageComponent(props, XtMode.Edit, TaskDetailsPage),
      guards: [TaskDetailsGuard],
    },
    {
      path: `/:id/${XtMode.View}`,
      component: (props) => buildPageComponent(props, XtMode.View, TaskDetailsPage),
    },
    {
      path: `/${XtMode.New}/opportunity/:parentNumber`,
      component: (props) => buildPageComponent(props, XtMode.New, TaskDetailsOpportunityNewPage),
      guards: [TaskDetailsGuard],
    },
    {
      path: `/${XtMode.New}`,
      component: (props) => buildPageComponent(props, XtMode.New, TaskDetailsPage),
      guards: [TaskDetailsGuard],
    },
    {
      path: tasksRoutes.kanban,
      exact: true,
      component: TasksBoard,
      guards: [TasksGuard],
    },
    {
      path: tasksRoutes.calendar,
      exact: true,
      component: TasksCalendar,
      guards: [TasksGuard],
    },
  ]
}
