import { Overwrite } from 'utility-types'
import { IUser } from 'users/users.types'
import { IXtAutocompleteOption } from '../components/controls/xt-autocomplete/xt-autocomplete.types'

export enum ProfileDetailsFormField {
  Username = 'username',
  ProperName = 'proper_name',
  Status = 'status',
  Initials = 'initials',
  Active = 'active',
  Locale = 'locale',
  PreferredCountry = 'preferred_country',
  RowsPerPage = 'rows_per_page',
}

export enum ProfileDetailsLabel {
  Username = 'Email Address',
  ProperName = 'Proper Name',
  Status = 'Status',
  Initials = 'Initials',
  Active = 'Active',
  Locale = 'Locale',
  PreferredCountry = 'Preferred Country',
  RowsPerPage = 'Rows Pep Page',
}

export enum PasswordChangeFormField {
  Password = 'password',
  Verify = 'verify',
}
export enum PasswordChangeLabel {
  Password = 'Password',
  Verify = 'Verify',
}

export type ProfileDetailsForm = Overwrite<
  Pick<
    IUser,
    | ProfileDetailsFormField.Username
    | ProfileDetailsFormField.ProperName
    | ProfileDetailsFormField.Initials
    | ProfileDetailsFormField.Locale
  >,
  {
    [ProfileDetailsFormField.Locale]: IXtAutocompleteOption | null
  }
> & {
  [ProfileDetailsFormField.Active]: boolean
  [PasswordChangeFormField.Password]: string
  [PasswordChangeFormField.Verify]: string
  [ProfileDetailsFormField.PreferredCountry]: IXtAutocompleteOption | null
  [ProfileDetailsFormField.RowsPerPage]: IXtAutocompleteOption | null
}
