import * as React from 'react'
import { useState, ReactElement, PropsWithChildren } from 'react'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { XtSelect } from 'components/controls/xt-select/xt-select'
import { XtInfoValues } from 'components/xt-info-values/xt-info-values'
import { IFilterFormState } from 'components/pagefilter/filter/filter.types'
import { useFilter } from 'common/hooks/filter'
import { XtFilter } from 'components/pagefilter/filter/filter'
import { parseFilterValues } from 'core/services/pagefilters/pagefilters.utils'
import { FilterOption, IXtPageFilter, IXtPageFilterContext } from './pagefilter.types'
import * as styles from './pagefilter.module.scss'
import { createPageFilterContext, usePageFilterContext } from './pagefilter.utils'
import { filtersPresetInfoValue } from './filter/filter-dialog-wrapper'

function PageFilterComponentLayout<TFiltersState extends IFilterFormState>({
  children,
  onFilterClick,
}: PropsWithChildren<{ onFilterClick?: () => void }>): ReactElement {
  const {
    state: { pageFilters, handleLastUsedFilter },
    preset,
    setPreset,
    filter,
    onClick,
  } = usePageFilterContext<TFiltersState>()

  const onPresetChange = (option: FilterOption): void => {
    setPreset(option)
    const params = parseFilterValues<TFiltersState>(option.value)
    if (params) {
      void filter(params)
      void handleLastUsedFilter(params)
    }
  }

  const onFilterButtonClick = (): void => {
    if (onClick) {
      onClick()
    }
    if (onFilterClick) {
      onFilterClick()
    }
  }

  return (
    <div className={styles.filterContent}>
      {children}
      <XtResponsiveButton showIconOnDesktopMode={false} icon={SvgIconIds.FILTER} label="Filters" onClick={onFilterButtonClick} />
      <div className={styles.presetContent}>
        <XtInfoValues values={filtersPresetInfoValue} />
        <XtSelect clearable options={pageFilters} label="" onChange={onPresetChange} value={preset} disabled={!pageFilters.length} />
      </div>
    </div>
  )
}

function PageFilterComponentWithCustom<TFiltersState extends IFilterFormState>(): ReactElement {
  const { CustomFilterDialog } = usePageFilterContext<TFiltersState>()
  return <PageFilterComponentLayout>{CustomFilterDialog && CustomFilterDialog()}</PageFilterComponentLayout>
}

function PageFilterComponent<TFiltersState extends IFilterFormState>(): ReactElement {
  const context = usePageFilterContext<TFiltersState>()
  const { filter, resolveFilters } = context

  const { openFilters, closeFilters, filtersAvailable, onFiltersSubmit, filterOpen, filters } = useFilter(
    resolveFilters ? resolveFilters : () => [],
    filter
  )

  const onFilterClick = (): void => {
    openFilters()
  }

  return (
    <PageFilterComponentLayout onFilterClick={onFilterClick}>
      {filtersAvailable && <XtFilter open={filterOpen} onClose={closeFilters} onSubmit={onFiltersSubmit} filters={filters} />}
    </PageFilterComponentLayout>
  )
}

export function XtPageFilter<TFiltersState extends IFilterFormState>(context: IXtPageFilter<TFiltersState>): React.ReactElement {
  const PageFilterContext = createPageFilterContext<IXtPageFilterContext<TFiltersState>>()
  const [preset, setPreset] = useState<FilterOption | null>(null)

  const { CustomFilterDialog } = context
  return (
    <PageFilterContext.Provider value={{ ...context, preset, setPreset }}>
      {CustomFilterDialog ? <PageFilterComponentWithCustom /> : <PageFilterComponent />}
    </PageFilterContext.Provider>
  )
}
