import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { CommentsModule } from 'comments/comments.module'
import { CharacteristicsModule } from 'characteristics/characteristics.module'
import { DocumentsModule } from 'documents/documents.module'
import { Injector } from 'core/injector/injector.service'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { Scope } from 'core/injector/injector.types'
import { ICommentsService } from 'comments/comments.service'
import { commentsServiceToken } from 'comments/comments.constants'
import { documentsServiceToken } from 'documents/documents.constants'
import { IDocumentsService } from 'documents/documents.types'
import { IItemsService } from './items/items.service'
import { IRoutingService } from './routing/routing.service'
import { IBomService } from './bom/bom.service'
import {
  bomItemsServiceToken,
  bomServiceToken,
  bomUtilsServiceToken,
  itemSitesServiceToken,
  itemSitesUtilsServiceToken,
  itemsServiceToken,
  itemsUtilsServiceToken,
  productsModule,
  routingServiceToken,
  routingUtilsServiceToken,
} from './products.constants'
import { IBomItemsService } from './bom/bom-item/bom-item.service'
import { IBomUtilsService } from './bom/bom-utils.service'
import { IItemsUtilsService } from './items/items-utils.service'
import { IRoutingUtilsService } from './routing/routing-utils.service'
import { CoreModule } from '../core/core.module'
import { AuthModule } from '../auth/auth.module'
import { IItemSitesService } from './items/item-sites.service'
import { IErrorHandler } from '../core/services/error-handler.service'
import { errorHandlerServiceToken, revisionServiceToken } from '../core/core.constants'
import { ItemsSharedModule } from './items-shared/items-shared.module'
import { IRevisionService } from '../core/services/revision.service'
import { IItemSitesUtilsService } from './items/item-sites-utils.service'

export type ProductsModuleDefinition = typeof import('./products.exports')

export interface IProductsModuleServices {
  BomService: IBomService
  RoutingService: IRoutingService
  ItemsService: IItemsService
  ItemSitesService: IItemSitesService
  ItemSitesUtilsService: IItemSitesUtilsService
  BomItemsService: IBomItemsService
  BomUtilsService: IBomUtilsService
  ItemsUtilsService: IItemsUtilsService
  RoutingUtilsService: IRoutingUtilsService
}

export const ProductsModule: ILazyModule<ProductsModuleDefinition> = {
  name: productsModule,
  resolver: () => import('./products.exports'),
  initializer: ({
    BomService,
    ItemsService,
    RoutingService,
    BomItemsService,
    BomUtilsService,
    ItemsUtilsService,
    RoutingUtilsService,
    ItemSitesService,
    ItemSitesUtilsService,
  }) => {
    const httpClient = Injector.resolve<IHttpClient>(httpClientToken)
    Injector.register<IBomService>(bomServiceToken, () => new BomService(httpClient), Scope.Singleton)
    Injector.register<IRoutingService>(routingServiceToken, () => new RoutingService(httpClient), Scope.Singleton)
    Injector.register<IItemsService>(
      itemsServiceToken,
      (injector) => {
        const commentService = injector.resolve<ICommentsService>(commentsServiceToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)

        return new ItemsService(httpClient, commentService, documentsService)
      },
      Scope.Singleton
    )
    Injector.register<IBomItemsService>(
      bomItemsServiceToken,
      (injector) => {
        const commentService = injector.resolve<ICommentsService>(commentsServiceToken)

        return new BomItemsService(httpClient, commentService)
      },
      Scope.Singleton
    )
    Injector.register<IBomUtilsService>(
      bomUtilsServiceToken,
      (injector) => {
        const bomService = injector.resolve<IBomService>(bomServiceToken)
        const bomItemsService = injector.resolve<IBomItemsService>(bomItemsServiceToken)

        return new BomUtilsService(bomService, bomItemsService)
      },
      Scope.Singleton
    )
    Injector.register<IItemSitesService>(
      itemSitesServiceToken,
      (injector) => {
        const commentService = injector.resolve<ICommentsService>(commentsServiceToken)

        return new ItemSitesService(httpClient, commentService)
      },
      Scope.Singleton
    )
    Injector.register<IItemSitesUtilsService>(
      itemSitesUtilsServiceToken,
      (injector) => {
        const itemsSitesService = injector.resolve<IItemSitesService>(itemSitesServiceToken)
        return new ItemSitesUtilsService(itemsSitesService)
      },
      Scope.Singleton
    )

    Injector.register<IItemsUtilsService>(
      itemsUtilsServiceToken,
      (injector) => {
        const itemsService = injector.resolve<IItemsService>(itemsServiceToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
        const itemSitesService = Injector.resolve<IItemSitesService>(itemSitesServiceToken)
        const errorHandler = Injector.resolve<IErrorHandler>(errorHandlerServiceToken)
        const revisionService = injector.resolve<IRevisionService>(revisionServiceToken)

        return new ItemsUtilsService(itemsService, itemSitesService, documentsService, errorHandler, revisionService)
      },
      Scope.Singleton
    )
    Injector.register<IRoutingUtilsService>(
      routingUtilsServiceToken,
      (injector) => {
        const routingService = injector.resolve<IRoutingService>(routingServiceToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)

        return new RoutingUtilsService(routingService, documentsService)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, AuthModule, CommentsModule, CharacteristicsModule, DocumentsModule, ItemsSharedModule],
}
