import { renderEmailCell } from 'components/controls/email/email-link/email-link.utils'
import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { booleanColumnConverter, convertPhoneData } from 'common/utils/table.utils'
import { dateConverter } from 'common/utils/date.utils'
import { renderPhoneCell } from 'components/controls/phone/phone-link/phone-link.utils'
import { AccountAction, IAccountTableItem } from './accounts-list.types'
import { PhoneRole } from '../../../contacts/contacts.types'
import { AccountFilterFieldName, AccountFilterPanel } from '../services/accounts-services.types'

export const AccountActions: IXtTableCellAction[] = [
  {
    name: AccountAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: AccountAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: AccountAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const crmAccountColumns: IXtTableColumn<IAccountTableItem>[] = [
  {
    id: 'number',
    field: 'number',
    headerName: 'Number',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'active',
    field: 'active',
    headerName: 'Active',
    flex: '1 0 100px',
    width: 100,
    converter: booleanColumnConverter,
  },
  {
    id: 'name',
    field: 'name',
    headerName: 'Name',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'owner',
    field: 'owner',
    headerName: 'Owner',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'firstName',
    field: 'primary_contact.first_name',
    headerName: 'First Name',
    flex: '1 0 200px',
    width: 200,
    unsortable: true,
  },
  {
    id: 'lastName',
    field: 'primary_contact.last_name',
    headerName: 'Last Name',
    flex: '1 0 200px',
    width: 200,
    unsortable: true,
  },
  {
    id: 'phones',
    headerName: 'Phone Number',
    flex: '1 0 240px',
    width: 240,
    unsortable: true,
    renderCell: (item) => renderPhoneCell(convertPhoneData(item?.primary_contact?.phones ?? [], PhoneRole.Mobile)),
  },
  {
    id: 'email',
    headerName: 'Email',
    flex: '1 0 260px',
    width: 260,
    unsortable: true,
    renderCell: (item) => renderEmailCell(item?.primary_contact?.email),
  },
  {
    id: 'address',
    field: 'primary_contact.contact_address.address1',
    headerName: 'Address',
    flex: '1 0 260px',
    width: 260,
    unsortable: true,
  },
  {
    id: 'city',
    field: 'primary_contact.contact_address.city',
    headerName: 'City',
    flex: '1 0 160px',
    width: 160,
    unsortable: true,
  },
  {
    id: 'state',
    field: 'primary_contact.contact_address.state',
    headerName: 'State',
    flex: '1 0 110px',
    width: 110,
    unsortable: true,
  },
  {
    id: 'country',
    field: 'primary_contact.contact_address.country',
    headerName: 'Country',
    flex: '1 0 120px',
    width: 120,
    unsortable: true,
  },
  {
    id: 'postalCode',
    field: 'primary_contact.contact_address.postalcode',
    headerName: 'Postal Code',
    flex: '1 0 140px',
    width: 140,
    unsortable: true,
  },
  {
    id: 'customer',
    field: 'roles.customer',
    headerName: 'Customer',
    flex: '1 0 120px',
    width: 120,
    converter: booleanColumnConverter,
    unsortable: true,
  },
  {
    id: 'prospect',
    field: 'roles.prospect',
    headerName: 'Prospect',
    flex: '1 0 110px',
    width: 110,
    converter: booleanColumnConverter,
    unsortable: true,
  },
  {
    id: 'created',
    field: 'created',
    headerName: 'Create Date',
    flex: '1 0 150px',
    width: 150,
    converter: dateConverter,
  },
]

export const defaultFilterValues: AccountFilterPanel = {
  [AccountFilterFieldName.ShowInactive]: false,
  [AccountFilterFieldName.AccountNumberPattern]: '',
  [AccountFilterFieldName.AccountNamePattern]: '',
  [AccountFilterFieldName.AccountType]: null,
  [AccountFilterFieldName.ContactNamePattern]: '',
  [AccountFilterFieldName.CityPattern]: '',
  [AccountFilterFieldName.StatePattern]: '',
  [AccountFilterFieldName.Owner]: null,
}
