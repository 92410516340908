import { IRemarksHook } from 'comments/remarks/remarks-hook'
import { IDocumentsHook } from 'documents/documents-hook'
import { IAppliedCharacteristic } from 'characteristics/characteristics.types'
import { IProspect } from 'companies/prospects/prospects.types'
import { XtMode } from 'common/common.types'

export enum ProspectDetailsTabsName {
  General = 'General',
  Contacts = 'Contacts',
  Tasks = 'Tasks',
  Remarks = 'Remarks',
  Quotes = 'Quotes',
  Characteristics = 'Characteristics',
  Documents = 'Documents',
}

export interface IProspectDetailsTabs extends IProspectDetailsCharacteristicsTab {
  disabled: boolean
  isMobile: boolean
  characteristics: IAppliedCharacteristic[]
  onCreateCharacteristic(newCharacteristic: IAppliedCharacteristic): void
  onUpdateCharacteristic(updatedCharacteristic: IAppliedCharacteristic): void
  onDeleteCharacteristic(deletedCharacteristic: IAppliedCharacteristic): void
  remarks: IRemarksHook
  documents: IDocumentsHook
  prospectNumber?: string
  prospect?: IProspect | null
  mode: XtMode
}

export interface IProspectDetailsGeneralTab {
  disabled: boolean
  shouldSetDefaults: boolean
}

export interface IProspectDetailsCharacteristicsTab {
  characteristics: IAppliedCharacteristic[]
  onCreateCharacteristic(newCharacteristic: IAppliedCharacteristic): void
  onUpdateCharacteristic(updatedCharacteristic: IAppliedCharacteristic): void
  onDeleteCharacteristic(deletedCharacteristic: IAppliedCharacteristic): void
}
