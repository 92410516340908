import React, { FC, memo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { FormXtAutocomplete } from 'common/utils/form/form.components'
import * as styles from './characteristic-dialog.module.scss'
import { CharacteristicDialogFormSchema } from './characteristic-dialog.validation'
import {
  ICharacteristicDialog,
  CharacteristicDialogFormLabel,
  CharacteristicDialogFormField,
  ICharacteristicFormState,
} from './characteristic-dialog.types'
import { CharacteristicDialogValueInput } from './characteristic-dialog.value-input'
import { convertCharacteristicFormValues, defineCharacteristicFormState } from './characteristic-dialog.utils'
import { useCharacteristicsModule } from '../characteristics-module-hook'
import { CharacteristicOption } from '../characteristics.types'
import { defaultCharacteristicFormValues } from './characteristic-dialog.constants'

export const CharacteristicDialog: FC<ICharacteristicDialog> = memo(
  ({ onClose, onConfirm, usedOnFilter, characteristic: defaultCharacteristic, value: defaultValue }) => {
    const { CharacteristicsUtilsService } = useCharacteristicsModule()

    const {
      handleSubmit,
      control,
      watch,
      reset,
      formState: { isDirty },
    } = useForm<ICharacteristicFormState>({
      defaultValues: defaultCharacteristicFormValues,
      resolver: yupResolver(CharacteristicDialogFormSchema),
      mode: 'onBlur',
    })

    const characteristic = watch(CharacteristicDialogFormField.Characteristic)

    const onSaveForm = (formData: ICharacteristicFormState): void => {
      onConfirm(convertCharacteristicFormValues(formData))
      reset(defaultCharacteristicFormValues)
    }

    useEffect(() => void reset(defineCharacteristicFormState(defaultCharacteristic, defaultValue)), [
      reset,
      defaultCharacteristic,
      defaultValue,
    ])

    const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
      e.stopPropagation() // To prevent submitting parent forms
      const eventHandler = handleSubmit(onSaveForm)
      void eventHandler(e)
    }

    const onCancel = (): void => {
      if (isDirty && !confirm('Are you sure you want to leave the dialog? Updates will not be applied.')) {
        return
      }
      reset(defaultCharacteristicFormValues)
      onClose()
    }

    const onCharacteristicChange = (characteristicOption: CharacteristicOption | null): void => {
      reset({ [CharacteristicDialogFormField.Characteristic]: characteristicOption, [CharacteristicDialogFormField.Value]: null })
    }

    return (
      <form onSubmit={submitForm} className={styles.characteristicDialog}>
        <FormXtAutocomplete
          control={control}
          name={CharacteristicDialogFormField.Characteristic}
          label={CharacteristicDialogFormLabel.Characteristic}
          loadOptions={CharacteristicsUtilsService.loadCharacteristicOptions}
          onChange={onCharacteristicChange}
          filters={usedOnFilter}
        />
        <CharacteristicDialogValueInput
          mask={characteristic?.mask}
          control={control}
          options={characteristic?.list_options ?? []}
          type={characteristic?.characteristic_type}
          validator={characteristic?.characteristic_validator ?? null}
        />
        <div className={styles.characteristicDialogButtons}>
          <XtButton label="Cancel" onClick={onCancel} />
          <XtButton type="submit" label="Save" disabled={!isDirty} />
        </div>
      </form>
    )
  }
)
