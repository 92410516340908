import type { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import type { QuotesModuleDefinition } from 'quotes/quotes.module'
import type { SalesOrdersModuleDefinition } from 'sales-orders/sales-orders.module'
import type { PurchaseOrdersModuleDefinition } from 'purchase-orders/purchase-orders.module'
import { ItemSourceModule, ItemSourceModuleDefinition } from 'item-source/item-source.module'
import { PurchaseRequestModule, PurchaseRequestsModuleDefinition } from 'purchase-requests/purchase-requests.module'
import { PurchaseOrdersModule } from 'purchase-orders/purchase-orders.module'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { QuotesModule } from 'quotes/quotes.module'
import { SalesOrdersModule } from 'sales-orders/sales-orders.module'
import { ordersModuleToken } from './orders.constants'

export type OrdersModuleDefinition = QuotesModuleDefinition &
  SalesOrdersModuleDefinition &
  PurchaseOrdersModuleDefinition &
  PurchaseRequestsModuleDefinition &
  ItemSourceModuleDefinition

export const OrdersModule: ILazyModule<OrdersModuleDefinition> = {
  name: ordersModuleToken,
  resolver: async () => {
    const quotesModule = LazyModulesService.resolveModule<QuotesModuleDefinition>(QuotesModule)
    const salesOrdersModule = LazyModulesService.resolveModule<SalesOrdersModuleDefinition>(SalesOrdersModule)
    const purchaseOrdersModule = LazyModulesService.resolveModule<PurchaseOrdersModuleDefinition>(PurchaseOrdersModule)
    const purchaseRequestsModule = LazyModulesService.resolveModule<PurchaseRequestsModuleDefinition>(PurchaseRequestModule)
    const itemSourceModule = LazyModulesService.resolveModule<ItemSourceModuleDefinition>(ItemSourceModule)
    return {
      ...quotesModule,
      ...salesOrdersModule,
      ...purchaseOrdersModule,
      ...purchaseRequestsModule,
      ...itemSourceModule,
    }
  },
  initializer: () => {},
  dependencies: [QuotesModule, SalesOrdersModule, PurchaseOrdersModule, PurchaseRequestModule, ItemSourceModule],
}
