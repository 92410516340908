import type { IAsyncRoute, IRoute } from 'common/routing/routing.types'
import type { ActivitiesModuleDefinition } from './activities.module'
import { XtMode } from 'common/common.types'
import { buildPageComponent } from 'common/routing/routing.utils'
import { TasksGuard } from 'tasks/tasks.guard'
import { tasksRouteFactory } from 'tasks/tasks.routes'
import { activitiesRoutePath, activitiesRoutes } from './activities.constants'
import { ActivitiesGuard } from './activities.guard'
import { ActivitiesModule } from './activities.module'
import { OpportunitiesGuard } from './opportunities/opportunities.guard'
import { OpportunityDetailsGuard } from './opportunities/opportunity-details/opportunity-details.guard'
import { opportunitiesRoutes } from './opportunities/opportunities.constants'
import { OpportunitiesBoard } from './opportunities/opportunities.export'

function activitiesRouteFactory(moduleDefinition: ActivitiesModuleDefinition): IRoute[] {
  const { OpportunitiesList, OpportunityDetailsPage } = moduleDefinition

  return [
    {
      path: activitiesRoutes.opportunities,
      name: 'Opportunities',
      guards: [OpportunitiesGuard],
      children: [
        {
          path: '',
          exact: true,
          component: OpportunitiesList,
        },
        {
          path: `/:opportunityNumber/${XtMode.Edit}`,
          component: (props) => buildPageComponent(props, XtMode.Edit, OpportunityDetailsPage),
          guards: [OpportunityDetailsGuard],
        },
        {
          path: `/:opportunityNumber/${XtMode.View}`,
          component: (props) => buildPageComponent(props, XtMode.View, OpportunityDetailsPage),
        },
        {
          path: `/${XtMode.New}`,
          component: (props) => buildPageComponent(props, XtMode.New, OpportunityDetailsPage),
          guards: [OpportunityDetailsGuard],
        },
        {
          path: opportunitiesRoutes.kanban,
          exact: true,
          component: OpportunitiesBoard,
          guards: [OpportunitiesGuard],
        },
      ],
    },
    {
      path: activitiesRoutes.tasks,
      name: 'Tasks',
      guards: [TasksGuard],
      children: tasksRouteFactory(moduleDefinition),
    },
  ]
}

export const activitiesRoute: IAsyncRoute<ActivitiesModuleDefinition> = {
  path: activitiesRoutePath,
  module: ActivitiesModule,
  name: 'Activities',
  guards: [ActivitiesGuard],
  childrenRoutesFactory: activitiesRouteFactory,
}
