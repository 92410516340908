/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { Table, TableBody, TableContainer, TablePagination, TablePaginationProps } from '@material-ui/core'
import { useCallback } from 'react'
import { XtTableHead } from './table-head/table-head'
import { IPagination, IXtTableType } from './table.types'
import { XtTableRow } from './table-row/table-row'
import { cls } from '../../common/utils/utils'
import { arePropsEqual } from './table.utils'
import './table.scss'

const tableContainerRootClasses = { root: 'xt-table-container' }
const tableRootClasses = { root: 'xt-table-root-element' }
const tableBodyClasses = { root: 'xt-table-body' }
const tablePaginationRootClasses = { root: 'xt-table-pagination' }
const defaultRowsPerPageOptions = [10, 25, 50, 100]

export const getRowsPerPageOptions = (pagination: IPagination): Array<number> => {
  if (!pagination || defaultRowsPerPageOptions.includes(pagination.rowsPerPage)) {
    return defaultRowsPerPageOptions
  }
  return [...defaultRowsPerPageOptions, pagination.rowsPerPage].sort((a, b) => a - b)
}

// TODO check out dnd on small screen width
// for performance issues search for react beautiful dnd optimize performance
export const XtTable: IXtTableType = React.memo(
  ({
    columns,
    rows,
    loading = false,
    onRowClick,
    withDnd = false,
    onRowPositionChanged,
    rowClassName,
    pagination,
    className,
    sortOptions,
    onColumnHeaderClick,
    renderRow,
  }) => {
    if (withDnd && typeof onRowPositionChanged !== 'function') {
      throw Error('onRowPositionChanged should be provided for proper dnd feature usage.')
    }

    const onDragEnd = useCallback(
      (result: DropResult, _: ResponderProvided) => {
        if (!withDnd || typeof onRowPositionChanged !== 'function') {
          return
        }

        const { destination, source, draggableId, reason } = result

        if (!destination || reason === 'CANCEL') {
          return
        }
        const oldPosition = source.index
        const newPosition = destination.index
        if (oldPosition === newPosition && destination.droppableId === source.droppableId) {
          return
        }

        onRowPositionChanged({
          newPosition,
          oldPosition,
          rowId: draggableId,
        })
      },
      [withDnd, onRowPositionChanged]
    )

    const onChangePage = useCallback<TablePaginationProps['onChangePage']>((_, newPage) => pagination?.onChangePage(newPage), [pagination])

    const onChangeRowsPerPage: TablePaginationProps['onChangeRowsPerPage'] = useCallback(
      ({ target: { value } }) => pagination?.onChangeRowsPerPage(parseInt(value, 10)),
      [pagination]
    )

    return (
      <div className={cls('xt-table', className)}>
        {loading && <div className="xt-table-overlay" />}
        <TableContainer classes={tableContainerRootClasses}>
          <Table component="div" classes={tableRootClasses}>
            <XtTableHead loading={loading} columns={columns} sortOptions={sortOptions} onSortLabelClick={onColumnHeaderClick} />
            <div hidden={loading || rows.length !== 0} className="xt-table-empty-container">
              No data available.
            </div>
            {(rows.length > 0 || loading) && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable isDropDisabled={!withDnd} droppableId="droppable">
                  {(provided) => (
                    <TableBody classes={tableBodyClasses} component="div" {...provided.droppableProps} ref={provided.innerRef}>
                      {rows.map((row, index) => (
                        <XtTableRow
                          key={row.id}
                          rowClassName={rowClassName}
                          withDnd={withDnd}
                          onClick={onRowClick}
                          item={row}
                          columns={columns}
                          index={index}
                          selected={row.selected}
                          renderRow={renderRow}
                        />
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Table>
        </TableContainer>
        {pagination && (
          <TablePagination
            classes={tablePaginationRootClasses}
            component="div"
            count={pagination.count}
            rowsPerPage={pagination.rowsPerPage}
            rowsPerPageOptions={getRowsPerPageOptions(pagination)}
            page={pagination.page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        )}
      </div>
    )
  },
  arePropsEqual
)
