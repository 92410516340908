import { useCallback, useRef, useState } from 'react'
import { ITableRow } from 'components/table/table.types'
import { deleteByFieldValue, updateByFieldValue } from 'common/utils/array.utils'

export type TableStateHookItem<T extends ITableRow> = T & {
  isValid: boolean
  errors?: TableStateHookValidationError<T>
  touched: boolean
}

export interface ITableStateHookChange<T extends ITableRow> {
  data: TableStateHookItem<T>[]
  isValid: boolean
  isDirty: boolean
}

export type TableStateHookChange<T extends ITableRow> = (change: ITableStateHookChange<T>) => void

export type TableStateHookValidationError<T extends object> = { [Key in keyof T]?: string }

export type TableStateHookValidator<T extends object> = (data: T) => TableStateHookValidationError<T> | undefined

export interface ITableStateHookParams<T extends ITableRow> {
  data: T[]
  onChange?: TableStateHookChange<T>
  validate?: TableStateHookValidator<T>
}

export interface ITableStateHook<T extends ITableRow> extends ITableStateHookState<T> {
  reset(factory: (prevData: T[]) => T[], validator?: TableStateHookValidator<T>): void
  reset(data: T[], validator?: TableStateHookValidator<T>): void
  addItem(item: T): void
  deleteItem(itemId: T['id']): void
  updateItem(item: T): void
  resetValidator(validator: TableStateHookValidator<T>): void
  getState(): ITableStateHookState<T>
  validate(): boolean
}

export interface ITableStateHookState<T extends ITableRow> {
  data: TableStateHookItem<T>[]
  isValid: boolean
  isDirty: boolean
}

function areItemsValid(items: TableStateHookItem<ITableRow>[]): boolean {
  return items.every(({ isValid }) => isValid)
}

function processItem<T extends ITableRow>(item: T, validator: TableStateHookValidator<T>, touched: boolean): TableStateHookItem<T> {
  const errors = validator(item)

  const isValid = !errors || !Object.keys(errors).length

  return { ...item, isValid, errors, touched }
}

function validateItems<T extends ITableRow>(
  items: TableStateHookItem<T>[],
  validator: TableStateHookValidator<T>
): Omit<ITableStateHookState<T>, 'isDirty'> {
  const data = items.map((item) => processItem(item, validator, item.touched))
  return {
    data: items.map((item) => processItem(item, validator, item.touched)),
    isValid: areItemsValid(data),
  }
}

function defineTableState<T extends ITableRow>(items: T[], validator: TableStateHookValidator<T>): ITableStateHookState<T> {
  let isValid = true
  const data: TableStateHookItem<T>[] = []

  items.forEach((item) => {
    const processedItem = processItem(item, validator, false)

    if (!processedItem.isValid) {
      isValid = false
    }

    data.push(processedItem)
  })

  return { data, isValid, isDirty: false }
}

export function useTableState<T extends ITableRow>({
  data: initialData,
  onChange,
  validate: initialValidator = () => undefined,
}: ITableStateHookParams<T>): ITableStateHook<T> {
  const [state, setState] = useState<ITableStateHookState<T>>(() => defineTableState(initialData, initialValidator))

  const stateRef = useRef<ITableStateHookState<T>>(state)

  const onChangeRef = useRef<TableStateHookChange<T>>((newState) => {
    stateRef.current = newState
    if (onChange) {
      onChange(newState)
    }
  })

  const validateRef = useRef<typeof initialValidator>(initialValidator)

  const addItem = useCallback<(item: T) => void>(
    (item) =>
      setState((prevState) => {
        const processedItem = processItem(item, validateRef.current, false)
        const items = [...prevState.data, processedItem]

        const newState = { data: items, isValid: prevState.isValid, isDirty: true }
        onChangeRef.current(newState)

        return newState
      }),
    []
  )

  const updateItem = useCallback<(item: TableStateHookItem<T>) => void>(
    (item) =>
      setState((prevState) => {
        const processedItem = processItem(item, validateRef.current, item.touched)
        const data = updateByFieldValue(prevState.data, 'id', processedItem)
        const isValid = areItemsValid(data)
        const newState = { data, isValid, isDirty: true }
        onChangeRef.current(newState)

        return newState
      }),
    []
  )

  const deleteItem = useCallback<(itemId: string) => void>(
    (itemId) =>
      setState((prevState) => {
        const data = deleteByFieldValue(prevState.data, 'id', itemId)
        const isValid = areItemsValid(data)

        const newState = { data, isValid, isDirty: true }
        onChangeRef.current(newState)

        return newState
      }),
    []
  )

  const validate = useCallback<() => boolean>(() => {
    let isValid = true
    setState(({ data, isDirty }) => {
      const newState = {
        ...validateItems(data, validateRef.current),
        isDirty,
      }
      onChangeRef.current(newState)

      isValid = newState.isValid

      return newState
    })
    return isValid
  }, [])

  const resetValidator = useCallback<(validator: typeof initialValidator) => void>(
    (validator) => {
      validateRef.current = validator

      validate()
    },
    [validate]
  )

  const reset = useCallback<(payload: T[] | ((prevData: T[]) => T[]), validateFn?: typeof initialValidator) => void>(
    (payload, validateFn) => {
      validateRef.current = validateFn ?? validateRef.current
      setState(({ data: prevData }) => {
        const newData = typeof payload === 'function' ? payload(prevData) : payload

        const data = newData.map((item) => processItem(item, validateRef.current, false))

        const newState = {
          data,
          isValid: areItemsValid(data),
          isDirty: false,
        }

        onChangeRef.current(newState)

        return newState
      })
    },
    []
  )

  const getState = useCallback<() => ITableStateHookState<T>>(() => {
    return stateRef.current
  }, [])

  return {
    ...state,
    addItem,
    updateItem,
    deleteItem,
    reset,
    resetValidator,
    getState,
    validate,
  }
}
