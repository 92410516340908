import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { FilterOption } from 'components/pagefilter/pagefilter.types'
import { IPageFilterService } from './pagefilters.service'
import { IPageFilter, IParsedPageFilter, LastUsed } from './pagefilters.types'
import {
  IPageFiltersData,
  IFiltersOptionsParams,
  IFilterDeleteParams,
  IHandleLastUsedFilterParams,
  IPageFiltersParams,
} from './pagefilters-utils.types'
import { parseFilterValues } from './pagefilters.utils'

export interface IPageFilterUtilsService {
  getLastUsedFilter(pagename: string): Promise<IParsedPageFilter | null>
  getAllFilter(pagename: string): Promise<IPageFiltersData>
  getAllFilterOptions(filters?: IFiltersOptionsParams): Promise<FilterOption[]>
  deleteFilter(params: IFilterDeleteParams): Promise<void>
  updateLastUsedFilter(params: IHandleLastUsedFilterParams): Promise<IParsedPageFilter | null>
  createLastUsedFilter(params: IHandleLastUsedFilterParams): Promise<IParsedPageFilter | null>
  updateFilter(data?: IPageFiltersParams): Promise<void>
  createFilter(data?: IPageFiltersParams): Promise<void>
}

export class PageFilterUtilsService implements IPageFilterUtilsService {
  constructor(private readonly pagefilterService: IPageFilterService) {
    bindAllInstanceMethods(this)
  }

  public async getLastUsedFilter(pagename: string): Promise<IParsedPageFilter | null> {
    const { filters } = await this.pagefilterService.getAll(pagename)
    if (!filters) {
      return null
    }

    const lastUsed = filters.find((filter: IPageFilter) => filter.filtername === LastUsed)

    if (lastUsed) {
      try {
        return await parseFilterValues(lastUsed?.filtervalues ?? '')
      } catch (e) {
        console.log(e)
        return null
      }
    }
    return null
  }

  public async getAllFilter(pagename: string): Promise<IPageFiltersData> {
    const data = await this.pagefilterService.getAll(pagename)
    return data
  }

  public async getAllFilterOptions({ pagename }: IFiltersOptionsParams): Promise<FilterOption[]> {
    const { filters } = await this.pagefilterService.getAll(pagename)

    return filters
      ? filters
          .filter((filter: IPageFilter) => filter.filtername !== LastUsed)
          .map((item: IPageFilter) => ({ id: item.filtername, value: item?.filtervalues ?? '', label: item.filtername }))
      : []
  }

  public async deleteFilter({ pagename, username, filtername }: IFilterDeleteParams): Promise<void> {
    const data = {
      username,
      pagename,
      filters: [{ filtername }],
    }
    await this.pagefilterService.delete(data)
  }

  public async updateLastUsedFilter({ pagename, username, filterValues }: IHandleLastUsedFilterParams): Promise<IParsedPageFilter | null> {
    const data = {
      username,
      pagename,
      filters: [
        {
          shared: false,
          filtername: LastUsed,
          filtervalues: filterValues,
        },
      ],
    }
    await this.pagefilterService.update(data)
    return this.getLastUsedFilter(pagename)
  }

  public async createLastUsedFilter({ pagename, username, filterValues }: IHandleLastUsedFilterParams): Promise<IParsedPageFilter | null> {
    const data = {
      username,
      pagename,
      filters: [
        {
          shared: false,
          filtername: LastUsed,
          filtervalues: filterValues,
        },
      ],
    }
    await this.pagefilterService.create(data)
    return this.getLastUsedFilter(pagename)
  }

  public async updateFilter(data: IPageFiltersParams): Promise<void> {
    await this.pagefilterService.update(data)
  }

  public async createFilter(data: IPageFiltersParams): Promise<void> {
    await this.pagefilterService.create(data)
  }
}
