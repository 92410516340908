import { defineAutocompleteOption, parseAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { numberConverter } from 'common/utils/utils'
import { ICountry } from 'dictionary/dictionary.types'
import { defineCountryOption } from 'dictionary/dictionary.utils'
import { IUser, IUserPayload } from '../users/users.types'
import { PasswordChangeFormField, ProfileDetailsForm, ProfileDetailsFormField } from './profile.types'

export function defineFormData(user: IUser | null, country?: ICountry | null): ProfileDetailsForm {
  return {
    [ProfileDetailsFormField.Username]: user?.username ?? '',
    [ProfileDetailsFormField.ProperName]: user?.proper_name ?? '',
    [ProfileDetailsFormField.Initials]: user?.initials ?? '',
    [ProfileDetailsFormField.Active]: user?.status ? user.status === 'active' : true,
    [ProfileDetailsFormField.Locale]: defineAutocompleteOption(user?.locale),
    [ProfileDetailsFormField.PreferredCountry]: country ? defineCountryOption(country) : null,
    [ProfileDetailsFormField.RowsPerPage]: defineAutocompleteOption(numberConverter(user?.rows_per_page, 0)) ?? null,
    [PasswordChangeFormField.Password]: '',
    [PasswordChangeFormField.Verify]: '',
  }
}

export function convertFormData({
  username,
  proper_name,
  initials,
  locale,
  password,
  preferred_country,
  rows_per_page,
}: ProfileDetailsForm): IUserPayload {
  const rowsPerPage = !isNaN(Number(parseAutocompleteOption(rows_per_page))) ? Number(parseAutocompleteOption(rows_per_page)) : null
  const payload = {
    username,
    proper_name,
    initials,
    locale: parseAutocompleteOption(locale),
    preferred_country: parseAutocompleteOption(preferred_country),
    rows_per_page: rowsPerPage,
  }

  return password ? { ...payload, password } : payload
}
