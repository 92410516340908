import { Overwrite } from 'utility-types'
import { Observable } from 'rxjs'
import { ICustomer } from '../../customers.types'

import { IXtAutocompleteOption } from '../../../../components/controls/xt-autocomplete/xt-autocomplete.types'
import { SingleFormRegister } from '../../../../common/hooks/form/multiple-form-manager/multiple-form-manager.types'

import { customerMainFormKey } from './customer-details-form.constants'

export enum CustomerMainFormField {
  Number = 'customer_number',
  Name = 'customer_name',
  Type = 'customer_type',
  Active = 'active',
}

export type CustomerMainFormDataFilled = Pick<
  ICustomer,
  CustomerMainFormField.Number | CustomerMainFormField.Name | CustomerMainFormField.Type | CustomerMainFormField.Active
>

export type CustomerMainFormData = Overwrite<
  CustomerMainFormDataFilled,
  {
    [CustomerMainFormField.Number]: string | null
    [CustomerMainFormField.Name]: string | null
    [CustomerMainFormField.Type]: string | null
    [CustomerMainFormField.Active]: boolean
  }
>

export interface ICustomerMainForm
  extends Overwrite<
    CustomerMainFormData,
    {
      [CustomerMainFormField.Number]: string
      [CustomerMainFormField.Name]: string
      [CustomerMainFormField.Type]: IXtAutocompleteOption | null
    }
  > {}

export interface ICustomerDetailsForm {
  disabled: boolean
  register: SingleFormRegister<typeof customerMainFormKey, ICustomerMainForm>
  customer: ICustomer | null
  isSubmitting: boolean
  customerNumber$: Observable<string | null>
  customerNumberDisabled: boolean
  disabledCustomerType: boolean
  customerName: string | null
}

export enum CustomerMainFormLabel {
  Number = 'Customer #',
  Name = 'Customer Name',
  Type = 'Customer Type',
  Active = 'Active',
}
