import * as yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { CustomerMainFormField } from './customer-details-form.types'

export const CustomerDetailsFormSchema = (disabledCustomerType: boolean) =>
  yup.object().shape({
    [CustomerMainFormField.Number]: yup.string().nullable().required(validationMessage.isRequired),
    [CustomerMainFormField.Name]: yup.string().nullable().required(validationMessage.isRequired),
    [CustomerMainFormField.Type]: disabledCustomerType
      ? yup.mixed().nullable().notRequired()
      : yup.mixed().nullable().required(validationMessage.isRequired),
  })
