import React, { useEffect, useState } from 'react'
import { IXtAutocompleteOption } from '../xt-autocomplete/xt-autocomplete.types'
import { GetInputLabelFunc, IXtSelectProps } from './xt-select.types'
import { XtAutocompletePlain } from '../xt-autocomplete/xt-autocomplete-plain'

function defaultInputLabelResolver<Option extends IXtAutocompleteOption>(option: Option | null): string {
  return option?.label ?? ''
}

function filterItems<Option extends IXtAutocompleteOption>(
  options: Option[],
  filter: string,
  getInputLabel: GetInputLabelFunc<Option> = defaultInputLabelResolver
): Option[] {
  return options.filter((option) => {
    const label = getInputLabel(option)
    return label.toLowerCase().includes(filter.toLowerCase())
  })
}

export function XtSelect<Option extends IXtAutocompleteOption>({
  label,
  value,
  disabled,
  options,
  onChange,
  className,
  error,
  hidden,
  onBlur,
  multiple,
  clearable = false,
  getInputLabel,
  getOptionDisabled,
  renderOption,
  disableInput,
}: IXtSelectProps<Option>): React.ReactElement {
  const [availableOptions, setAvailableOptions] = useState<Option[]>(options)

  const reset: VoidFunction = () => {
    setAvailableOptions(options)
  }

  const filter = async (searchFilter: string | null): Promise<void> => {
    if (!searchFilter) {
      reset()
    } else {
      const filteredItems = filterItems(options, searchFilter, getInputLabel)
      setAvailableOptions(filteredItems)
    }
  }

  useEffect(() => {
    setAvailableOptions(options)
  }, [options])

  return (
    <XtAutocompletePlain
      options={availableOptions}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      reset={reset}
      loadMore={() => {}}
      filter={filter}
      renderOption={renderOption}
      disableClearable={!clearable}
      disabled={disabled}
      placeholder={label}
      hidden={hidden}
      className={className}
      multiple={multiple}
      getInputLabel={getInputLabel}
      getOptionDisabled={getOptionDisabled}
      disableInput={disableInput}
    />
  )
}
