import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab'
import { CircularProgress, TextField as TextInput } from '@material-ui/core'
import * as React from 'react'
import { AutocompleteInputChangeReason } from '@material-ui/lab/useAutocomplete/useAutocomplete'
import { SyntheticEvent, useEffect, useRef } from 'react'
import { BehaviorSubject } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter as rxFilter, switchMap } from 'rxjs/operators'
import { cls } from 'common/utils/utils'
import { globalConstants } from 'common/constants'
import { IXtAutocompleteOption, IXtAutocompletePlainParams, XtAutocompleteValue } from './xt-autocomplete.types'
import './xt-autocomplete.scss'
import {
  buildInputProps,
  convertAutocompleteValue,
  getAutocompleteTitle,
  getLabel,
  renderAutocompleteOption,
} from './xt-autocomplete.utils'

const loaderSize = 20

export function XtAutocompletePlain<Option extends IXtAutocompleteOption>({
  value,
  onChange,
  options,
  reset,
  loading,
  loadMore,
  filter,
  onBlur = () => {},
  renderOption = renderAutocompleteOption,
  placeholder,
  disabled = false,
  className,
  error,
  hidden = false,
  getInputLabel = getLabel,
  noOptionsText = 'No matching items found.',
  getOptionDisabled,
  disableClearable,
  multiple,
  disableInput = false,
}: IXtAutocompletePlainParams<Option>): React.ReactElement {
  const inputValueRef = useRef<BehaviorSubject<string | null>>(new BehaviorSubject<string | null>(null))

  const selectValue = convertAutocompleteValue(value, multiple)

  useEffect(() => {
    const subscription = inputValueRef.current
      .pipe(
        debounceTime(globalConstants.inputDebounce),
        distinctUntilChanged(),
        rxFilter<string | null>((inputValue) => inputValue !== null),
        switchMap((inputValue) => filter(inputValue))
      )
      .subscribe()

    return () => {
      subscription.unsubscribe()
    }
  }, [filter])

  const onUserInput: (inputValue: string, reason: AutocompleteInputChangeReason) => void = (
    inputValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input') {
      inputValueRef.current!.next(inputValue)
    } else if (reason === 'reset' && inputValueRef.current?.value) {
      inputValueRef.current!.next(null)
      void reset()
    }
  }

  const onScroll: (event: SyntheticEvent) => void = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget
    if (!loading && scrollTop !== 0 && scrollTop + clientHeight >= scrollHeight * 0.8) {
      void loadMore()
    }
  }

  const onSelect = (item: XtAutocompleteValue<Option>, reason: AutocompleteChangeReason): void => {
    if (reason === 'select-option' || reason === 'clear' || reason === 'remove-option') {
      onChange(item as never)
    }
    if (reason === 'clear' && inputValueRef.current?.value) {
      inputValueRef.current!.next(null)
      void reset()
    }
  }

  return (
    <Autocomplete
      multiple={multiple}
      hidden={hidden}
      value={selectValue}
      title={getAutocompleteTitle(selectValue)}
      options={options}
      onBlur={onBlur}
      loading={loading}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getInputLabel}
      disableCloseOnSelect={multiple}
      getOptionSelected={(option: IXtAutocompleteOption, item: IXtAutocompleteOption) => option && item && option.id === item.id}
      ListboxProps={{ onScroll }}
      className={cls('xt-autocomplete-input', className)}
      onInputChange={(_, inputValue, reason) => onUserInput(inputValue, reason)}
      renderInput={(params) => (
        <TextInput
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          inputProps={buildInputProps(params.inputProps, disableInput || disabled)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={loaderSize} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={placeholder ?? ''}
          variant="outlined"
          error={!!error}
          helperText={error}
        />
      )}
      classes={{ listbox: 'xt-scrollbar-style xt-autocomplete-list-container' }}
      renderOption={renderOption}
      noOptionsText={noOptionsText}
      onChange={(_, option, reason) => onSelect(option, reason)}
      disabled={disabled}
      disableClearable={disableClearable}
    />
  )
}
