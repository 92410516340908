import { ProductsModule } from 'products/products.module'
import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { CoreModule } from 'core/core.module'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { IItemsService } from 'products/items/items.service'
import { itemsServiceToken } from 'products/products.constants'
import { IErrorHandler } from 'core/services/error-handler.service'
import { errorHandlerServiceToken } from 'core/core.constants'
import { costingBomUtilsServiceToken } from './bom/bom.constants'
import { ICostingBomUtilsService } from './bom/bom-utils.service'
import { costingModule } from './costing.constants'
import { costingItemCostsUtilsServiceToken, inventoryHistoryServiceToken } from './item-costs/item-costs.constants'
import { ICostingItemCostsUtilsService } from './item-costs/item-costs-utils.service'
import { SharedModule } from '../shared/shared-module'
import { QuantityOnHandModule, QuantityOnHandModuleDefinition } from './quantity-on-hand/quantity-on-hand.module'
import { LazyModulesService } from '../core/react-lazy-modules/react-lazy-modules.service'
import { httpClientToken, IHttpClient } from '../core/services/http-client'
import { SalesOrdersModule } from '../sales-orders/sales-orders.module'
import { IInventoryHistoryService, InventoryHistoryService } from './item-costs/inventory-history.service'

export type CostingModuleDefinition = typeof import('./costing.exports') & QuantityOnHandModuleDefinition

export interface ICostingModuleInterfaces {
  CostingBomUtilsService: ICostingBomUtilsService
  CostingItemCostsUtilsService: ICostingItemCostsUtilsService
}

export const CostingModule: ILazyModule<CostingModuleDefinition> = {
  name: costingModule,
  resolver: async () => {
    const costingModuleExport = await import('./costing.exports')
    const qohModuleExport = LazyModulesService.resolveModule<QuantityOnHandModuleDefinition>(QuantityOnHandModule)
    return { ...costingModuleExport, ...qohModuleExport }
  },
  initializer: ({ CostingBomUtilsService, CostingItemCostsUtilsService }) => {
    const httpClient = Injector.resolve<IHttpClient>(httpClientToken)
    Injector.register<IInventoryHistoryService>(
      inventoryHistoryServiceToken,
      () => new InventoryHistoryService(httpClient),
      Scope.Singleton
    )

    Injector.register<ICostingBomUtilsService>(
      costingBomUtilsServiceToken,
      (injector) => {
        const itemsService = injector.resolve<IItemsService>(itemsServiceToken)
        const errorHandler = injector.resolve<IErrorHandler>(errorHandlerServiceToken)

        return new CostingBomUtilsService(itemsService, errorHandler)
      },
      Scope.Singleton
    )
    Injector.register<ICostingItemCostsUtilsService>(
      costingItemCostsUtilsServiceToken,
      (injector) => {
        const itemsService = injector.resolve<IItemsService>(itemsServiceToken)
        const errorHandler = injector.resolve<IErrorHandler>(errorHandlerServiceToken)
        const inventoryHistoryService = injector.resolve<IInventoryHistoryService>(inventoryHistoryServiceToken)

        return new CostingItemCostsUtilsService(itemsService, errorHandler, inventoryHistoryService)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, ProductsModule, SharedModule, QuantityOnHandModule, SalesOrdersModule],
}
