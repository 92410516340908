import type { IXtCalendarTemplates } from '../../calendar/calendar.types'
import { CalendarTask } from '../tasks.types'
import { TasksCalendarItemTemplate } from './tasks-calendar-item/tasks-calendar-item'
import { TaskCalendarFiltersPanel } from './tasks-calendar.types'

export const tasksCalendarFilterDefaultValues: TaskCalendarFiltersPanel = {
  allusers: false,
  user: null,
  status: null,
}

export const tasksCalendarTemplates: IXtCalendarTemplates<CalendarTask> = {
  calendarItem: TasksCalendarItemTemplate,
}
