import { Overwrite } from 'utility-types'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { NewComment } from 'comments/comments.types'
import { IAttachedDocumentWithFile, ICRMAccountDocument } from 'documents/documents.types'
import { AccountPayload, IAccountFulfilled, IAccount, AccountOption } from '../accounts.types'
import { IAccountTableItem } from '../accounts-list/accounts-list.types'

export interface IAccountsServices {
  AccountsService: IAccountsService
  AccountsUtilsService: IAccountsUtilsService
}

export interface IAccountsService {
  getAll: (
    pagination: IPaginationParams,
    filters?: IAccountFilters,
    showDetails?: boolean,
    sortOptions?: ISortOption[]
  ) => Promise<IPaginationData<IAccount>>
  get(accountNumber: string): Promise<IAccount>
  delete(accountNumber: string): Promise<void>
  create(account: AccountPayload, comments: NewComment[], documents: IAttachedDocumentWithFile[]): Promise<void>
  update(account: AccountPayload): Promise<void>
  getFulfilledAccount(account: Pick<ICRMAccountDocument, 'name' | 'number' | 'crm_roles'>): Promise<IAccountFulfilled>
}

export interface IAccountsUtilsService {
  fetchAccounts(
    filters: AccountFilterPanel,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IAccountTableItem>>
  loadAccountsOptions(page: number, limit: number, account_number: string | null): Promise<IPaginationData<AccountOption>>
}

export enum AccountFilterFieldName {
  Owner = 'owner',
  ShowInactive = 'showInactive',
  AccountNumberPattern = 'account_number',
  AccountNamePattern = 'account_name',
  AccountType = 'account_type',
  ContactNamePattern = 'contact_name',
  CityPattern = 'city',
  StatePattern = 'state',
}

export interface IAccountFilters {
  [AccountFilterFieldName.ShowInactive]?: boolean
  [AccountFilterFieldName.AccountNumberPattern]?: string | null
  [AccountFilterFieldName.AccountNamePattern]?: string
  [AccountFilterFieldName.AccountType]?: string
  [AccountFilterFieldName.ContactNamePattern]?: string | null
  [AccountFilterFieldName.CityPattern]?: string | null
  [AccountFilterFieldName.StatePattern]?: string | null
  [AccountFilterFieldName.Owner]?: string
}

export type AccountFilterPanel = Overwrite<
  Required<IAccountFilters>,
  {
    [AccountFilterFieldName.Owner]: IXtAutocompleteOption | null
    [AccountFilterFieldName.AccountType]: IXtAutocompleteOption | null
  }
>
