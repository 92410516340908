import type { IXtSidebarOption } from './sidebar.types'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { homeRoutePath } from 'home/home.constants'
import { productsRoutePath } from 'products/products.constants'
import { canActivateRoute } from 'common/routing/guarded-route/guarder-route.utils'
import { productRoute } from 'products/products.routes'
import { setupRoutePath } from 'setup/setup.constants'
import { setupRoute } from 'setup/setup.routes'
import { shipmentsLabel, shippingRoutePath } from 'shipping/shipping.constants'
import { costingRoutePath } from 'costing/costing.constants'
import { runningAvailabilityRoutePath } from 'running-availability/running-availability.constants'
import { shippingRoute } from 'shipping/shipping.routes'
import { runningAvailabilityRoute } from 'running-availability/running-availability.routes'
import { costingRoute } from 'costing/costing.routes'
import { maintainShipmentsLabel, maintainShippingRoutePath } from 'maintain-shipping/maintain-shipping.constants'
import { maintainShippingRoute } from 'maintain-shipping/maintain-shipping.routes'
import { InventoryAdjustmentRoutePath } from 'inventory-adjustments/inventory-adjustments.constants'
import { companiesRoutePath } from 'companies/companies.constants'
import { companiesRoute } from 'companies/companies.routes'
import { activitiesRoutePath } from 'activities/activities.constants'
import { activitiesRoute } from 'activities/activities.routes'
import { ordersRoutePath } from 'orders/orders.constants'
import { ordersRoute } from 'orders/orders.routes'

export function getSidebarOptions(): IXtSidebarOption[] {
  return [
    {
      name: 'Home',
      path: homeRoutePath,
      icon: SvgIconIds.HOME,
      exact: true,
      disabled: false,
    },
    {
      name: 'Companies',
      path: companiesRoutePath,
      icon: SvgIconIds.CRM,
      disabled: !canActivateRoute(companiesRoute.guards),
    },
    {
      name: 'Activities',
      path: activitiesRoutePath,
      icon: SvgIconIds.LIST_ICON,
      disabled: !canActivateRoute(activitiesRoute.guards),
    },
    {
      name: 'Products',
      path: productsRoutePath,
      icon: SvgIconIds.PRODUCTS,
      disabled: !canActivateRoute(productRoute.guards),
    },
    {
      name: 'Orders',
      path: ordersRoutePath,
      icon: SvgIconIds.SALES_ORDERS,
      disabled: !canActivateRoute(ordersRoute.guards),
    },
    {
      name: shipmentsLabel,
      path: shippingRoutePath,
      icon: SvgIconIds.SHIPPING,
      disabled: !canActivateRoute(shippingRoute.guards),
    },
    {
      name: maintainShipmentsLabel,
      path: maintainShippingRoutePath,
      icon: SvgIconIds.MAINTAIN_SHIPPING,
      disabled: !canActivateRoute(maintainShippingRoute.guards),
    },
    {
      name: 'Running Availability',
      path: runningAvailabilityRoutePath,
      icon: SvgIconIds.RUNNING_AVAILABILITY,
      disabled: !canActivateRoute(runningAvailabilityRoute.guards),
    },
    {
      name: 'Costing',
      path: costingRoutePath,
      icon: SvgIconIds.COSTING,
      disabled: !canActivateRoute(costingRoute.guards),
    },
    {
      name: 'Inventory Adjustment',
      path: InventoryAdjustmentRoutePath,
      icon: SvgIconIds.INVENTORY_ADJUSTMENT,
      disabled: false,
    },
    {
      name: 'Settings',
      path: setupRoutePath,
      icon: SvgIconIds.SETTINGS,
      disabled: !canActivateRoute(setupRoute.guards),
    },
  ]
}
