import { KanbanOpportunitiesFilter, KanbanOpportunitiesFilterPanel } from '../opportunities-kanban.types'

export const itemsPerRequest = 30

export const defaultFilterValues: KanbanOpportunitiesFilterPanel = {
  [KanbanOpportunitiesFilter.ShowInactive]: false,
  [KanbanOpportunitiesFilter.User]: null,
  [KanbanOpportunitiesFilter.AssignedTo]: null,
  [KanbanOpportunitiesFilter.TargetDateOnOrAfter]: null,
  [KanbanOpportunitiesFilter.TargetDateOnOrBefore]: null,
  [KanbanOpportunitiesFilter.Project]: null,
  [KanbanOpportunitiesFilter.Account]: null,
  [KanbanOpportunitiesFilter.Type]: null,
  [KanbanOpportunitiesFilter.TypePattern]: '',
  [KanbanOpportunitiesFilter.Source]: null,
  [KanbanOpportunitiesFilter.SourcePattern]: '',
  [KanbanOpportunitiesFilter.Owner]: null,
  [KanbanOpportunitiesFilter.IsMyOpportunities]: true,
}
