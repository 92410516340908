import { IAsyncRoute, IRoute } from '../common/routing/routing.types'
import { salesOrdersRoutePath } from './sales-orders.constants'
import { SalesOrdersGuard } from './sales-orders.guard'
import { XtMode } from '../common/common.types'
import { buildPageComponent } from '../common/routing/routing.utils'
import { SalesOrderDetailsGuard } from './sales-order-details/sales-order-details.guard'
import { SalesOrdersModuleDefinition, SalesOrdersModule } from './sales-orders.module'

export function salesOrdersRouteFactory({ SalesOrdersList, SalesOrderDetailsPage }: SalesOrdersModuleDefinition): IRoute[] {
  return [
    {
      path: '',
      exact: true,
      component: SalesOrdersList,
    },
    {
      path: `/:orderNumber/${XtMode.Edit}`,
      component: (props) => buildPageComponent(props, XtMode.Edit, SalesOrderDetailsPage),
      guards: [SalesOrderDetailsGuard],
    },
    {
      path: `/:orderNumber/${XtMode.View}`,
      component: (props) => buildPageComponent(props, XtMode.View, SalesOrderDetailsPage),
    },
    {
      path: `/${XtMode.New}`,
      component: (props) => buildPageComponent(props, XtMode.New, SalesOrderDetailsPage),
      guards: [SalesOrderDetailsGuard],
    },
  ]
}

export const salesOrdersRoute: IAsyncRoute<SalesOrdersModuleDefinition> = {
  path: salesOrdersRoutePath,
  name: 'Sales Orders',
  module: SalesOrdersModule,
  guards: [SalesOrdersGuard],
  childrenRoutesFactory: salesOrdersRouteFactory,
}
