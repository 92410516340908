import type { TaskCalendarFiltersPanel, TaskCalendarItem } from './tasks-calendar.types'
import type { CalendarTask } from '../tasks.types'
import React, { FC, useCallback, useEffect } from 'react'
import { cls } from 'common/utils/utils'
import { XtMode } from 'common/common.types'
import { CalendarView } from 'calendar/calendar.types'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { useCalendarModule } from 'calendar/calendar-module-hook'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { XtPageFilter } from 'components/pagefilter/pagefilter'
import { usePageFilter } from 'components/pagefilter/pagefilter.utils'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { tasksCalendarFilterDefaultValues, tasksCalendarTemplates } from './tasks-calendar.constants'
import * as styles from './tasks-calendar.module.scss'
import { useTaskDetailsDialogHook } from '../task-details/task-details-dialog-hook'
import { useTasksModule } from '../tasks-module-hook'
import { resolveFilters } from './tasks-calendar.utils'
import { TaskListFilterField, TaskListFilterLabel } from '../tasks.types'

export const TasksCalendar: FC = () => {
  const { TasksUtilsService, TaskDetails } = useTasksModule()
  const { DocumentsUtilsService } = useDocumentsModule()
  const { ErrorHandler } = useCoreModule()
  const { XtCalendar, useCalendar } = useCalendarModule()

  const { taskId, taskDetailsDialogOpen, taskDetailsDialogMode, closeTaskDetailsDialog, openTaskDetailsDialog } = useTaskDetailsDialogHook()

  const loadCalendarItems = useCallback<
    (view: CalendarView, startDate: Date, endDate: Date, params?: TaskCalendarFiltersPanel) => Promise<TaskCalendarItem[]>
  >(
    async (view, startDate, endDate, params) => {
      const filters = params ?? tasksCalendarFilterDefaultValues
      return TasksUtilsService.requestCalendarTasks({ ...filters, fromdate: startDate, view, endDate })
    },
    [TasksUtilsService]
  )

  const { data, loading, handleItemUpdate, reset, calendarInstance, params } = useCalendar<CalendarTask, TaskCalendarFiltersPanel>({
    defaultView: CalendarView.Month,
    defaultParams: tasksCalendarFilterDefaultValues,
    loadData: loadCalendarItems,
    updateItem: TasksUtilsService.updateTaskDueDate,
  })

  const filterCalendar = useCallback<(filters: TaskCalendarFiltersPanel) => Promise<void>>(
    async (filters) => {
      try {
        await reset(undefined, undefined, filters)
      } catch (error) {
        ErrorHandler.handleError(error as Error)
      }
    },
    [ErrorHandler, reset]
  )

  const calendarListFilters = usePageFilter<TaskCalendarFiltersPanel>(PageFilterMapping.Calendar)

  useEffect(() => {
    if (calendarListFilters.lastUsedFilter) {
      void filterCalendar({ [TaskListFilterField.AllUsers]: calendarListFilters.lastUsedFilter[TaskListFilterField.AllUsers] })
    }
  }, [calendarListFilters.lastUsedFilter, filterCalendar])

  const onSubmitTaskDetailsDialog = useCallback<VoidFunction>(() => {
    void reset()
  }, [reset])

  const onItemClick = useCallback<(item: TaskCalendarItem) => void>(
    ({ raw: { id, editable } }) => {
      const mode = editable ? XtMode.Edit : XtMode.View
      openTaskDetailsDialog(id, mode)
    },
    [openTaskDetailsDialog]
  )

  const handleShowTasksForAllUsersFilterChange = (checked: boolean): void => {
    const newParams = { ...params, [TaskListFilterField.AllUsers]: checked }
    void filterCalendar(newParams)
    void calendarListFilters.handleLastUsedFilter(newParams)
  }

  return (
    <div className={cls('xt-content', styles.tasksCalendar)}>
      <XtDialog className="xt-modal-details-content" open={taskDetailsDialogOpen} animation={XtDialogAnimation.SlideAnimation}>
        <TaskDetails onSubmit={onSubmitTaskDetailsDialog} mode={taskDetailsDialogMode} id={taskId} onCancel={closeTaskDetailsDialog} />
      </XtDialog>
      <div className={styles.filtersContainer}>
        <XtPageFilter
          state={calendarListFilters}
          defaultFilterValues={tasksCalendarFilterDefaultValues}
          resolveFilters={() => resolveFilters(DocumentsUtilsService)}
          filter={filterCalendar}
          tableFilters={params}
        />
        <XtCheckbox
          className={styles.calendarListCheckbox}
          label={TaskListFilterLabel.AllUsers}
          value={params[TaskListFilterField.AllUsers]}
          onChange={handleShowTasksForAllUsersFilterChange}
          disabled={loading}
        />
      </div>
      <XtCalendar
        calendarInstance={calendarInstance}
        data={data}
        loading={loading}
        handleItemUpdate={handleItemUpdate}
        templates={tasksCalendarTemplates}
        onItemClick={onItemClick}
      />
    </div>
  )
}
