import { IAsyncRoute, IRoute } from 'common/routing/routing.types'
import { XtMode } from 'common/common.types'
import { buildPageComponent } from 'common/routing/routing.utils'
import { PurchaseOrdersModule, PurchaseOrdersModuleDefinition } from './purchase-orders.module'
import { purchaseOrdersRoutePath } from './purchase-orders.constants'
import { PurchaseOrdersGuard } from './purchase-orders.guard'

export function purchaseOrdersRouteFactory({ PurchaseOrdersListPage, PurchaseOrdersPage }: PurchaseOrdersModuleDefinition): IRoute[] {
  return [
    {
      path: '',
      exact: true,
      component: PurchaseOrdersListPage,
    },
    {
      path: `/:orderNumber/${XtMode.Edit}`,
      component: (props) => buildPageComponent(props, XtMode.Edit, PurchaseOrdersPage),
    },
    {
      path: `/:orderNumber/${XtMode.View}`,
      component: (props) => buildPageComponent(props, XtMode.View, PurchaseOrdersPage),
    },
    {
      path: `/${XtMode.New}`,
      component: (props) => buildPageComponent(props, XtMode.New, PurchaseOrdersPage),
    },
  ]
}

export const purchaseOrdersRoute: IAsyncRoute<PurchaseOrdersModuleDefinition> = {
  path: purchaseOrdersRoutePath,
  name: 'Purchase Orders',
  module: PurchaseOrdersModule,
  guards: [PurchaseOrdersGuard],
  childrenRoutesFactory: purchaseOrdersRouteFactory,
}
