import { Overwrite, Required } from 'utility-types'
import { IAppliedCharacteristic, IAppliedCharacteristicNew } from 'characteristics/characteristics.types'
import { ICustomer } from 'companies/customers/customers.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { ISalesPriceDetails, ISalesSharedOrderItem, ISalesSharedType, LineItemStatus } from './sales-shared.types'

export enum HoldTypeField {
  None = 'None',
  Credit = 'Credit',
  Shipping = 'Shipping',
  Packing = 'Packing',
  Return = 'Return',
  Tax = 'Tax',
}

export interface ISalesOrder extends ISalesSharedType {
  order_status: string
  order_number: string
  external_number: string
  order_date: string | null
  terms_description: string
  hold_type: HoldTypeField
  shipping_form: string | null
  shipping_charges: string | null
  ship_complete: boolean
  order_items: ISalesOrderItem[]
  salesorder_characteristics: IAppliedCharacteristic[] | null
}

export type SalesOrderLightweight = Omit<ISalesOrder, 'salesorder_characteristics' | 'order_items'>

export type SalesOrdersResponseType<ShowDetails> = ShowDetails extends true ? ISalesOrder : SalesOrderLightweight

export interface ISalesOrderExtended extends ISalesOrder {
  customer: ICustomer
}

export interface ISalesOrderItem extends ISalesSharedOrderItem {
  status: LineItemStatus
  qty_at_shipping: number
  qty_returned: number
  qty_shipped: number
  sub_items: ISalesOrderSubItem[] | null
  warranty: boolean
  substitute_for: string | null
}

export interface ISalesOrderSubItem {
  line_number: number
  item_number: string
  item_description: string
  customer_pn: string
  sold_from_site: string
  status: string
  qty_ordered: number
  qty_at_shipping: number
  qty_returned: number
  qty_shipped: number
  qty_uom: string
  qty_uom_description: string
  warranty: string
  tax_type: string
}

// TODO support currency_symbol
export type SalesOrderCreateInput = Overwrite<
  Omit<
    ISalesOrder,
    | 'order_number'
    | 'misc_charge_tax_type'
    | 'terms_description'
    | 'lastupdated'
    | 'site'
    | 'created'
    | 'scheduled_date'
    | 'currency_symbol'
    | 'external_number'
    | 'order_total'
    | 'sales_total'
  >,
  {
    order_items: ISalesOrderLineItemCreatePayload[]
    order_status?: string
    salesorder_characteristics: IAppliedCharacteristicNew[]
    sales_rep: string | null
    sale_type: string | null
    terms: string | null
  }
>

export type SalesOrderUpdateInput = Overwrite<
  ISalesOrder,
  {
    salesorder_characteristics: IAppliedCharacteristicNew[]
    order_items: ISalesOrderLineItemUpdatePayload[]
    sales_rep: string | null
    sale_type: string | null
    terms: string | null
  }
>

export interface ISalesOrderItemsFilters {
  showCancelledLines: boolean
  showClosedLines?: boolean
}

export interface ISalesOrderLineItemCreatePayload extends Partial<ISalesPriceDetails> {
  item_number: string
  customer_pn: string | null
  sold_from_site: string
  qty_ordered: number | null
  scheduled_date: string | null
  promise_date: string | null
  net_unit_price: number
  price_uom: string
  warranty: boolean
  substitute_for: string | null
  status: LineItemStatus
}

export interface ISalesOrderLineItemUpdatePayload extends ISalesOrderLineItemCreatePayload {
  line_number: string
}

export interface ISalesOrderCreateResponse {
  order_number: string
}

export enum SalesOrderFilterLabel {
  StartDate = 'Start Date',
  EndDate = 'End Date',
  ShowClosed = 'Show Closed',
  NewOrder = 'New Order',
  AttachOrder = 'Attach Order',
}

export enum SalesOrderFilterFieldName {
  CustomerNumber = 'customer_number',
  StartDate = 'startDate',
  EndDate = 'endDate',
  ShowClosed = 'closed',
  Site = 'site',
  CustomerType = 'customer_type',
  CustomerPattern = 'customer_type_pattern',
  PONumber = 'customer_po',
  SalesRep = 'sales_rep',
  SalesType = 'sale_type',
  Project = 'project',
  CreatedBy = 'created_by',
  HoldType = 'hold_type',
  Opportunity = 'opportunity_number',
}

export interface ISalesOrderFilters {
  [SalesOrderFilterFieldName.StartDate]?: string
  [SalesOrderFilterFieldName.EndDate]?: string
  [SalesOrderFilterFieldName.ShowClosed]?: boolean
  [SalesOrderFilterFieldName.Site]?: string
  [SalesOrderFilterFieldName.CustomerNumber]?: string
  [SalesOrderFilterFieldName.CustomerType]?: string
  [SalesOrderFilterFieldName.CustomerPattern]?: string
  [SalesOrderFilterFieldName.PONumber]?: string
  [SalesOrderFilterFieldName.SalesRep]?: string
  [SalesOrderFilterFieldName.SalesType]?: string
  [SalesOrderFilterFieldName.Project]?: string
  [SalesOrderFilterFieldName.CreatedBy]?: string
  [SalesOrderFilterFieldName.HoldType]?: string
  [SalesOrderFilterFieldName.Opportunity]?: string
  unassignedToOpportunity?: boolean
}

export type SalesOrderFilterPanel = Overwrite<
  Required<
    Omit<
      ISalesOrderFilters,
      | SalesOrderFilterFieldName.StartDate
      | SalesOrderFilterFieldName.EndDate
      | SalesOrderFilterFieldName.ShowClosed
      | SalesOrderFilterFieldName.Opportunity
      | 'unassignedToOpportunity'
    >
  >,
  {
    [SalesOrderFilterFieldName.Site]: string | null
    [SalesOrderFilterFieldName.CustomerType]: IXtAutocompleteOption | null
    [SalesOrderFilterFieldName.SalesRep]: IXtAutocompleteOption | null
    [SalesOrderFilterFieldName.SalesType]: IXtAutocompleteOption | null
    [SalesOrderFilterFieldName.Project]: IXtAutocompleteOption | null
    [SalesOrderFilterFieldName.CreatedBy]: IXtAutocompleteOption | null
  }
>

export interface ICopySalesOrderRequestData {
  order_number?: string
  customer_number?: string
  schedule_date?: string | null
}

export type SalesOrderOption = SalesOrderLightweight & IXtAutocompleteOption
