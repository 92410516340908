import { dateToServerFormat } from 'common/utils/date.utils'
import { IOpportunityFormValues, IOpportunityState, OpportunityFormField } from './opportunity-details.types'
import { IOpportunity } from '../opportunities.types'

export const defaultOpportunityState: IOpportunityState = {
  opportunity: null,
  loading: false,
}

export function convertFormValues(data: IOpportunityFormValues): Partial<IOpportunity> {
  return {
    ...data,
    [OpportunityFormField.Account]: data.account?.id ?? '',
    [OpportunityFormField.Currency]: data.currency?.id ?? null,
    [OpportunityFormField.Owner]: data.owner?.id ?? null,
    [OpportunityFormField.AssignedTo]: data.assigned_user?.id ?? null,
    [OpportunityFormField.Project]: data.project?.id ?? null,
    [OpportunityFormField.OppType]: data.type?.id ?? null,
    [OpportunityFormField.Stage]: data.stage?.id ?? null,
    [OpportunityFormField.Priority]: data.priority?.id ?? null,
    [OpportunityFormField.LeadSource]: data.source?.id ?? null,
    [OpportunityFormField.Probability]: data.probability_percent ?? 0,
    [OpportunityFormField.StartDate]: data?.start_date ? dateToServerFormat(data?.start_date) : null,
    [OpportunityFormField.AssigneeDate]: data?.assigned_date ? dateToServerFormat(data?.assigned_date) : null,
    [OpportunityFormField.TargetDate]: data?.target_date ? dateToServerFormat(data?.target_date) : null,
    [OpportunityFormField.ActualDate]: data?.actual_date ? dateToServerFormat(data?.actual_date) : null,
  }
}
